import React from 'react'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { useGetPlaceTasksQuery } from 'services/placeApi'
import { useGetSubplaceTasksQuery } from 'services/subplaceApi'
import { useGetTaskAnalyticsQuery } from 'services/taskApi'
import { useGetUserAnalyticsQuery } from 'services/questApi'
import { TaskType } from 'utils/questUtils'
// import { VscCircleSlash } from 'react-icons/vsc'
import { CircularProgressbarWithChildren } from 'react-circular-progressbar'
import { RiCheckLine } from 'react-icons/ri'
import { HiOutlineLightBulb } from 'react-icons/hi'
import { MdDoubleArrow } from 'react-icons/md'
import { format } from 'date-fns'

const ListTask = () => {
  const { id, taskParentType, taskParentId, questId } = useParams()
  const { data: placeData } = useGetPlaceTasksQuery(taskParentId, {
    skip: taskParentType !== 'places',
  })
  const { data: subplaceData } = useGetSubplaceTasksQuery(taskParentId, {
    skip: taskParentType !== 'subplaces',
  })
  const { data: TaskAnalytics } = useGetTaskAnalyticsQuery(taskParentId, {
    skip: !taskParentId,
  })

  const { data: UserAnalytics } = useGetUserAnalyticsQuery(questId, {
    skip: !questId,
  })

  const { title, tasks } = placeData || subplaceData || {}

  return (
    <>
      <div className="w-full bg-[#F7F8FA] rounded-[16px] px-[26px] py-[23px] 2xl:w-[300px]">
        <div>
          <p className="text-[18px] leading-[150%] text-[#262626] font-semibold">
            Tasks List
          </p>
          <p className="text-[14px] leading-[150%] text-[#7D7D7D] mb-[24px]">
            {title}
          </p>
          {tasks &&
            tasks.map((task, index) => (
              <div className="flex" key={task.id}>
                <div className="w-[24px] relative">
                  <div
                    className={
                      task.id == id
                        ? 'z-30 flex items-center justify-center bg-[#2C7D92] text-[12px] leading-[16px] text-[#F7F8FA] h-[24px] w-[24px] border-[2px] rounded-full border-[#2C7D92]'
                        : 'z-30 flex items-center justify-center bg-[#F7F8FA] text-[12px] leading-[16px] text-[#2C7D92] h-[24px] w-[24px] border-[2px] rounded-full border-[#2C7D92]'
                    }
                  >
                    {index + 1}
                  </div>
                  {tasks.length > index + 1 ? (
                    <div className="z-20 h-full flex justify-center">
                      <div className="w-[1px] border-[1px] border-[#2C7D92]"></div>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
                <div className="ml-[16px]">
                  <Link
                    to={`/quests/${questId}/${taskParentType}/${taskParentId}/tasks/${task.id}`}
                  >
                    <p className="text-[16px] leading-[150%] text-[#262626] font-semibold">
                      {task.title}
                    </p>
                  </Link>
                  <p className="text-[14px] leading-[150%] text-[#7D7D7D]">
                    <TaskType type={task.task_type} />
                  </p>
                  <div className="h-[24px]"></div>
                </div>
              </div>
            ))}
        </div>
      </div>
      {/* <div>
        <div
          style={{
            background: 'rgba(241, 90, 91, 0.1)',
          }}
          className="bg-[#F7F8FA] w-[162px] py-[24px] px-[20px] rounded-[16px] mt-[24px]"
        >
          <div className="flex items-center justify-end">
            <VscCircleSlash className="text-[#F15A5B] text-3xl rotate-90" />
          </div>
          <div className="flex items-center justify-start text-[24px] leading-[120%] text-[#F15A5B] font-semibold">
            {TaskAnalytics?.skip_used?.percentage}
          </div>
          <div className="text-[14px] leading-[21px] text-[#5A5A60] mt-[4px]">
            {TaskAnalytics?.skip_used?.users_count} users got stuck on this task
          </div>
        </div>
      </div> */}
      <div>
        <div className="mt-[24px] flex items-center justify-start">
          <div className="greenn w-[60px]">
            <CircularProgressbarWithChildren
              strokeWidth={12}
              value={TaskAnalytics?.task_completion?.percentage?.slice(0, 1)}
            >
              <div>
                <RiCheckLine className="text-2xl text-questeon" />
              </div>
            </CircularProgressbarWithChildren>
          </div>
          <div>
            <div className="pl-[12px]">
              <div className="text-[14px] leading-[21px] text-[#262626]">
                Task Completion
              </div>
              <div className="flex items-center justify-start text-[18px] leading-[27px] text-[#262626] font-semibold">
                {TaskAnalytics?.task_completion?.percentage}
              </div>
              <div className="text-[12px] leading-[16px] text-[#5A5A60]">
                Last joined :{' '}
                {format(
                  new Date(
                    UserAnalytics ? UserAnalytics?.users?.last_joined : null
                  ),
                  'mm/dd/yyyy'
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="mt-[24px] flex items-center justify-start">
          <div className="greenn w-[60px]">
            <CircularProgressbarWithChildren
              strokeWidth={12}
              value={TaskAnalytics?.hint_used?.percentage?.slice(0, 1)}
            >
              <div>
                <HiOutlineLightBulb className="text-2xl text-questeon" />
              </div>
            </CircularProgressbarWithChildren>
          </div>
          <div>
            <div className="pl-[12px]">
              <div className="text-[14px] leading-[21px] text-[#262626]">
                Hint Used
              </div>
              <div className="flex items-center justify-start text-[18px] leading-[27px] text-[#262626] font-semibold">
                {TaskAnalytics?.hint_used?.percentage}
              </div>
              <div className="text-[12px] leading-[16px] text-[#5A5A60]">
                Last joined :{' '}
                {format(
                  new Date(
                    UserAnalytics ? UserAnalytics?.users?.last_joined : null
                  ),
                  'mm/dd/yyyy'
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="mt-[24px] flex items-center justify-start">
          <div className="reeed w-[60px]">
            <CircularProgressbarWithChildren
              strokeWidth={12}
              value={TaskAnalytics?.skip_used?.percentage?.slice(0, 1)}
            >
              <div>
                <MdDoubleArrow className="text-2xl text-[#F15A5B]" />
              </div>
            </CircularProgressbarWithChildren>
          </div>
          <div>
            <div className="pl-[12px]">
              <div className="text-[14px] leading-[21px] text-[#262626]">
                Skip Used
              </div>
              <div className="flex items-center justify-start text-[18px] leading-[27px] text-[#262626] font-semibold">
                {TaskAnalytics?.skip_used?.percentage?.slice(0, 1)}
              </div>
              <div className="text-[12px] leading-[16px] text-[#5A5A60]">
                Last joined :{' '}
                {format(
                  new Date(
                    UserAnalytics ? UserAnalytics?.users?.last_joined : null
                  ),
                  'mm/dd/yyyy'
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ListTask
