import React, { useEffect, useState } from 'react'
import { DeviceFrameset } from 'react-device-frameset'
import { useWatch } from 'react-hook-form'
import 'react-device-frameset/styles/marvel-devices.min.css'
import { IoIosArrowBack } from 'react-icons/io'
import FileIcon from 'assets/images/mobile/image_icon_gray.png'

const MobileIntro = ({ control }) => {
  const [previewMobile, setPreviewMobile] = useState({
    intro_image: '',
    intro: '',
    notes: [],
  })

  const getTitle = useWatch({
    control,
    name: 'title',
  })

  const getImage = useWatch({
    control,
    name: 'intro_image',
  })

  const getIntro = useWatch({
    control,
    name: 'intro',
  })

  const getNotes = useWatch({
    control,
    name: 'quest_intros_attributes',
  })

  const setIntro = (e) => {
    setPreviewMobile((existingValues) => ({
      ...existingValues,
      intro: e,
    }))
  }

  const setNotes = (e) => {
    setPreviewMobile((existingValues) => ({
      ...existingValues,
      notes: e,
    }))
  }

  const setImage = (e) => {
    setPreviewMobile((existingValues) => ({
      ...existingValues,
      intro_image: e,
    }))
  }

  useEffect(() => {
    if (typeof getImage !== 'object' && getImage !== 'SERVER_FILE') {
      setImage(getImage)
    }
    if (getImage !== null && typeof getImage === 'object') {
      setTimeout(() => {
        const imageUrl = URL.createObjectURL(getImage)
        setImage(imageUrl)
      }, 100)
    }
  }, [getImage])

  useEffect(() => {
    if (getIntro) {
      setIntro(getIntro.replace(/<[^>]+>/g, ''))
    }
    if (getNotes) {
      setNotes(
        getNotes?.map((note) => ({
          title: note.title,
          description: note.description
            .replace(/<[^>]+>/g, '')
            .replace(/(\r\n|\n|\r)/gm, ''),
        }))
      )
    }
  }, [getIntro, getNotes])

  return (
    <>
      <div className="w-3/12 -mt-[130px] sticky top-0 h-[600px]">
        <div className="-ml-14 -mt-[130px]">
          <DeviceFrameset device="iPhone X" zoom={0.6} potrait="true">
            <div className="h-[800px] overflow-auto">
              <div className="mt-[30px] p-[17px] flex items-center justify-between">
                <div>
                  <IoIosArrowBack className="text-black text-2xl" />
                </div>
                <div className="text-[16px] leading-[150%] text-[#262626] font-semibold">
                  Intro
                </div>
                <div>
                  <IoIosArrowBack className="text-white text-2xl" />
                </div>
              </div>
              <div
                style={{
                  backgroundImage: `url(${previewMobile?.intro_image} )`,
                  backgroundSize: 'cover',
                }}
                className="flex items-center justify-center h-[230px] bg-[#EDEDED]"
              >
                {previewMobile?.intro_image === '' && (
                  <div className="flex items-center justify-center">
                    <img src={FileIcon} alt="" className="w-[40px]" />
                  </div>
                )}
              </div>
              <div className="p-[17px]">
                <div className="text-[18px] leading-[130%] text-[#262626] font-bold inline-block">
                  {getTitle}
                </div>
                <div className="flex items-center justify-start mt-[12px]">
                  <div className="text-[11px] leading-[150%] text-[#7D7D7D]">
                    {previewMobile.intro || 'Intro description goes here ...'}
                  </div>
                </div>
                {previewMobile?.notes[0]?.title === '' ? (
                  <div className="flex items-center justify-start mt-[17px] border-[1px] rounded-[12px] py-[12px] px-[14px]">
                    <div>
                      <div className="text-[13px] leading-[130%] text-[#7D7D7D] font-bold inline-block">
                        Intro Notes
                      </div>
                      <div className="text-[11px] leading-[150%] text-[#7D7D7D] mt-2">
                        Intro block goes here ...
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="flex items-center justify-start mt-[17px]">
                    <div className="w-full">
                      {previewMobile.notes.map((notes, index) => (
                        <div
                          key={index}
                          className="bg-[#FFCF50]/10 rounded-[12px] py-[12px] px-[14px] w-full mb-[24px]"
                        >
                          <div className="text-[13px] leading-[130%] text-[#262626] font-bold inline-block">
                            {notes.title}
                          </div>
                          <div className="text-[11px] leading-[150%] text-[#5A5A60] mt-2">
                            {notes.description}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                <div className="flex items-center justify-center mt-[24px]">
                  <div>
                    <div className="text-[18px] leading-[27px] text-[#262626] font-bold inline-block">
                      Ready to start the quest?
                    </div>
                    <div className="flex items-center justify-center mt-[14px] bg-questeon text-[16px] font-bold leading-[150%] text-white rounded-[37px] px-[50px] py-[13px]">
                      Start Quest
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </DeviceFrameset>
        </div>
      </div>
    </>
  )
}

export default MobileIntro
