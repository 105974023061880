import React from 'react'
import emptyReviewsIcon from 'assets/images/icon-empty-reviews.svg'

const EmptyReviews = () => {
  return (
    <div className="flex items-center justify-center h-[400px]">
      <div>
        <div className="flex items-center justify-center">
          <img src={emptyReviewsIcon} alt="" />
        </div>
        <div className="flex items-center justify-center">
          <div>
            <p className="text-center text-[18px] leading-[150%] text-[#5A5A60] font-semibold mt-2">
              No review found
            </p>
            <p className="text-center text-[14px] leading-[150%] text-[#5A5A60] font-extralight mt-2">
              When people play your quest,
            </p>
            <p className="text-center text-[14px] leading-[150%] text-[#5A5A60] font-extralight">
              they can give a feedback and review
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EmptyReviews
