/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { setInActive } from 'features/navigationSlice'
import { ColorRing } from 'react-loader-spinner'
import Sidebar from './Sidebar'
import Header from './Header'
import Modal from './Modal'

const ProtectedRoutes = () => {
  const { isAuthenticated } = useSelector((state) => state.authentication)
  const location = useLocation()
  return isAuthenticated ? (
    <DefaultLayout />
  ) : (
    <Navigate to="/signin" state={{ from: location }} replace />
  )
}

const DefaultLayout = () => {
  const dispatch = useDispatch()
  const { isLoading } = useSelector((state) => state.loading)

  const onOverlayClick = () => {
    if (!isLoading) {
      dispatch(setInActive())
    }
  }

  return (
    <div className="relative" onClick={onOverlayClick}>
      {isLoading && (
        <div className="absolute flex items-center justify-center w-screen h-screen bg-[rgba(0,0,0,0.5)] z-50">
          <div>
            <div className="flex items-center justify-center">
              <ColorRing
                visible={isLoading}
                height="80"
                width="80"
                ariaLabel="blocks-loading"
                wrapperClass="blocks-wrapper"
                colors={['#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF']}
              />
            </div>
            <p className="text-[14px] leading-[150%] text-[#FFFFFF] text-center font-extralight">
              Please wait a moment
            </p>
          </div>
        </div>
      )}

      <div className="h-screen">
        <div className="flex">
          <Sidebar />
          <div className="w-full overflow-auto">
            <Header />
            <Outlet />
          </div>
        </div>
      </div>
      <div>
        <Modal />
      </div>
    </div>
  )
}

export default ProtectedRoutes
