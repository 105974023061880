import React from 'react'
import { FaStar } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { useGetReviewChartQuery } from 'services/questApi'

const ReviewChart = () => {
  const { quest } = useSelector((state) => state.quest)
  const { data } = useGetReviewChartQuery(quest?.id, { skip: !quest })
  return (
    <div className="">
      {data?.map((review) => (
        <ReviewStar data={review} key={`star-${review.star}`} />
      ))}
    </div>
  )
}

const ReviewStar = ({ data }) => {
  return (
    <div className="flex items-center">
      <span className="flex items-center w-[40px] mr-2">
        <div className="w-[18px] grid justify-center">{data.star}</div>
        <FaStar className="text-[#FFCF50]" />
      </span>
      <div className="bg-[#EDEDED] rounded-full h-[8px] w-[250px]">
        <div
          className="bg-[#2C7D92] h-[8px] rounded-full"
          style={{
            width: data.percentage,
          }}
        ></div>
      </div>
      <div className="ml-2 grid justify-center w-[18px]">{data.total}</div>
    </div>
  )
}

export default ReviewChart
