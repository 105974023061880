/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-extra-semi */
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useNavigate, NavLink, Outlet } from 'react-router-dom'
import { FaPen, FaCopy, FaTrash } from 'react-icons/fa'
import { HiOutlineUserGroup } from 'react-icons/hi'
import { AiOutlineCalendar } from 'react-icons/ai'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import dollar from 'assets/images/questdetail/dollar.svg'
import list from 'assets/images/questdetail/list.svg'
import star from 'assets/images/questdetail/star.svg'
import time from 'assets/images/questdetail/time.svg'
import RadiusIcon from 'assets/images/questdetail/icon-radius.svg'
import classNames from 'classnames'
import Swal from 'sweetalert2'
import RejectedIcon from 'assets/images/icon-rejected.svg'
import HTMLView from 'components/HTMLView'
import PublishFormModal from 'components/quest/modalForm/PublishFormModal'
import PublishSuccessModal from 'components/quest/modalForm/PublishSuccessModal'
import PublishEditModal from 'components/quest/modalForm/PublishEditModal'
import DeleteModal from 'components/quest/modalForm/DeleteModal'
import {
  useGetQuestQuery,
  useGenerateTestLinkMutation,
  useUpdateQuestStatusMutation,
  useDeleteQuestMutation,
} from 'services/questApi'
import { setQuest, unSetQuest } from 'features/questSlice'
import { setAction } from 'features/questSlice'
import { setHeader } from 'features/navigationSlice'
import RecentUser from './QuestDetail/RecentUser'
import QuestStatus from 'pages/quest/QuestDetail/QuestStatus'
import useWindowDimensions from '../../hooks/WindowDimensions'
import { format } from 'date-fns'

const QuestDetail = () => {
  const { id } = useParams()
  const { data } = useGetQuestQuery(id)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [generateTestLink] = useGenerateTestLinkMutation()
  const [updateQuestStatus] = useUpdateQuestStatusMutation()
  const [deleteQuest] = useDeleteQuestMutation()
  const [copied, setCopied] = useState(false)
  const [showPublishModal, setShowPublishModal] = useState(false)
  const [published, setPublished] = useState(false)
  const [editPublish, setEditPublish] = useState(false)
  const [isDelete, setIsDelete] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  const [isReview, setIsReview] = useState(false)
  const { height } = useWindowDimensions()

  const onModalShow = () => {
    setShowPublishModal(true)
  }

  const handleOnChangeValue = () => {
    setIsChecked(!isChecked)
  }

  useEffect(() => {
    dispatch(setHeader('QUEST'))
    dispatch(setQuest(data))
    dispatch(setAction('VIEW'))
    if (data && !data?.deep_link) {
      generateTestLink(id)
    }
  }, [data, dispatch])

  useEffect(() => {
    return () => {
      dispatch(unSetQuest())
    }
  }, [dispatch])

  useEffect(() => {
    if (data) {
      if (data.step === 'conclusion') {
        setIsReview(true)
      }
    }
  }, [data])

  const errorDialog = async (data) => {
    await Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: data.errors,
      confirmButtonColor: '#2C7D92',
    })
  }

  const onSubmitForReviewClick = async () => {
    const status = 'submit_review'
    try {
      await updateQuestStatus({ id, status }).unwrap()
    } catch ({ data }) {
      errorDialog(data)
    }
  }

  const onPublishClick = async () => {
    const status = 'published'
    try {
      await updateQuestStatus({ id, status }).unwrap()
      setShowPublishModal(false)
      setPublished(true)
    } catch ({ data }) {
      errorDialog(data)
    }
  }

  const ondeleteClick = async () => {
    const id = data?.id
    try {
      await deleteQuest(id).unwrap()
      setIsDelete(false)
      setTimeout(() => {
        navigate(`/quests/list`)
        window.location.reload()
      }, 100)
    } catch ({ data }) {
      errorDialog(data)
    }
  }

  const onEditClick = () => {
    setEditPublish(true)
  }

  let disableEditBtn = false
  if (data) {
    disableEditBtn = ['review'].includes(data.status)
  }

  let isFree = true
  if (data) {
    isFree = data?.price === 0
  }

  return (
    <>
      {
        <PublishFormModal
          showModal={showPublishModal}
          closeModal={() => setShowPublishModal(false)}
          valueChecked={isChecked}
          handleValue={handleOnChangeValue}
          publishClick={onPublishClick}
        />
      }
      {
        <PublishEditModal
          showModal={editPublish}
          closeModal={() => setEditPublish(false)}
          editClick={() => navigate(`/quests/${data?.slug}/edit`)}
        />
      }
      {
        <DeleteModal
          showModal={isDelete}
          closeModal={() => setIsDelete(false)}
          deleteClick={ondeleteClick}
        />
      }
      {
        <PublishSuccessModal
          showModal={published}
          closeModal={() => setPublished(false)}
        />
      }
      <div
        style={{
          height: height - 80,
        }}
        className="overflow-auto"
      >
        {data && (
          <div>
            <div className="h-[300px]">
              <img
                src={data.image_url}
                alt=""
                className="h-full w-full object-cover object-center"
              />
            </div>
            <div className="px-[32px]">
              <div className="flex items-center justify-between pt-[24px]">
                <div className="flex items-center">
                  <QuestStatus questStatus={data.status} />
                </div>
                <div className="flex items-center justify-between">
                  {['draft', 'rejected'].includes(data.status) && (
                    <div>
                      <button
                        className={
                          isReview
                            ? 'h-12 px-6 rounded-full font-bold text-white bg-questeon'
                            : 'h-12 px-6 rounded-full font-bold text-white bg-gray-300'
                        }
                        onClick={onSubmitForReviewClick}
                        disabled={!isReview}
                      >
                        Submit&nbsp;for&nbsp;Review
                      </button>
                    </div>
                  )}

                  {data.status === 'ready' && (
                    <button
                      className="h-12 px-6 rounded-full text-white font-bold disabled bg-questeon"
                      onClick={onModalShow}
                    >
                      Publish&nbsp;Now
                    </button>
                  )}

                  {data.status === 'review' && (
                    <button
                      className="h-12 px-6 rounded-full text-white font-bold bg-gray-300"
                      disabled
                    >
                      Reviewing...
                    </button>
                  )}
                  {data?.status === 'publish' ? (
                    <button
                      className={classNames(
                        'font-bold flex justify-center items-center mx-3 rounded-full h-12 px-6 border-[1px] border-solid text-questeon border-questeon',
                        {
                          '!text-[#C2C2C2] !border-[#C2C2C2]': disableEditBtn,
                        }
                      )}
                      onClick={onEditClick}
                      disabled={disableEditBtn}
                    >
                      <FaPen />
                      Edit
                    </button>
                  ) : (
                    <button
                      className={classNames(
                        'font-bold flex justify-center items-center mx-3 rounded-full h-12 px-6 border-[1px] border-solid text-questeon border-questeon',
                        {
                          '!text-[#C2C2C2] !border-[#C2C2C2]': disableEditBtn,
                        }
                      )}
                      onClick={() => navigate(`/quests/${data?.slug}/edit`)}
                      disabled={disableEditBtn}
                    >
                      <FaPen />
                      Edit
                    </button>
                  )}

                  <button
                    onClick={() => setIsDelete(true)}
                    disabled={disableEditBtn}
                    className="focus:outline-none focus:ring-none"
                  >
                    <span>
                      <FaTrash
                        className={classNames('text-[#F15A5B]', {
                          '!text-[#C2C2C2]': disableEditBtn,
                        })}
                      />
                    </span>
                  </button>
                </div>
              </div>

              <div className="my-6">
                <div className="text-[36px] leading-[120%] text-[#262626] font-bold">
                  <h3>{data.title}</h3>
                </div>
              </div>
              <div className="mb-[24px]">
                <div className="flex items-center text-[14px] leading-[21px] text-[#5A5A60] font-semibold">
                  <p className="flex items-center mr-4">
                    <img src={star} alt="star" className="" />
                    &nbsp; {data.star}
                  </p>
                  <p className="flex items-center mr-4">
                    <img
                      src={dollar}
                      alt=""
                      className={classNames('', {
                        green: !isFree,
                      })}
                    />
                    &nbsp; {isFree ? 'Free' : data.price}
                  </p>
                  {data.place_sequential && (
                    <p className="flex items-center mr-4">
                      <img src={list} alt="list" />
                      &nbsp; Place Sequential
                    </p>
                  )}
                  {data.time_tracker && (
                    <p className="flex items-center mr-4">
                      <img src={time} alt="time" />
                      &nbsp; Time Tracker
                    </p>
                  )}

                  {data.group_play && (
                    <p className="flex items-center">
                      <HiOutlineUserGroup className="text-xl text-[#C2C2C2]" />
                      &nbsp; Group play
                    </p>
                  )}
                </div>
              </div>
              {data.end_date || data.start_date ? (
                <div className="mb-[24px] flex items-center justify-start">
                  <div>
                    <AiOutlineCalendar className="text-[#7D7D7D] text-xl" />
                  </div>
                  <div className="ml-[18px] flex items-center justify-start">
                    {data.start_date !== null && (
                      <div>
                        <p className="text-[14px] leading-[21px] text-[#5A5A60] font-semibold">
                          Start date{' '}
                          <span className="text-[14px] leading-[150%] text-[#262626] font-light">
                            {format(
                              new Date(data.start_date),
                              'dd-MM-yyyy h:mm aa'
                            )}
                          </span>
                        </p>
                      </div>
                    )}
                    {data.end_date !== null && (
                      <div className="ml-[18px]">
                        <p className="text-[14px] leading-[21px] text-[#5A5A60] font-semibold">
                          End date{' '}
                          <span className="text-[14px] leading-[150%] text-[#262626] font-light">
                            {format(
                              new Date(data.end_date),
                              'dd-MM-yyyy h:mm aa'
                            )}
                          </span>
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              ) : null}
              <div className="flex">
                <div className="w-9/12 2xl:pr-12 2xl:w-8/12">
                  {data.status === 'rejected' && (
                    <div className="rounded-[24px] bg-[#f15a5b]/10 p-[24px] mb-4">
                      <div className="flex items-center">
                        <div>
                          <img
                            src={RejectedIcon}
                            alt="RejectedIcon"
                            className="w-[19.5px]"
                          />
                        </div>
                        <div className="pl-2 text-[16px] leading-[24px] text-[#F15A5B] font-bold">
                          Quest Rejected
                        </div>
                      </div>
                      <div className="mt-[16px]">
                        <p className="text-[14px] leading-[21px] text-[#262626]">
                          Your quest submission was rejected by our team, here
                          are the reasons :
                        </p>
                        <div className="text-[14px] leading-[21px] text-[#262626] font-bold">
                          <HTMLView content={data.reject_messages} />
                        </div>
                        <p className="text-[14px] leading-[21px] text-[#262626]">
                          Please update your quest and try submit it again
                          later. Thank you!
                        </p>
                      </div>
                    </div>
                  )}

                  <div className="rounded-[24px] bg-[#ededed] p-[24px] mb-4 flex items-center">
                    <img src={RadiusIcon} alt="RadiusIcon" className="mr-3" />
                    <span className="text-[14px]">
                      Radius detection will not be activated until the quest is{' '}
                      <span className="font-bold">published</span>. Feel free to
                      test the quest wherever you are, there's no restriction.
                    </span>
                  </div>

                  {data.deep_link && (
                    <div className="bg-[#F7F8FA]/80 p-[24px] rounded-[24px]">
                      <div className="mb-3" type="button">
                        <div className="flex items-center text-[16px] font-semibold leading-[24px] text-[#262626]">
                          <span className="text-xl mr-2">✨</span>
                          Test Your Quest
                        </div>
                      </div>

                      <div className="text-[14px] leading-[150%] text-[#5A5A60]">
                        <p>
                          Test out your Quest with your friends or team first.
                          When all is good, you can submit it to be reviewed by
                          our team, then publish it online (available for
                          everyone to join) when it's approved or ready. This
                          link will stay active even after the quest is
                          published.
                        </p>
                        <div className="flex justify-between mt-[24px]">
                          <input
                            className="bg-[#EDEDED] rounded-[24px] pl-[20px] py-[12px] text-[14px] leading-[150%] w-full mr-6"
                            type="text"
                            value={data.deep_link}
                            disabled
                          />
                          <CopyToClipboard
                            text={data.deep_link}
                            onCopy={() => setCopied(true)}
                          >
                            <button className="bg-[#2C7D92] flex items-center justify-center text-[#FFFFFF] text-[14px] leading-[150%] font-semibold px-[20px] py-[12px] rounded-[50px]">
                              <FaCopy /> Copy&nbsp;link
                            </button>
                          </CopyToClipboard>
                        </div>
                        {copied && <span className="pl-[20px]">Copied!</span>}
                      </div>
                    </div>
                  )}

                  <div className="flex w-full my-[24px] text-[16px] leading-[24px] text-[#262626] border-b-[1px] ">
                    <div className="py-[18px]">
                      <NavLink
                        to={`/quests/${data?.slug}/`}
                        className={({ isActive }) =>
                          isActive
                            ? 'text-[16px] leading-[24px] font-semibold py-[18px] px-[40px] text-[#2C7D92] border-b-2 border-[#2C7D92] hover:text-[#7D7D7D]'
                            : 'text-[16px] leading-[24px] text-[#262626] font-semibold py-[18px] px-[40px] hover:text-[#7D7D7D]'
                        }
                      >
                        Info
                      </NavLink>
                    </div>
                    <div className="py-[18px]">
                      <NavLink
                        to={`/quests/${data?.slug}/places`}
                        className={({ isActive }) =>
                          isActive
                            ? 'text-[16px] leading-[24px] font-semibold py-[18px] px-[40px] text-[#2C7D92] border-b-2 border-[#2C7D92] hover:text-[#7D7D7D]'
                            : 'text-[16px] leading-[24px] text-[#262626] font-semibold py-[18px] px-[40px] hover:text-[#7D7D7D]'
                        }
                      >
                        Places
                      </NavLink>
                    </div>
                    <div className="py-[18px]">
                      <NavLink
                        to={`/quests/${data?.slug}/users`}
                        className={({ isActive }) =>
                          isActive
                            ? 'text-[16px] leading-[24px] font-semibold py-[18px] px-[40px] text-[#2C7D92] border-b-2 border-[#2C7D92] hover:text-[#7D7D7D]'
                            : 'text-[16px] leading-[24px] text-[#262626] font-semibold py-[18px] px-[40px] hover:text-[#7D7D7D]'
                        }
                      >
                        Users
                      </NavLink>
                    </div>
                    <div className="py-[18px]">
                      <NavLink
                        to={`/quests/${data?.slug}/reviews`}
                        className={({ isActive }) =>
                          isActive
                            ? 'text-[16px] leading-[24px] font-semibold py-[18px] px-[40px] text-[#2C7D92] border-b-2 border-[#2C7D92] hover:text-[#7D7D7D]'
                            : 'text-[16px] leading-[24px] text-[#262626] font-semibold py-[18px] px-[40px] hover:text-[#7D7D7D]'
                        }
                      >
                        Reviews
                      </NavLink>
                    </div>
                    <div className="py-[18px]">
                      <NavLink
                        to={`/quests/${data?.slug}/analytics`}
                        className={({ isActive }) =>
                          isActive
                            ? 'text-[16px] leading-[24px] font-semibold py-[18px] px-[40px] text-[#2C7D92] border-b-2 border-[#2C7D92] hover:text-[#7D7D7D]'
                            : 'text-[16px] leading-[24px] text-[#262626] font-semibold py-[18px] px-[40px] hover:text-[#7D7D7D]'
                        }
                      >
                        Analytics
                      </NavLink>
                    </div>
                  </div>
                  <div>
                    <Outlet />
                  </div>
                </div>
                <div className="pl-[20px] w-3/12 2xl:w-4/12">
                  <RecentUser />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default QuestDetail
