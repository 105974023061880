import React from 'react'
import emptyTableIcon from 'assets/images/table1.png'
import { FaPlus } from 'react-icons/fa'
import { Link } from 'react-router-dom'

const EmptyQuest = () => {
  return (
    <div className="grid justify-center items-center h-[500px]">
      <div className="flex items-center justify-center">
        <img src={emptyTableIcon} alt="" />
      </div>
      <div className="grid justify-center items-center">
        <p className="text-center text-[18px] leading-[150%] text-[#5A5A60] font-semibold mt-2">
          It’s quite empty in here 👀
        </p>
        <p className="text-center text-[14px] leading-[150%] text-[#5A5A60] font-extralight">
          No quest has been made. Let’s create one
        </p>
      </div>
      <div className="flex items-center justify-center text-white text-[16px] leading-[24px] mt-8">
        <Link
          to="/quests/new"
          className="flex items-center justify-center text-white w-[230px] py-[13px] bg-[#2C7D92] rounded-[50px]"
        >
          <FaPlus className="text-xl text-white" />
          &nbsp; Create First Quest
        </Link>
      </div>
    </div>
  )
}

export default EmptyQuest
