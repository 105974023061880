import React, { useState } from 'react'
import verifyIcon from 'assets/images/authpage/verify_icon.svg'
import { useLocation } from 'react-router-dom'
import { useSendEmailConfirmationMutation } from 'services/authApi'
import AuthNotice from 'components/AuthNotice'
import QuesteonLogo from 'assets/images/questeon_logo.svg'

const VerifyEmail = () => {
  const { state } = useLocation()
  const [resendConfirmation] = useSendEmailConfirmationMutation()
  const [resendNotice, setResendNotice] = useState({
    type: '',
    message: '',
  })

  const onSubmit = async () => {
    try {
      const result = await resendConfirmation({
        email: state?.email,
      }).unwrap()
      if (result) {
        setResendNotice({
          type: 'success',
          message: 'A new email instruction has been sent to your email',
        })
      }
    } catch ({ data: resendEmailError }) {
      setResendNotice({
        type: 'error',
        message: resendEmailError.errors,
      })
    }
  }

  return (
    <div className="bg-[#F7F8FA] flex justify-center items-center h-screen">
      {resendNotice.message.length !== 0 && (
        <AuthNotice type={resendNotice.type} message={resendNotice.message} />
      )}
      <div>
        <div className="bg-white w-[440px] px-[45px] py-[60px] rounded-[40px] border-1 border-[#EDEDED]">
          <img src={QuesteonLogo} className="h-[40px]" />
          <div>
            <div className="flex items-center justify-center my-[40px]">
              <img src={verifyIcon} alt="" />
            </div>
            <div className="grid items-center justify-center text-[14px] text-[#5A5A60] leading-[21px]">
              <h3 className="text-[24px] text-center leading-[120%] text-[#262626] font-semibold">
                Verify your email
              </h3>
              <p className="text-center mb-[20px]">
                An instruction email has been sent to <br />
                <a
                  href="#0"
                  className="text-[#262626] font-semibold hover:text-[#262626]"
                >
                  {state?.email}
                </a>
              </p>
              <p className="text-center">
                If you have not received the email after a few <br /> minutes,
                please check your spam folder
              </p>
            </div>
          </div>
          <div className="mt-[60px] flex items-center justify-center text-[14px] text-[#5A5A60] leading-[21px]">
            <p>
              Still not receiving email?&nbsp;
              <button
                onClick={() => {
                  onSubmit()
                }}
                className="text-[#2C7D92] font-semibold hover:text-[#2C7D92] focus:outline-none focus:ring-0"
              >
                Resend email
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VerifyEmail
