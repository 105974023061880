import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setQuest } from 'features/questSlice'
import { useForm, Controller } from 'react-hook-form'
import RichTextInput from 'components/inputs/RichTextInput'
import {
  useUpdateQuestMutation,
  useUpdateQuestStepMutation,
  useListPlacesQuery,
} from 'services/questApi'
import { useUpdatePlacePositionMutation } from 'services/placeApi'
import { useNavigate } from 'react-router-dom'
import SaveNotice from 'components/SaveNotice'
import ErrorNotice from 'components/ErrorNotice'
import MobileConclusion from './mobilePreview/MobileConclusion'

const ConclusionForm = () => {
  const dispatch = useDispatch()
  const { quest, currentNavigation } = useSelector((state) => state.quest)
  const { data: places } = useListPlacesQuery(quest?.id)
  const { conclusion } = quest || {}
  const [updateQuest] = useUpdateQuestMutation()
  const [updateStep] = useUpdateQuestStepMutation()
  const [updatePlacePosition] = useUpdatePlacePositionMutation()
  const navigate = useNavigate()
  const [saveNotice, setSaveNotice] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isSave, setIsSave] = useState(false)
  const [item, setItem] = useState([])
  const [isPlace, setIsPlace] = useState([])
  const haveHiddenPlace = places?.some((place) => place.is_hidden)

  const {
    register,
    control,
    handleSubmit,
    setError,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm()

  useEffect(() => {
    if (quest) {
      const conclusionQuest = {
        badge_id: quest?.badge,
        description: conclusion?.description,
      }
      reset(conclusionQuest)
    }
  }, [quest, reset])

  useEffect(() => {
    if (isSave) {
      setTimeout(() => {
        if (Object.keys(errors).length > 0) {
          setIsError(true)
          setTimeout(() => {
            setIsError(false)
            setIsSave(false)
            clearErrors(errors)
          }, 2000)
        }
      }, 600)
    }
  }, [isSave, errors])

  useEffect(() => {
    if (places) {
      setItem(places)
      const hiddenPlaceIndex = places.findIndex(
        (option) => option.is_hidden === true
      )
      const newItems = Array.from(item)
      const [removed] = newItems.splice(hiddenPlaceIndex, 1)
      newItems.splice(places.length - 1, 0, removed)
      setIsPlace(
        newItems.map((item, index) => ({ id: item?.id, position: index }))
      )
    }
  }, [places, item])

  useEffect(() => {
    setSaveNotice(true)
    setTimeout(() => {
      setSaveNotice(false)
    }, 1000)
  }, [])

  const onSubmit = async (data) => {
    const questData = {
      conclusion_attributes: data,
    }
    if (haveHiddenPlace) {
      try {
        await updatePlacePosition({
          id: quest?.id,
          data: { data: isPlace },
        }).unwrap()
      } catch (error) {
        console.log(error)
      }
    }
    if (quest) {
      if (quest.status == 'ready') {
        setValue('status', 'draft')
      }
      try {
        const id = quest.id
        const questResponse = await updateQuest({
          id,
          quest: questData,
        }).unwrap()
        dispatch(setQuest(questResponse))

        if (currentNavigation === 'place_and_task') {
          await updateStep({ id: id, step: 'conclusion' })
        }
        if (questResponse.conclusion.description === '<p></p>\n') {
          setError('description', {
            type: 'description',
            message: 'Description is required',
          })
        }
        if (questResponse.conclusion.description !== '<p></p>\n') {
          navigate(`/quests/${quest?.slug}/edit/overview`)
        }
      } catch ({ data: updateQuestError }) {
        updateQuestError?.data?.forEach(({ name, message }) => {
          setError(name, { type: 'server', message })
        })
      }
    }
  }

  return (
    <>
      {saveNotice && <SaveNotice />}
      {isError && (
        <ErrorNotice content={<>Please check fields marked in red</>} />
      )}
      <div className="flex justify-between pr-[40px]">
        <div className="w-9/12 mr-4 relative">
          <div className="flex items-center text-[24px] leading-[130%] text-[#262626] font-semibold">
            <span className="flex items-center justify-center text-center mr-2 bg-[#FFCF50] w-[30px] h-[30px] rounded-full text-[16px] leading-[24px] text-[#262626]">
              4
            </span>
            Conclusion
          </div>
          <div className="mt-[40px]">
            <form onSubmit={handleSubmit(onSubmit)}>
              <input type="hidden" value={conclusion?.id} {...register(`id`)} />
              <input type="hidden" {...register('status')} />
              <div className="flex mb-[32px]">
                <label className="text-[16px] leading-[24px] text-[#262626] font-medium w-[200px] 2xl:w-[300px]">
                  Quest Conclusion <sup className="text-[#F15A5B]">*</sup>
                </label>
                <div className="w-[534px] 2xl:w-[800px]">
                  <Controller
                    name="description"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'Description is required' }}
                    render={({ field }) => (
                      <RichTextInput
                        {...field}
                        error={errors?.description}
                        placeholder="Tell us the conclusion about the quest"
                      />
                    )}
                  />
                  {errors?.description && (
                    <div className="text-[#F15A5B] text-[14px] leading-[150%]">
                      {errors?.description?.message}
                    </div>
                  )}
                </div>
              </div>

              <div className="flex items-center justify-between mt-20">
                <Link
                  to={`/quests/${quest?.slug}/edit/place`}
                  className="flex items-center justify-center bg-white rounded-[50px] text-[16px] leading-[24px] text-[#2C7D92] hover:text-[#2C7D92] border-[1px] border-[#2C7D92] font-semibold w-[160px] py-[13px]"
                >
                  Previous
                </Link>
                <button
                  onClick={() => setIsSave(true)}
                  type="submit"
                  className="flex items-center justify-center bg-[#2C7D92] rounded-[50px] text-[16px] leading-[24px] text-white font-semibold w-[160px] py-[13px]"
                >
                  Next
                </button>
              </div>
            </form>
          </div>
        </div>
        <MobileConclusion control={control} />
      </div>
    </>
  )
}

export default ConclusionForm
