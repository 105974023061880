import React from 'react'
import WarningCircle from 'assets/images/icon-warning-circle.svg'

const ErorNotice = ({ content }) => {
  return (
    <div className="absolute flex items-center justify-end -ml-[340px] -mt-[200px] w-full h-max z-20">
      <div className="flex items-center justify-start w-[318px] h-[56px] rounded-[14px] px-[22px] bg-[#F15A5B] mr-6">
        <div>
          <img src={WarningCircle} alt="" className="mr-2" />
        </div>
        <div className="text-[14px] leading-[21px] text-white">{content}</div>
      </div>
    </div>
  )
}

export default ErorNotice
