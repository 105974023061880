import React from 'react'
import { FiSearch } from 'react-icons/fi'
import Modal from 'react-bootstrap/Modal'

const CategoryFormModal = ({
  categoryOpen,
  inputHandler,
  quest,
  filteredData,
  categoryIdSelected,
  setValue,
  selectedCategory,
  setSelectedCategory,
  setCategoryOpen,
}) => {
  const onOptionCategoryClicked = (value) => {
    const updatedValue = [...selectedCategory, value]
    setSelectedCategory(updatedValue)
  }

  const removeValueAtIndex = (indexToRemove) => {
    const valueRemove = selectedCategory.filter((value) => {
      return value.id !== indexToRemove.id
    })

    setSelectedCategory(valueRemove)
  }
  return (
    <>
      <Modal
        show={categoryOpen}
        className="fixed top-0 left-0 right-0 bottom-0 bg-[rgba(0,0,0,0.5)] overflow-auto"
      >
        <div className="flex items-center justify-center w-screen h-screen">
          <div className="flex items-center justify-center bg-white w-[550px] rounded-[24px]">
            <div className="p-[40px] w-[550px] rounded-[24px] m-auto">
              <div className="text-[24px] leading-[130%] font-semibold text-[#262626]">
                Choose categories / tags that most suitable to your quest!
              </div>
              <div className="text-[14px] leading-[21px] text-[#5A5A60] mt-[15px] mb-[24px]">
                You can choose maximum 5.
              </div>
              <div className="flex items-center w-[470px] rounded-[24px] py-[14px] px-[17px] border-[1px] border-[#EDEDED]">
                <FiSearch className="text-xl mr-2 text-[#7D7D7D]" />
                <input
                  type="text"
                  className="text-[14px] leading-[21px]"
                  placeholder="Search tags"
                  onChange={inputHandler}
                />
              </div>
              <div className="mt-[24px]">
                {quest ? (
                  <ul className="flex flex-wrap whitespace-nowrap">
                    {filteredData?.map((item) => (
                      <div
                        key={item.id}
                        onClick={
                          Array.from(categoryIdSelected)?.includes(`${item.id}`)
                            ? () =>
                                setValue(
                                  `quest_categories_attributes[${item.id}]`,
                                  {
                                    id: selectedCategory.find(
                                      (value) => value.id === item.id
                                    ).category_id,
                                    _destroy: true,
                                  }
                                )
                            : () =>
                                setValue(
                                  `quest_categories_attributes[${item.id}]`,
                                  {
                                    category_id: `${item.id}`,
                                  }
                                )
                        }
                      >
                        <li
                          onClick={
                            Array.from(categoryIdSelected)?.includes(
                              `${item.id}`
                            )
                              ? () => removeValueAtIndex(item)
                              : () => onOptionCategoryClicked(item)
                          }
                          className={
                            Array.from(categoryIdSelected)?.includes(
                              `${item.id}`
                            )
                              ? 'flex items-center justify-center cursor-pointer text-[#2C7D92] bg-[#2c7d92]/10 text-[14px] leading-[150%] px-[20px] py-[9px] rounded-[40px] border-[1px] border-[#2C7D92] mr-1 my-1'
                              : 'flex items-center justify-center cursor-pointer bg-[#F7F8FA] text-[14px] leading-[150%] px-[20px] py-[9px] rounded-[40px] border-[1px] border-[#EDEDED] mr-1 my-1'
                          }
                        >
                          {item.name}
                        </li>
                      </div>
                    ))}
                  </ul>
                ) : (
                  <ul className="flex flex-wrap whitespace-nowrap">
                    {filteredData?.map((item) => (
                      <div key={item.id}>
                        <li
                          onClick={
                            Array.from(categoryIdSelected)?.includes(
                              `${item.id}`
                            )
                              ? () => removeValueAtIndex(item)
                              : () => onOptionCategoryClicked(item)
                          }
                          className={
                            Array.from(categoryIdSelected)?.includes(
                              `${item.id}`
                            )
                              ? 'flex items-center justify-center cursor-pointer text-[#2C7D92] bg-[#2c7d92]/10 text-[14px] leading-[150%] px-[20px] py-[9px] rounded-[40px] border-[1px] border-[#2C7D92] mr-1 my-1'
                              : 'flex items-center justify-center cursor-pointer bg-[#F7F8FA] text-[14px] leading-[150%] px-[20px] py-[9px] rounded-[40px] border-[1px] border-[#EDEDED] mr-1 my-1'
                          }
                        >
                          {item.name}
                        </li>
                      </div>
                    ))}
                  </ul>
                )}
              </div>
              <div className="flex items-center justify-between mt-[56px]">
                <div onClick={() => setCategoryOpen(false)}>
                  <button
                    className="flex items-center justify-center bg-white rounded-[50px] text-[16px] leading-[24px] text-[#F15A5B] border-[1px] border-[#F15A5B] font-semibold w-[160px] py-[13px]"
                    onClick={
                      quest
                        ? () => setCategoryOpen(false)
                        : () => setSelectedCategory([])
                    }
                  >
                    Cancel
                  </button>
                </div>
                <div>
                  <button
                    onClick={() => setCategoryOpen(false)}
                    type="submit"
                    className="bg-[#2C7D92] rounded-[50px] text-[16px] leading-[24px] text-white font-semibold w-[165px] py-[13px]"
                  >
                    Save Tags
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default CategoryFormModal
