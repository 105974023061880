import React from 'react'
import { IoMdSettings } from 'react-icons/io'
import { RiCompassFill } from 'react-icons/ri'
import logout from '../assets/images/sidebar/logout.svg'
import smile from '../assets/images/sidebar/smile.svg'
import logo_d from '../assets/images/sidebar/logo_maximize.svg'
import { useSignOutMutation } from 'services/authApi'
import { useGetCreatorQuery } from 'services/creatorApi'
import { Link, useLocation } from 'react-router-dom'

const Sidebar = () => {
  const [signOut] = useSignOutMutation()

  const routePath = useLocation()
  const questDetected = routePath.pathname.includes('quests')
  const settingDetected = routePath.pathname.includes('setting')

  const handleSignOut = async () => {
    await signOut()
  }

  const { data } = useGetCreatorQuery()

  return (
    <div className="w-[300px] bg-[#F7F8FA] h-screen flex justify-center">
      <div>
        <div className="grid justify-center items-center border-b-[1px] w-[300px] h-[80px]">
          <img src={logo_d} width="117" height="32" alt="" />
        </div>
        <div className="grid justify-center items-start my-[40px] w-[300px]">
          <div className="text-center">
            <div className="grid justify-center items-center">
              {/* {isLoading && <Skeleton circle height={100} width={100} />} */}
              <img
                src={data?.avatar_url ? data.avatar_url : smile}
                alt={data?.name}
                className="w-[100px] h-[100px] rounded-full"
              />
            </div>
            <p className="text-[18px] font-[700] pt-[24px]">{data?.name}</p>
            <span className="text-[14px] font-[400] text-[#7D7D7D]">
              {data?.email}
            </span>
          </div>
        </div>
        <div className="grid items-start justify-center w-[300px]">
          <Link
            to="/quests/list"
            className={
              questDetected
                ? 'flex items-center w-[252px] h-[55px] pl-[20px] py-[16px] text-[16px] font-[500] rounded-[24px] text-[#2C7D92] bg-[#2C7D92]/10 hover:text-[#2C7D92]'
                : 'flex items-center w-[252px] h-[55px] pl-[20px] py-[16px] text-[16px] font-[500] rounded-[24px] text-[#7D7D7D] hover:text-[#7D7D7D]'
            }
          >
            <RiCompassFill
              className={
                questDetected
                  ? 'w-[24px] h-[24px] mr-[12px] text-[#2C7D92]'
                  : 'w-[24px] h-[24px] mr-[12px]'
              }
            />
            My Quest
          </Link>
          <Link
            to="/setting"
            className={
              settingDetected
                ? 'flex items-center w-[252px] h-[55px] pl-[20px] py-[16px] text-[16px] font-[500] rounded-[24px] text-[#2C7D92] bg-[#2C7D92]/10 hover:text-[#2C7D92]'
                : 'flex items-center w-[252px] h-[55px] pl-[20px] py-[16px] text-[16px] font-[500] rounded-[24px] text-[#7D7D7D] hover:text-[#7D7D7D]'
            }
          >
            <IoMdSettings
              className={
                settingDetected
                  ? 'w-[24px] h-[24px] mr-[12px] text-[#2C7D92]'
                  : 'w-[24px] h-[24px] mr-[12px]'
              }
            />
            Settings
          </Link>
        </div>
        <div className="grid items-center justify-center absolute bottom-0 w-[300px]">
          <button
            onClick={handleSignOut}
            className="flex items-center w-[252px] h-[55px] focus:outline-none focus:ring-none pb-5 pl-[20px] text-[16px] font-[500] text-[#F15A5B]"
          >
            <img
              src={logout}
              className="w-[24px] h-[24px] mr-[12px]"
              alt="logout"
            />
            Sign out
          </button>
        </div>
      </div>
    </div>
  )
}

export default Sidebar
