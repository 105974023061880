import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useForgotPasswordMutation } from 'services/authApi'
import useWindowDimensions from '../hooks/WindowDimensions'
import QuesteonLogo from 'assets/images/questeon_logo.svg'
import messageIcon from 'assets/images/authpage/forgotpassword_icon.svg'

const ForgotPassword = () => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm()
  const navigate = useNavigate()
  const [forgotPassword] = useForgotPasswordMutation()
  const onSubmit = async (data) => {
    try {
      const response = await forgotPassword(data).unwrap()
      if (response) {
        navigate('/check-email', { state: { email: data.email } })
      }
    } catch ({ data: forgotPasswordError }) {
      forgotPasswordError?.data?.forEach(({ name, message }) => {
        setError(name, { type: 'server', message })
      })
    }
  }
  const { height } = useWindowDimensions()
  return (
    <div
      style={{
        height: height,
      }}
      className="bg-[#F7F8FA] flex justify-center items-center text-[#262626]"
    >
      <div className="w-[440px] bg-white py-[60px] px-[50px] border-1 border-[#EDEDED] rounded-[40px]">
        <div>
          <img src={QuesteonLogo} alt="" className="h-[40px]" />
        </div>
        <div className="py-[45px]">
          <h3 className="text-[24px] leading-[120%] text-[#262626] font-semibold">
            Forgot password
          </h3>
          <p className="text-[14px] leading-[21px] text-[#5A5A60] pt-[8px]">
            Enter your registered email address to receive password reset
            instruction.
          </p>
        </div>
        <div className="">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="text-[14px] leading-[21px] text-[#262626]">
              <label>Email</label>
              <input
                type="email"
                placeholder="Your email"
                // className={`form-control ${
                //     errors.email && 'is-invalid'
                // }`}
                className="w-[340px] border-2 border-[#EDEDED] rounded-[24px] py-[14px] px-[24px] text-[#7D7D7D] mt-[8px] mb-[24px] focus:outline-none focus:bg-[#F7F8FA] invalid:border-1 invalid:border-[#F15A5B] invalid:text-[#F15A5B]"
                {...register('email', {
                  required: 'Email is required.',
                })}
              />
              {errors.email && (
                <div className="invalid-feedback">{errors.email.message}</div>
              )}
            </div>
            <div className="pt-[24px] text-white font-semibold">
              <button
                type="submit"
                className="flex justify-center items-center w-[340px] text-center bg-[#2C7D92] rounded-[50px] py-[11px]"
              >
                <img src={messageIcon} alt="messageIcon" className="pr-3" />
                Send Instruction
              </button>
            </div>
            <div className="flex items-center justify-center pt-[24px]">
              <Link
                to="/signin"
                className="text-[#2C7D92] hover:text-[#2C7D92] font-semibold text-[14px] leading-[21px] focus:outline-none focus:ring-0"
              >
                Back to Sign In
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword
