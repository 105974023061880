/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import Logo from 'assets/images/questeon_logo.svg'
import TermsLogo from 'assets/images/terms_signup.svg'

const SignUpTerms = () => {
  return (
    <div>
      <div className="flex items-center justify-center py-[24px]">
        <img src={Logo} alt="logo" className="h-[40px]" />
      </div>
      <div className="flex items-center justify-between bg-[#F7F8FA] px-[247px] h-[360px]">
        <div>
          <div className="w-[461px] py-[124px] 2xl:w-full">
            <h2 className="text-[36px] leading-[120%] font-[36px] text-[#262626]">
              Terms & Conditions
            </h2>
            <p>
              Read our terms below about creators, quest review framework, and
              data privacy.
            </p>
          </div>
        </div>
        <div className="mt-20">
          <img src={TermsLogo} alt="TermsLogo" className="w-[336px]" />
        </div>
      </div>
      <div className="px-[247px] mt-[32px] text-[14px] leading-[21px]">
        <div>Updated Aug 25, 2022</div>
        <div className="border-b border-1 my-[24px]"></div>
        <div>
          <p className="font-bold">All About Being a Creator</p>
          <p className="-mt-[8px]">
            To summarize : A creator is someone who creates an account on
            Questeon to create quests for users. There are a lot of details
            below involving payments, fees, taxes and restrictions that you
            should read in full if you are a creator.
          </p>
        </div>
        <div className="mt-[24px]">
          <p className="font-bold">Quest Listing Policy</p>
          <p className="-mt-[8px]">
            To summarize : We will like to build a community of trust and a safe
            environment for players engaging in our quests. The following
            content is not allowed on our Quests :
          </p>
          <div className="ml-8 -mt-[8px]">
            <ul className="list-disc">
              <li className="font-bold">Child Endangerment</li>
              <p>
                Questeon has a zero-tolerance policy for endangering children.
                This includes :
              </p>
              <div className="ml-8 -mt-[8px]">
                <li>
                  Any predatory behavior, including attempting to befriend a
                  child in order to manipulate and exploit them (i.e., grooming)
                </li>
                <li>Sexualizing children in any way</li>
                <li>
                  Engaging in sexual conversation with or soliciting sexual
                  material from minors
                </li>
                <li>
                  Sharing, requesting, or discussing child sexual exploitation
                  imagery
                </li>
              </div>
              <li className="font-bold mt-[8px]">Threats of Violence</li>
              <p>
                Questeon has a zero-tolerance policy for endangering children.
                This includes :
              </p>
              <div className="ml-8 -mt-[8px]">
                <li>
                  Any predatory behavior, including attempting to befriend a
                  child in order to manipulate and exploit them (i.e., grooming)
                </li>
                <li>Sexualizing children in any way</li>
                <li>
                  Engaging in sexual conversation with or soliciting sexual
                  material from minors
                </li>
                <li>
                  Sharing, requesting, or discussing child sexual exploitation
                  imagery
                </li>
              </div>
            </ul>
          </div>
        </div>
        <div className="mt-[24px]">
          <p className="font-bold">Questeon's Role</p>
          <p className="-mt-[8px]">
            To summarize : We proactively look at some pages and posts on
            Questeon and review reported pages to identify potential violations
            of our guidelines. We proactively review the quests listed on
            Questeon to make sure creators follow our Guidelines.
            <br />
            <br /> We also investigate reports of potential violations. These
            investigations may take a while to resolve and may include looking
            at what is supported by sales received through Questeon. In most
            situations we will work with creators to resolve any potential
            violations and allow the creator to continue using Questeon.
            <br />
            <br />
            Terminating accounts is not an action we take lightly and is done in
            only the most extreme cases.
            <br />
            <br /> Questeon reserves the right to remove a creator from our
            system. We are constantly testing out new features with the goal of
            making Questeon better. We may add or remove features, and often
            test features with a random subset of our community. If we believe a
            feature is significantly different from these terms, then we explain
            those differences in the test.
            <br />
            <br /> With your permission, we may give other websites or services
            the ability to verify information about your Questeon account or
            perform actions on your behalf. This permission is asked for when
            you connect your Questeon account to these other websites or
            services. You can learn more in our Privacy Policy.
          </p>
        </div>
        <div className="mt-[24px]">
          <p className="font-bold">Dispute Resolution</p>
          <p className="-mt-[8px]">
            To summarize: If you have a problem please talk to us. Any disputes
            with us must be resolved in Singapore under Singapore laws.
            <br />
            <br /> We encourage you to contact us if you have an issue. If a
            dispute does arise out of these terms or in relation to your use of
            Questeon, then the dispute will be resolved in courts located in
            Singapore. Both parties consent to the exclusive jurisdiction and
            venue of the Singapore courts for the purpose of resolving any such
            dispute. Singapore law, excluding its conflict of law provisions,
            governs these terms, all other Questeon policies, and any dispute
            that arises between you and Questeon.
          </p>
        </div>
        <div className="mt-[24px]">
          <p className="font-bold">Everything Else</p>
          <p className="-mt-[8px]">
            To summarize : These terms are the final word on Questeon's policies
            and we will tell you if we change them.
            <br />
            <br /> These terms and any referenced policies are the entire
            agreement between you and us, and supersede all prior agreements. If
            any provision of these terms is held to be unenforceable, then that
            provision is modified to the extent necessary to enforce it. If a
            provision cannot be modified, it is severed from these terms, and
            all other provisions remain in force. If either party fails to
            enforce a right provided by these terms, it does not waive the
            ability to enforce any rights in the future. We may sometimes make
            changes to these terms and policies. If we make material changes
            that adversely affect your rights, then we will let you know before
            the changes come into effect. Continuing to use Questeon after a
            change means you accept the new terms or policies. These terms are
            an agreement with Questeon Pte Ltd, based on the address registered
            with ACRA.
          </p>
        </div>
        <div className="mt-[24px]">
          <div className="border-b border-1 mb-[24px]"></div>
          <p className="text-[#7D7D7D]">
            © 2022 Questeon. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  )
}

export default SignUpTerms
