import React, { useEffect, useState } from 'react'
import { DeviceFrameset } from 'react-device-frameset'
import { useWatch } from 'react-hook-form'
import 'react-device-frameset/styles/marvel-devices.min.css'
import conclutionBg from 'assets/images/mobile/conclution_bg.svg'
import { FiShare2 } from 'react-icons/fi'

const MobileConclusion = ({ control }) => {
  const [previewMobile, setPreviewMobile] = useState({
    description: '',
    badge_id: [],
  })

  const setDescription = (e) => {
    setPreviewMobile((existingValues) => ({
      ...existingValues,
      description: e,
    }))
  }

  const setBadge = (e) => {
    setPreviewMobile((existingValues) => ({
      ...existingValues,
      badge_id: e,
    }))
  }

  const getBadge = useWatch({
    control,
    name: 'badge_id',
  })

  const getDescription = useWatch({
    control,
    name: 'description',
  })

  useEffect(() => {
    if (getDescription) {
      setDescription(getDescription.replace(/<[^>]+>/g, ''))
    }

    if (getBadge) {
      setBadge(getBadge)
    }
  }, [getDescription, getBadge])

  return (
    <>
      <div className="w-3/12 -mt-[100px] sticky top-0 h-[600px]">
        <div className="-ml-14 -mt-[130px]">
          <DeviceFrameset device="iPhone X" zoom={0.6} potrait="true">
            <div className="h-[800px] overflow-auto">
              <div
                style={{
                  backgroundImage: `url(${conclutionBg} )`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'bottom',
                }}
                className="flex items-start justify-center w-full h-[300px]"
              >
                <div className="p-[17px] text-[24px] leading-[130%] text-white font-bold text-center mt-14">
                  Congratulations on completing the quest!
                  <div className="text-[18px] leading-[130%] text-white font-semibold text-center mt-6">
                    You earn a new badge ✨
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-center -mt-20">
                <img
                  src={previewMobile?.badge_id?.image?.url}
                  alt=""
                  className="w-[160px] rounded-full"
                />
              </div>
              <div className="py-[30px] mx-[17px] text-[21px] leading-[130%] text-black font-bold text-center mt-2 border-b-[1px]">
                {previewMobile?.badge_id?.description}
              </div>
              <div className="p-[17px] flex items-center justify-start mt-[12px]">
                <div className="text-[16px] leading-[150%] text-[#5A5A60]">
                  {previewMobile.description || 'Conclusion text goes here ...'}
                </div>
              </div>
              <div className="p-[17px] flex items-center justify-center mt-[80px]">
                <div className="text-[14px] leading-[150%]">
                  <div className="flex items-center justify-between bg-questeon rounded-[30px] py-[12px] px-[35px]">
                    <FiShare2 className="text-white text-2xl" />
                    <div className="text-white font-bold ml-4">
                      Share your achievement
                    </div>
                  </div>
                  <div className="flex items-center justify-between bg-[#2c7d92]/10 rounded-[30px] py-[12px] px-[35px] mt-[16px]">
                    <div className="text-questeon font-bold w-full text-center">
                      Share your achievement
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </DeviceFrameset>
        </div>
      </div>
    </>
  )
}

export default MobileConclusion
