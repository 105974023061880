/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { AiOutlineClose } from 'react-icons/ai'
import { FaCircle } from 'react-icons/fa'

const PublishEditModal = ({ showModal, editClick, closeModal }) => {
  return (
    <>
      <Modal
        show={showModal}
        className="fixed top-0 left-0 right-0 bottom-0 bg-[rgba(0,0,0,0.5)] overflow-auto"
      >
        <div className="flex items-center justify-center w-screen h-screen">
          <Modal.Body className="flex items-center justify-center bg-white w-[400px] rounded-[24px]">
            <div className="px-[24px] mb-10 w-[400px] rounded-[24px] m-auto">
              <div className="flex items-center justify-between py-[22px]">
                <p className="text-[18px] leading-[150%] text-[#262626] font-semibold"></p>
                <div onClick={closeModal} className="cursor-pointer">
                  <AiOutlineClose className="text-[#2C7D92] text-xl" />
                </div>
              </div>
              <div className="text-[24px] leading-[120%] font-bold text-[#262626]">
                Looks like you’re about to edit a published quest
              </div>
              <div className="text-[14px] leading-[21px] text-[#262626] pt-[24px] mb-2">
                There’s some information that you need to know before making the
                edits :
                <ul className="pt-[16px]">
                  <li className="pb-[11px] flex items-start">
                    <div className="text-[#FFCF50] w-[5px] mr-2 pt-1">
                      <FaCircle className="text-[#FFCF50] w-[5px]" />
                    </div>
                    If you edit the quest, all previous users that already have
                    this quest will not be impacted, and will continue to play
                    the old quest version without being interrupted.
                  </li>
                  <li className="pb-[11px] flex items-start">
                    <div className="text-[#FFCF50] w-[5px] mr-2 pt-1">
                      <FaCircle className="text-[#FFCF50] w-[5px]" />
                    </div>
                    Your updated quest can be played for new users only.
                  </li>
                  <li className="pb-[11px] flex items-start">
                    <div className="text-[#FFCF50] w-[5px] mr-2 pt-1">
                      <FaCircle className="text-[#FFCF50] w-[5px]" />
                    </div>
                    <div>You cannot revert all the changes you have made.</div>
                  </li>
                  <li className="pb-[11px] flex items-start">
                    <div className="text-[#FFCF50] w-[5px] mr-2 pt-1">
                      <FaCircle className="text-[#FFCF50] w-[5px]" />
                    </div>
                    Your updated quest will be reviewed again by our team before
                    it replaces the old one.
                  </li>
                </ul>
              </div>
              <div>
                <button
                  className="flex items-center justify-center text-[#FFFFFF] text-[16px] leading-[24px] border-[#C2C2C2] bg-[#2C7D92] font-bold py-[13px] w-[352px] rounded-[50px]"
                  onClick={editClick}
                >
                  Continue to Edit
                </button>
                <button
                  className="flex items-center justify-center text-[#F15A5B] text-[16px] leading-[24px] border-[#F15A5B] border-[1px] font-bold py-[13px] w-[352px] rounded-[50px] mt-3"
                  onClick={closeModal}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  )
}

export default PublishEditModal
