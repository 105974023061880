/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import Modal from 'react-bootstrap/Modal'
import ConfirmedLogo from 'assets/images/confirmation.svg'

const PublishSuccessModal = ({ showModal, closeModal }) => {
  return (
    <>
      <Modal
        show={showModal}
        onHide={closeModal}
        className="fixed top-0 left-0 right-0 bottom-0 bg-[rgba(0,0,0,0.5)] overflow-auto"
      >
        <div className="flex items-center justify-center w-screen h-screen">
          <Modal.Body className="flex items-center justify-center bg-white w-[400px] rounded-[24px]">
            <div className="px-[24px] mb-10 w-[400px] rounded-[24px] m-auto mt-[80px]">
              <div className="flex justify-center">
                <img src={ConfirmedLogo} alt="" className="" />
              </div>
              <div className="text-center text-[24px] leading-[130%] font-semibold text-[#262626] mt-[29px]">
                Quest Published
              </div>
              <div className="text-center text-[14px] leading-[150%] text-[#5A5A60] mt-[15px]">
                Congratulations your quest now is published.
              </div>
              <div className="mt-[30px] flex justify-center">
                <button
                  className="flex items-center justify-center text-white text-[16px] leading-[24px] bg-[#2C7D92] border-[1px] font-bold py-[13px] px-[145px] rounded-[50px] mt-3"
                  onClick={closeModal}
                >
                  OK
                </button>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  )
}

export default PublishSuccessModal
