import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import chevronup from 'assets/images/questinfo/chevronup.svg'
import chevrondown from 'assets/images/questinfo/chevrondown.svg'
import { FaPlus, FaStar, FaTrash } from 'react-icons/fa'
import { RiArrowUpDownFill } from 'react-icons/ri'
import Skeleton from 'react-loading-skeleton'
import { v4 as uuid } from 'uuid'
import { useDeleteDialog } from 'components/DeleteDialog'
import HTMLView from 'components/HTMLView'
import PlaceIcon from 'assets/images/questinfo/subplace.svg'
import { TaskType } from 'utils/questUtils'
import star from 'assets/images/questdetail/star.svg'
import task2 from 'assets/images/questinfo/task2.svg'
import radius from 'assets/images/questinfo/radius.svg'
import delet from 'assets/images/questinfo/delet.svg'
import { useListPlacesQuery } from 'services/questApi'
import { useDeletePlaceMutation } from 'services/placeApi'
import { useDeleteTaskMutation } from 'services/taskApi'
import { useShowModal } from 'utils/questModal'
import { setPlace } from 'features/questSlice'
import { useDeleteSubplaceMutation } from 'services/subplaceApi'
import InfoIcon from 'assets/images/info.svg'
import ReactTooltip from 'react-tooltip'
import EmptyPlaceList from 'components/quest/EmptyPlaceList'
import edit from 'assets/images/questdetail/edit.svg'

const ListPlace = (props) => {
  const { id, editable, errorPlaces, errorSubplaces } = props
  const { reFetchPlaces } = useSelector((state) => state.quest)
  const showModal = useShowModal()
  const { data: places, refetch, isLoading } = useListPlacesQuery(id)
  const [deletePlace] = useDeletePlaceMutation()
  const deleteDialog = useDeleteDialog()
  const haveHiddenPlace = places?.some((place) => place.is_hidden)

  const onEditPlaceClick = (id) => {
    showModal({ component: 'PlaceFormModal', props: { id: id } })
  }

  const onDeletePlaceClick = (place) => {
    deleteDialog({ deleteHandler: deletePlace, id: place.id })
  }

  const onAddHiddenPlaceClick = () => {
    showModal({ component: 'PlaceFormModal', props: { isHidden: true } })
  }

  useEffect(() => {
    if (reFetchPlaces) {
      refetch()
    }
  }, [refetch, reFetchPlaces])

  return (
    <>
      {isLoading && <SkeletonPlace />}
      {places?.length === 0 && <EmptyPlaceList />}
      {places?.map((place) => (
        <>
          {place.have_subplaces ? (
            <>
              <div
                className={
                  errorSubplaces &&
                  Array.from(errorSubplaces)?.includes(`${place.id}`)
                    ? 'flex items-center justify-between bg-white border-[1px] border-[#F15A5B] rounded-2xl my-4 h-[450px] overflow-auto'
                    : 'flex items-center justify-between bg-white border-[1px] rounded-2xl my-4 h-[450px] overflow-auto'
                }
                key={`place-${place.id}`}
              >
                <div
                  className={
                    place.is_hidden
                      ? 'grid justify-center bg-[#FFCF50]/20 rounded-l-xl w-[260px] py-[24px] px-[24px] h-full'
                      : 'grid justify-center bg-[#F7F8FA] rounded-l-xl w-[260px] py-[24px] px-[24px] h-full'
                  }
                >
                  <div className="w-[220px] h-[165px]">
                    <img
                      src={place.image_url}
                      alt=""
                      className="w-[220px] h-[165px] rounded-[16px] object-fit object-center"
                    />
                  </div>
                  <div className="my-[14px]">
                    <p className="text-ellipsis overflow-hidden text-[18px] leading-[150%] font-semibold text-[#262626]">
                      {place.title}
                    </p>
                    <div className="flex items-center font-medium text-sm mt-[16px]">
                      <img src={radius} alt="" className="pr-2" />
                      <span>{place.radius} km</span>(Radius)
                    </div>
                    <div className="flex items-center font-medium text-sm pt-2">
                      <img src={task2} alt="" className="pr-2" />
                      <span className="">{place.tasks_count} Task</span>
                    </div>
                    {place.is_hidden && (
                      <p className="flex items-center text-[14px] leading-[150%] font-semibold text-[#7D7D7D] mt-4">
                        <div className="mr-2">
                          <FaStar className="text-xl text-[#FFCF50]" />
                        </div>
                        <span>Hidden Place</span>
                      </p>
                    )}
                  </div>

                  {editable && (
                    <div className="flex items-center justify-between">
                      <a
                        href="#0"
                        onClick={() => onEditPlaceClick(place.id)}
                        className="flex items-center text-[14px] leading-[150%] font-semibold text-[#2C7D92] hover:text-[#2C7D92]"
                      >
                        <img src={edit} alt="" className="mr-2" />
                        Edit Place
                      </a>
                      <a
                        href="#0"
                        onClick={() => onDeletePlaceClick(place)}
                        className="p-2 rounded-full bg-[#F15A5B]"
                      >
                        <FaTrash className="text-white" />
                      </a>
                    </div>
                  )}
                </div>
                <div className="scroolsub w-full h-full py-[24px] px-[24px] overflow-auto">
                  {
                    <SubPlaceView
                      place={place}
                      {...props}
                      errorSubplaces={errorSubplaces}
                    />
                  }
                </div>
              </div>
              {errorSubplaces &&
                Array.from(errorSubplaces)?.includes(`${place.id}`) && (
                  <div className="text-[#F15A5B] text-[14px] leading-[150%] text-center -mt-4">
                    Required to input at least one task
                  </div>
                )}
            </>
          ) : (
            <>
              <div
                className={
                  errorPlaces &&
                  Array.from(errorPlaces)?.includes(`${place.id}`)
                    ? 'flex items-center justify-between bg-white border-[1px] border-[#F15A5B] rounded-2xl my-4 h-[450px] overflow-auto'
                    : 'flex items-center justify-between bg-white border-[1px] rounded-2xl my-4 h-[450px] overflow-auto'
                }
                key={`place-${place.id}`}
              >
                <div
                  className={
                    place.is_hidden
                      ? 'grid justify-center bg-[#FFCF50]/20 rounded-l-xl w-[260px] py-[24px] px-[24px] h-full'
                      : 'grid justify-center bg-[#F7F8FA] rounded-l-xl w-[260px] py-[24px] px-[24px] h-full'
                  }
                >
                  <div className="w-[220px] h-[165px]">
                    <img
                      src={place.image_url}
                      alt=""
                      className="w-[220px] h-[165px] rounded-[16px] object-fit object-center"
                    />
                  </div>
                  <div className="my-[14px]">
                    <p className="text-ellipsis overflow-hidden text-[18px] leading-[150%] font-semibold text-[#262626]">
                      {place.title}
                    </p>
                    <div className="flex items-center font-medium text-sm mt-[16px]">
                      <img src={radius} alt="" className="pr-2" />
                      <span>{place.radius} km</span>(Radius)
                    </div>
                    <div className="flex items-center font-medium text-sm pt-2">
                      <img src={task2} alt="" className="pr-2" />
                      <span className="">{place.tasks_count} Task</span>
                    </div>
                    {place.is_hidden && (
                      <p className="flex items-center text-[14px] leading-[150%] font-semibold text-[#7D7D7D] mt-4">
                        <div className="mr-2">
                          <FaStar className="text-xl text-[#FFCF50]" />
                        </div>
                        <span>Hidden Place</span>
                      </p>
                    )}
                  </div>
                  {editable && (
                    <div className="flex items-center justify-between">
                      <a
                        href="#0"
                        onClick={() => onEditPlaceClick(place.id)}
                        className="flex items-center text-[14px] leading-[150%] font-semibold text-[#2C7D92] hover:text-[#2C7D92]"
                      >
                        <img src={edit} alt="" className="mr-2" />
                        Edit Place
                      </a>
                      <a
                        href="#0"
                        onClick={() => onDeletePlaceClick(place)}
                        className="p-2 rounded-full bg-[#F15A5B]"
                      >
                        <FaTrash className="text-white" />
                      </a>
                    </div>
                  )}
                </div>
                <div className="scroolsub w-full h-full py-[24px] px-[24px] overflow-auto">
                  {<PlaceTaskView place={place} {...props} />}
                </div>
              </div>
              {errorPlaces &&
                Array.from(errorPlaces)?.includes(`${place.id}`) && (
                  <div className="text-[#F15A5B] text-[14px] leading-[150%] text-center -mt-4">
                    Required to input at least one task
                  </div>
                )}
            </>
          )}
        </>
      ))}
      {editable && places?.length > 0 && !haveHiddenPlace && (
        <div className="my-[40px] flex items-center justify-center">
          <div>
            <a
              href="#0"
              onClick={onAddHiddenPlaceClick}
              className="border-[1px] border-[#2C7D92] hover:text-[#2C7D92] rounded-[50px] flex items-center justify-center text-[16px] leading-[24px] px-[26px] py-[13px] font-semibold text-[#2C7D92]"
            >
              <img src={star} alt="" className="pr-2" />
              &nbsp;Add Hidden Place?
            </a>
            <p className="flex items-center justify-center mt-[16px]">
              What is Hidden Place?&nbsp;
              <img
                type="button"
                src={InfoIcon}
                alt=""
                data-tip
                data-for="hiddenPlaceTooltip"
              />
              <ReactTooltip id="hiddenPlaceTooltip" effect="solid">
                <span>
                  A place that will only be shown after all tasks from
                  non-hidden places are finished
                </span>
              </ReactTooltip>
            </p>
          </div>
        </div>
      )}
    </>
  )
}

export default ListPlace

const TaskView = (props) => {
  const { task, place, editable } = props
  const dispatch = useDispatch()
  const showModal = useShowModal()
  const [deleteTask] = useDeleteTaskMutation()
  const deleteDialog = useDeleteDialog()

  const onEditTaskClick = () => {
    dispatch(setPlace(place))
    showModal({ component: 'TaskFormModal', props: { id: task.id } })
  }

  const onDeleteTaskClick = () => {
    deleteDialog({ deleteHandler: deleteTask, id: task.id })
  }

  return (
    <div className="flex items-center justify-between mb-4">
      <div className="w-[400px]">
        <p className="text-[14px] leading-[150%] font-semibold text-[#262626]">
          {task.title}
        </p>
        <p className="text-[12px] leading-[150%] text-[#7D7D7D]">
          <TaskType type={task.task_type} />
        </p>
      </div>
      <div className="">
        {editable && (
          <div className="flex items-center">
            <a
              href="#0"
              onClick={onEditTaskClick}
              className="text-[14px] leading-[150%] font-semibold text-[#2C7D92] hover:text-[#2C7D92] mr-4"
            >
              Edit
            </a>
            <a href="#0" className="w-[20px]" onClick={onDeleteTaskClick}>
              <img src={delet} alt="" className="w-[20px]" />
            </a>
          </div>
        )}
      </div>
    </div>
  )
}

const PlaceTaskView = (props) => {
  const showModal = useShowModal()
  const dispatch = useDispatch()

  const { place, editable } = props

  const onAddTask = () => {
    dispatch(setPlace(place))
    showModal({ component: 'TaskFormModal' })
  }

  const onEditTaskSequence = () => {
    showModal({
      component: 'TaskSequenceModal',
      props: {
        parentId: place.id,
        parentType: 'Place',
      },
    })
  }

  return (
    <>
      <div className="flex items-center justify-between mb-[30px]">
        <span className="flex items-center text-[14px] leading-[150%] font-semibold text-[#7D7D7D]">
          <img src={task2} alt="" className="mr-2" /> {place.tasks_count} Task
        </span>
        {editable && (
          <div className="flex items-center justify-between">
            <button
              onClick={onAddTask}
              className="flex items-center border-[1px] border-[#2C7D92] focus:outline-none focus:ring-none rounded-[50px] py-[9.5px] px-[16px] text-[14px] leading-[21px] font-semibold text-[#2C7D92]"
            >
              <FaPlus className="text-[#2C7D92]" />
              &nbsp;Add
            </button>
            {place.tasks.length > 1 && (
              <button
                onClick={onEditTaskSequence}
                className="focus:outline-none focus:ring-none ml-4"
              >
                <RiArrowUpDownFill className="text-[#2C7D92] text-xl " />
              </button>
            )}
          </div>
        )}
      </div>
      <div className="">
        {place.tasks.map((task) => (
          <TaskView task={task} key={`task-${task.id}`} {...props} />
        ))}
      </div>
    </>
  )
}

const SubPlaceView = (props) => {
  const { place, editable } = props
  const showModal = useShowModal()
  const dispatch = useDispatch()
  const deleteDialog = useDeleteDialog()
  const [deleteSubplace] = useDeleteSubplaceMutation()
  const [data, setData] = useState(new Array(place?.subplaces.length))
  const SubplacesTask = place?.subplaces.map((subplace) => subplace.tasks_count)

  const updateFieldChanged = (index) => (e) => {
    let newArr = [...data]
    newArr[index] = e.target.value
    setData(newArr)
  }

  const onAddSubplace = () => {
    showModal({
      component: 'SubplaceFormModal',
      props: { parentId: place.id },
    })
  }

  const onEditSubplaceClick = (id) => {
    showModal({
      component: 'SubplaceFormModal',
      props: { id, place: place },
    })
  }

  const onDeleteSubplaceClick = (id) => {
    deleteDialog({ deleteHandler: deleteSubplace, id: id })
  }

  const onAddTask = (id) => {
    dispatch(setPlace(place))
    showModal({
      component: 'TaskFormModal',
      props: { parentId: id, parentType: 'Subplace' },
    })
  }

  const onEditTaskSequence = (id) => {
    showModal({
      component: 'TaskSequenceModal',
      props: {
        parentId: id,
        parentType: 'Subplace',
      },
    })
  }

  return (
    <>
      <div className="">
        <HTMLView content={place.description} />
        <div className="flex items-center justify-between">
          <span className="flex items-center justify-between text-[14px] leading-[150%] font-semibold text-[#7D7D7D]">
            <img src={PlaceIcon} alt="" className="mr-2" />
            {place.subplaces_count} Sub Place
          </span>
          {editable && (
            <button
              onClick={onAddSubplace}
              className="text-[14px] leading-[21px] font-semibold text-[#2C7D92] px-[16px] py-[9.5px] border-[1px] border-[#2C7D92] rounded-[50px]"
            >
              Add Subplace
            </button>
          )}
        </div>
      </div>
      <div className="mt-[20px]">
        {place.subplaces.map((subplace, index) => (
          <div
            className="border-[1px] p-[20px] rounded-[20px] mb-[20px]"
            key={`subplace-${subplace.id}`}
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <div className="mr-[20px]">
                  <img
                    src={subplace.image_url}
                    alt=""
                    className="w-[80px] h-[80px] rounded-[10px] object-fit object-center"
                  />
                </div>
                <div className="text-[12px] leading-[150%] text-[#7D7D7D] font-light">
                  <p className="text-[18px] leading-[150%] font-semibold text-[#262626]">
                    {subplace.title}
                  </p>
                  <HTMLView content={subplace.description} />
                  <div className="mt-3">
                    {editable && (
                      <>
                        <a
                          href="#0"
                          onClick={() => onEditSubplaceClick(subplace.id)}
                          className="text-[14px] leading-[150%] font-semibold text-[#2C7D92] mr-4"
                        >
                          Edit
                        </a>
                        <a
                          href="#0"
                          className="text-[14px] leading-[150%] font-semibold text-[#F15A5B]"
                          onClick={() => onDeleteSubplaceClick(subplace.id)}
                        >
                          Delete
                        </a>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <label className="cursor-pointer">
                <input
                  type="checkbox"
                  name="acceptRules"
                  id={`subplace-${subplace.id}`}
                  value={
                    document.getElementById(`subplace-${subplace.id}`)?.checked
                  }
                  onChange={updateFieldChanged(index)}
                />

                {data[index] === 'false' ? (
                  <div className="-mt-[10px]">
                    <img src={chevronup} alt="" className="" />
                  </div>
                ) : (
                  <div className="-mt-[10px]">
                    <img src={chevrondown} alt="" className="" />
                  </div>
                )}
              </label>
            </div>
            {data[index] === 'false' ? (
              <></>
            ) : (
              <div className="mt-[20px]" id={`subplace-${subplace.id}`}>
                <div className="flex items-center justify-between">
                  <div className="flex items-center tems-center text-[14px] leading-[150%] font-semibold text-[#7D7D7D] mb-2">
                    <img src={task2} alt="" className="pr-2" />
                    <p>{subplace.tasks_count} Task</p>
                  </div>
                  {editable && (
                    <div className="flex items-center justify-between">
                      <button
                        onClick={() => {
                          onAddTask(subplace.id)
                        }}
                        className="flex items-center justify-center text-[14px] leading-[150%] focus:ring-none font-semibold text-[#2C7D92] border-[1px] border-[#2C7D92] rounded-[50px] px-[16px] py-[9.5px]"
                      >
                        <FaPlus />
                        &nbsp;Add
                      </button>
                      {SubplacesTask[index] > 1 && (
                        <button
                          onClick={() => {
                            onEditTaskSequence(subplace.id)
                          }}
                          className="flex items-center justify-center focus:ring-none focus:outline-none ml-4"
                        >
                          <RiArrowUpDownFill className="text-[#2C7D92] text-lg" />
                        </button>
                      )}
                    </div>
                  )}
                </div>
                <div className="mt-[20px]">
                  {subplace.tasks.map((task) => (
                    <TaskView task={task} key={`task-${task.id}`} {...props} />
                  ))}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  )
}

const SkeletonTask = () => {
  return (
    <div className="" key={uuid}>
      <div>
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </div>
    </div>
  )
}

const SkeletonPlace = ({ count = 2 }) => {
  return (
    <>
      {Array.from({ length: count }).map((_, index) => (
        <div className="" key={`${index}-${uuid()}`}>
          <div className="">
            <div className="">
              <Skeleton height={200} width={200} />
            </div>
            <div className="">
              <h3>
                <Skeleton width={250} />
              </h3>
              <p>
                <Skeleton width={150} />
              </p>
              <p>
                <Skeleton width={150} />
              </p>
            </div>
          </div>
          <div className="">
            <div className="">
              <p>
                <Skeleton />
              </p>
              <div className="">
                <span>
                  <Skeleton width={200} />
                </span>
              </div>
              <span></span>
            </div>
            <div className="">
              <Skeleton />
              <Skeleton />
              <div className="">
                <br />
                {Array.from({ length: 2 }).map(() => (
                  <SkeletonTask key={`${index}-${uuid()}`} />
                ))}
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  )
}
