import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { AiOutlineClose } from 'react-icons/ai'
import { FaTrash } from 'react-icons/fa'
import { FiAlertCircle } from 'react-icons/fi'

const DeleteModal = ({ showModal, deleteClick, closeModal }) => {
  return (
    <>
      <Modal
        show={showModal}
        className="fixed top-0 left-0 right-0 bottom-0 bg-[rgba(0,0,0,0.5)] overflow-auto"
      >
        <div className="flex items-center justify-center w-screen h-screen">
          <Modal.Body className="flex items-center justify-center bg-white w-[400px] rounded-[24px]">
            <div className="px-[24px] mb-10 w-[400px] rounded-[24px] m-auto">
              <div className="flex items-center justify-between py-[22px]">
                <p className="text-[18px] leading-[150%] text-[#262626] font-semibold"></p>
                <div onClick={closeModal} className="cursor-pointer">
                  <AiOutlineClose className="text-[#2C7D92] text-xl" />
                </div>
              </div>
              <div className="flex items-center justify-center">
                <FiAlertCircle className="text-[#F15A5B] text-8xl" />
              </div>
              <div className="text-[24px] leading-[120%] font-bold text-[#262626] text-center mt-[30px]">
                Delete Confirmation
              </div>
              <div className="text-[14px] leading-[21px] text-[#262626] pt-[24px] text-center px-6 mb-2">
                Are you sure want to delete the quest? This action can’t be
                undone.
              </div>
              <div className="mt-[24px] grid justify-center">
                <button
                  className="flex items-center justify-center text-[#FFFFFF] text-[16px] leading-[24px] border-[#C2C2C2] bg-[#F15A5B] font-bold py-[13px] w-[220px] rounded-[50px]"
                  onClick={deleteClick}
                >
                  <FaTrash className="mr-2" /> Delete Quest
                </button>
                <button
                  className="flex items-center justify-center text-questeon text-[16px] leading-[24px] font-bold py-[13px] w-[220px] rounded-[50px] mt-3"
                  onClick={closeModal}
                >
                  Cancel
                </button>
              </div>
              <div className="bg-[#EDEDED] rounded-[12px] text-[12px] leading-[16px] text-[#5A5A60] p-[16px] text-center mt-[24px]">
                Are you sure want to delete the quest? This action can’t be
                undone.
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  )
}

export default DeleteModal
