import React, { useState } from 'react'
import { v4 as uuid } from 'uuid'
import { AiOutlineEye } from 'react-icons/ai'
import { Link, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useListPlacesQuery } from 'services/questApi'
import place from 'assets/images/questinfo/places.svg'
import task from 'assets/images/questinfo/task.svg'
import task2 from 'assets/images/questinfo/task2.svg'
import radius from 'assets/images/questinfo/radius.svg'
import HTMLView from 'components/HTMLView'
import { TaskType } from 'utils/questUtils'
import EmptyPlaces from 'pages/quest/QuestDetail/EmptyStates/EmptyPlaces'
// import { CircularProgressbarWithChildren } from 'react-circular-progressbar'
// import { RiCheckLine } from 'react-icons/ri'

const Places = () => {
  const { id } = useParams()
  const { quest } = useSelector((state) => state.quest)
  const { data: places } = useListPlacesQuery(id)
  const emptyPlaceList = places?.length === 0
  const [subplaceOpen] = useState(true)

  const TaskView = ({ task, parentType = 'places', parentId }) => {
    return (
      <div>
        <Link
          to={`/quests/${quest?.slug}/${parentType}/${parentId}/tasks/${task.id}`}
          className="flex items-center justify-between px-[16px] py-[12px] text-white hover:text-[#2c7d92] hover:bg-[#2c7d92]/10 hover:rounded-[16px]"
        >
          <div className="w-full text-[#262626] hover:text-[#2c7d92]">
            <p className="text-[14px] leading-[150%] font-semibold ">
              {task.title}
            </p>
            <p className="text-[12px] leading-[150%] text-[#7D7D7D]">
              <TaskType type={task.task_type} />
            </p>
          </div>
          <AiOutlineEye className="text-[24px]" />
          {/* <div className="greenn w-[40px]">
            <CircularProgressbarWithChildren strokeWidth={12} value={66}>
              <div>
                <RiCheckLine className="text-xl text-questeon" />
              </div>
            </CircularProgressbarWithChildren>
          </div> */}
        </Link>
      </div>
    )
  }

  const ListTasks = ({ tasks, tasksCount, parentId }) => {
    return (
      <div className="grid items-start bg-white w-full" key={uuid()}>
        <div className="mb-2">
          <span className="flex items-center text-[14px] leading-[150%] font-semibold text-[#7D7D7D]">
            <img src={task} alt="" className="pr-2" />
            {tasksCount} Task
          </span>
        </div>
        {tasks.map((task) => (
          <TaskView task={task} key={uuid()} parentId={parentId} />
        ))}
      </div>
    )
  }

  const SubplaceView = ({ subplace }) => {
    return (
      <div className="border-[1px] p-[20px] rounded-[20px] mt-[20px]">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <div className="mr-[20px]">
              <img
                src={subplace.image_url}
                alt=""
                className="w-[80px] h-[80px] rounded-[10px] object-cover object-center"
              />
            </div>
            <div className="text-[12px] leading-[150%] text-[#7D7D7D] font-light">
              <p className="text-[18px] leading-[150%] font-semibold text-[#262626]">
                {subplace.title}
              </p>
              <HTMLView content={subplace.description} />
            </div>
          </div>
          {/* <div
            onClick={() => setSubplaceOpen(!subplaceOpen)}
            className="cursor-pointer"
          >
            {subplaceOpen ? (
              <img src={chevronup} alt="" className="" />
            ) : (
              <img src={chevrondown} alt="" className="" />
            )}
          </div> */}
        </div>
        {subplaceOpen && (
          <div id={`subplace-${subplace.id}`} className="">
            <div className="mt-[20px]">
              <div className="flex items-center tems-center text-[14px] leading-[150%] font-semibold text-[#7D7D7D] mb-2">
                <img src={task} alt="" className="pr-2" />
                <p>{subplace.tasks_count} Task</p>
              </div>
              {subplace.tasks.map((task) => (
                <TaskView
                  task={task}
                  key={uuid()}
                  parentId={subplace.id}
                  parentType={'subplaces'}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    )
  }

  const ListSubplaces = ({ subplaceCount, description, subplaces }) => {
    return (
      <div className="text-[14px] leading-[150%] text-[#7D7D7D]">
        <div className="">
          <HTMLView content={description} />
          <span className="flex items-center my-2 text-[14px] leading-[150%] font-semibold">
            <img src={place} alt="" className="pr-2" />
            {subplaceCount} Subplace
          </span>
        </div>
        {subplaces.map((subplace) => (
          <SubplaceView subplace={subplace} key={uuid()} />
        ))}
      </div>
    )
  }

  return (
    <>
      {emptyPlaceList && <EmptyPlaces />}
      {!emptyPlaceList && quest && (
        <div className="">
          <div className="flex items-center justify-between">
            <div className="text-[18px] leading-[150%] text-[#262626] font-semibold">
              <p>📌 Places & Tasks</p>
            </div>
            <div className="flex items-center">
              <ul className="flex items-center text-[14px] leading-[150%] font-semibold text-[#7D7D7D]">
                <li>
                  <div className="flex items-center mr-4">
                    <img src={place} alt="" className="pr-2" />
                    {quest.places_count} Places
                  </div>
                </li>
                <li>
                  <div className="flex items-center">
                    <img src={task} alt="" className="pr-2" />
                    {quest.tasks_count} Tasks
                  </div>
                </li>
              </ul>
            </div>
          </div>

          {places?.map((place) => (
            <div
              className="flex items-center justify-between bg-white border-[1px] rounded-2xl my-4 h-[450px] overflow-auto"
              key={place.id}
            >
              <div className="grid justify-center bg-[#F7F8FA] rounded-l-xl w-[400px] py-[24px] px-[24px] h-full">
                <div className="">
                  <div className="grid justify-start">
                    <img
                      src={place.image_url}
                      alt=""
                      className="w-[400px] h-[200px] rounded-[16px] object-cover object-center"
                    />
                  </div>
                  <div
                    // className={
                    //     place.title.length > 50
                    //         ? 'h-28 text-ellipsis mb-2 overflow-hidden'
                    //         : ''
                    // }

                    className="my-[16px]"
                  >
                    <p className="text-ellipsis overflow-hidden text-[18px] leading-[150%] font-semibold text-[#262626]">
                      {place.title}
                    </p>
                  </div>
                  <div className="flex items-center font-medium text-sm pb-2">
                    <img src={radius} alt="" />
                    &nbsp;{place.radius}&nbsp;km(Radius)
                  </div>
                  <div className="flex items-center text-sm font-medium">
                    <img src={task2} alt="" />
                    &nbsp;{place.tasks_count} Tasks
                  </div>
                </div>
              </div>
              <div className="w-full h-full py-[24px] px-[24px] overflow-auto">
                {place.have_subplaces ? (
                  <ListSubplaces
                    subplaces={place.subplaces}
                    subplaceCount={place.subplaces_count}
                    description={place.description}
                  />
                ) : (
                  <ListTasks
                    tasks={place.tasks}
                    tasksCount={place.tasks_count}
                    parentId={place.id}
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  )
}

export default Places
