/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react'
import {
  FaTrash,
  FaPlus,
  FaCheck,
  FaMapMarkerAlt,
  FaSearch,
} from 'react-icons/fa'
import { IoIosArrowDown, IoIosClose } from 'react-icons/io'
import { AiOutlineCalendar } from 'react-icons/ai'
import { useForm, useFieldArray, Controller } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import {
  resetNavigation,
  setNavigationQuestInfo,
  setQuest,
} from 'features/questSlice'
import {
  useCreateQuestMutation,
  useUpdateQuestMutation,
  useListCategoryQuery,
  useGetCitiesQuery,
} from 'services/questApi'
import { serializeData } from 'utils/serializeData'
import { useNavigate, useLocation } from 'react-router-dom'
import ImageInput from 'components/inputs/ImageInput'
import RichTextInput from 'components/inputs/RichTextInput'
import BadgeInput from 'components/inputs/BadgeInput'
import ErrorNotice from 'components/ErrorNotice'
import CategoryFormModal from './modalForm/CategoryFormModal'
import MobileInfo from './mobilePreview/MobileInfo'
import time from 'assets/images/time.svg'
import destination from 'assets/images/destination.svg'
import group from 'assets/images/group.svg'
import { format } from 'date-fns'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

const QuestForm = () => {
  const dispatch = useDispatch()
  const { quest } = useSelector((state) => state.quest)
  const { data } = useListCategoryQuery()
  const [checkmarkPlace, setCheckmarkPlace] = useState(false)
  const [checkmarkTime, setCheckmarkTime] = useState(false)
  const [isGroupPlay, setIsGroupPlay] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [categoryOpen, setCategoryOpen] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isSave, setIsSave] = useState(false)
  const [selectedOption, setSelectedOption] = useState('')
  const [duration, setDuration] = useState('')
  const [durationHour, setDurationHour] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState([])
  const [categoryData, setCategoryData] = useState([])
  const [selectedCity, setSelectedCity] = useState('')
  const [cityOpen, setCityOpen] = useState(false)
  const [cityClicked, setCityClicked] = useState(false)
  const [inputTextCity, setInputTextCity] = useState('')
  const [inputText, setInputText] = useState('')
  const [openStartDate, setOpenStartDate] = useState(false)
  const [openEndDate, setOpenEndDate] = useState(false)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [isSearchable, setIsSearchable] = useState(true)
  const routePath = useLocation()
  const currentNavigation = routePath.pathname.split('/').slice(-1).toString()
  const { data: cities } = useGetCitiesQuery({ query: inputTextCity })

  const handleCalendarClickStart = (e) => {
    e.preventDefault()
    setStartDate(new Date())
    setOpenStartDate(!openStartDate)
  }

  const handleCalendarClickEnd = (e) => {
    e.preventDefault()
    setEndDate(new Date())
    setOpenEndDate(!openEndDate)
  }

  const inputHandler = (e) => {
    var lowerCase = e.target.value.toLowerCase()
    setInputText(lowerCase)
  }

  const filteredData = categoryData.filter((el) => {
    if (inputText === '') {
      return el
    } else {
      return el.name.toLowerCase().includes(inputText)
    }
  })

  const inputHandlerCity = (e) => {
    setInputTextCity(e.target.value)
  }

  const categoryToPost = selectedCategory?.map((item) => ({
    category_id: `${item.id}`,
    _destroy: item._destroy,
  }))

  const categoryIdSelected = selectedCategory?.map((item) => `${item.id}`)

  const options = ['child', 'teen', 'adult', 'null']
  const optionAge = {
    child: 'Child (0-12 years)',
    teen: 'Teen (13+ years)',
    adult: 'Adult (16+ years)',
    null: 'All ages',
  }

  const toggling = () => {
    setIsOpen(!isOpen)
  }

  const togglingCity = () => {
    setCityOpen(!cityOpen)
  }

  const onOptionClicked = (value) => () => {
    setSelectedOption(value)
  }

  const onCityClicked = (city) => () => {
    setValue('quest_city_attributes', {
      city_id: city.id,
    })
    setSelectedCity(city.full_name)
    setCityOpen(!cityOpen)
  }

  const removeValueAtIndex = (indexToRemove) => {
    const valueRemove = selectedCategory.filter((value) => {
      return value.id !== indexToRemove.id
    })

    setSelectedCategory(valueRemove)
  }

  const emptyHighlight = {
    value: '',
  }

  const emptyQuest = {
    title: '',
    description: '',
    image: '',
    badge_id: '',
    age: '',
    duration: '',
    route: '',
    price: '',
    highlights: [emptyHighlight],
    quest_categories_attributes: [],
    place_sequential: false,
    time_tracker: false,
    group_play: false,
    searchable: true,
    start_date: null,
    end_date: null,
  }

  const [createQuest] = useCreateQuestMutation()
  const [updateQuest] = useUpdateQuestMutation()
  const navigate = useNavigate()

  const {
    register,
    control,
    handleSubmit,
    setError,
    setValue,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: emptyQuest,
  })

  const { fields, append, remove } = useFieldArray({
    name: 'highlights',
    control,
  })

  useEffect(() => {
    if (!quest) {
      setValue('quest_categories_attributes', categoryToPost)
    }
  }, [quest])

  useEffect(() => {
    if (data) {
      setCategoryData(data)
    }
  }, [data])

  useEffect(() => {
    if (quest) {
      setValue('quest_categories_attributes', null)
    }
    if (currentNavigation === 'new') {
      reset(emptyQuest)
    }
  }, [])

  useEffect(() => {
    dispatch(resetNavigation())
    dispatch(setNavigationQuestInfo(true))
  }, [dispatch])

  useEffect(() => {
    if (isSave) {
      setTimeout(() => {
        if (Object.keys(errors).length > 0) {
          setIsError(true)
          setTimeout(() => {
            setIsError(false)
            setIsSave(false)
            clearErrors(errors)
          }, 2000)
        }
      }, 600)
    }
  }, [isSave, errors])

  useEffect(() => {
    if (quest && currentNavigation !== 'new') {
      const highlights = quest?.highlights?.map((highlight) => ({
        value: highlight,
      }))

      const questValue = {
        title: quest?.title,
        description: quest?.description,
        image: quest?.image_url,
        badge_id: quest?.badge?.id,
        route: quest?.route,
        age: quest?.age,
        step: quest?.step,
        duration: quest?.duration,
        group_play: quest?.group_play,
        price: quest?.price,
        highlights: highlights,
        place_sequential: quest?.place_sequential,
        time_tracker: quest?.time_tracker,
        start_date: quest?.start_date,
        end_date: quest?.end_date,
      }

      const getCategory = quest?.quest_categories?.map((item) => ({
        id: item.category_id,
        category_id: item.id,
        name: `${item.name}`,
        _destroy: false,
      }))

      setSelectedCategory(getCategory)
      setCheckmarkPlace(questValue.place_sequential)
      setCheckmarkTime(questValue.time_tracker)
      setSelectedOption(quest.age)
      setIsGroupPlay(quest.group_play)
      setIsSearchable(quest.searchable)
      setSelectedCity(quest?.quest_city?.full_name)

      if (questValue?.duration !== null) {
        const durationValue = questValue?.duration.split(' ')
        setDuration(durationValue[0])
        if (durationValue[1] === 'Hour') {
          setDurationHour(true)
        }
      }
      if (quest.status == 'ready') {
        setValue('status', 'draft')
      }

      if (quest.start_date !== null) {
        setStartDate(new Date(quest.start_date))
      }

      if (quest.end_date !== null) {
        setEndDate(new Date(quest.end_date))
      }

      reset(questValue)
    }
  }, [quest, reset])

  useEffect(() => {
    if (selectedOption === 'null') {
      setValue(`age`, ' ')
    } else {
      setValue(`age`, selectedOption)
      clearErrors('age')
    }
  }, [selectedOption, setValue])

  const setDurationValue = `${duration} ${durationHour ? 'Hour' : 'Day'}`

  useEffect(() => {
    if (duration) {
      clearErrors('duration')
      setValue(`duration`, setDurationValue)
    }
  }, [duration, setDurationValue])

  const handleQuestCreate = async (data) => {
    try {
      const questData = await createQuest(data).unwrap()

      if (questData) {
        dispatch(setQuest(questData))
        navigate(`/quests/${questData?.slug}/edit/intro`)
      }
    } catch ({ data: createQuestError }) {
      handleError(createQuestError)
    }
  }

  const handleQuestUpdate = async (id, data) => {
    try {
      const questData = await updateQuest({
        id: id,
        quest: data,
      }).unwrap()

      if (questData.description === '<p></p>\r\n') {
        setError('description', {
          type: 'description',
          message: 'Description is required',
        })
      }

      if (questData.description !== '<p></p>\r\n') {
        dispatch(setQuest(questData))
        navigate(`/quests/${questData?.slug}/edit/intro`)
      }
    } catch ({ data: updateQuestError }) {
      handleError(updateQuestError)
    }
  }

  const handleError = (errors) => {
    errors?.data?.forEach(({ name, message }) => {
      setError(name, { type: 'server', message })
    })
  }

  const onSubmit = async (data) => {
    const questDataForm = serializeData({ quest: data })
    quest
      ? handleQuestUpdate(quest.id, questDataForm)
      : handleQuestCreate(questDataForm)
  }

  return (
    <>
      {isError && (
        <ErrorNotice content={<>Please check fields marked in red</>} />
      )}
      {categoryOpen && (
        <CategoryFormModal
          categoryOpen={categoryOpen}
          inputHandler={inputHandler}
          quest={quest}
          filteredData={filteredData}
          categoryIdSelected={categoryIdSelected}
          setValue={setValue}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          setCategoryOpen={setCategoryOpen}
        />
      )}
      <div className="flex justify-between pr-[40px]">
        <div className="w-9/12 mr-4 relative">
          <div className="flex items-center text-[24px] leading-[130%] text-[#262626] font-semibold">
            <span className="flex items-center justify-center text-center mr-2 bg-[#FFCF50] w-[30px] h-[30px] rounded-full text-[16px] leading-[24px] text-[#262626]">
              1
            </span>
            Quest Info
          </div>
          <div className="mt-[40px]">
            <form onSubmit={handleSubmit(onSubmit)}>
              <input type="hidden" {...register('status')} />
              <div className="flex mb-[32px]">
                <label className="text-[16px] leading-[24px] text-[#262626] font-medium w-[200px] 2xl:w-[300px]">
                  Quest Banner <sup className="text-[#F15A5B]">*</sup>
                </label>
                <div>
                  <Controller
                    name="image"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'Quest Banner is required' }}
                    render={({ field }) => (
                      <ImageInput
                        {...field}
                        onError={setError}
                        error={errors.image}
                        errorImage={errors?.image}
                        clearErrors={clearErrors}
                      />
                    )}
                  />
                  {errors?.image && (
                    <div className="text-[#F15A5B] text-[14px] leading-[150%]">
                      {errors?.image?.message}
                    </div>
                  )}
                </div>
              </div>
              <div className="flex items-center mb-[32px]">
                <label className="text-[16px] leading-[24px] text-[#262626] font-medium w-[200px] 2xl:w-[300px]">
                  Quest Title <sup className="text-[#F15A5B]">*</sup>
                </label>
                <div>
                  <div
                    className={
                      errors.title
                        ? 'border-[1px] w-[534px] 2xl:w-[800px] rounded-[24px] py-[12px] px-[24px] focus:outline-none focus:bg-[#F7F8FA] border-[#F15A5B] text-[#F15A5B]'
                        : 'border-[1px] w-[534px]  2xl:w-[800px] border-[#EDEDED] rounded-[24px] py-[12px] px-[24px] text-[#7D7D7D] focus:outline-none focus:bg-[#F7F8FA]'
                    }
                  >
                    <input
                      type="text"
                      placeholder="Quest title"
                      className="text-[16px] leading-[150%] text-[#262626] w-full"
                      {...register('title', {
                        required: 'Title is required',
                      })}
                    />
                  </div>
                  {errors?.title && (
                    <div className="text-[#F15A5B] text-[14px] leading-[150%]">
                      {errors?.title?.message}
                    </div>
                  )}
                </div>
              </div>
              <div className="flex mb-[32px]">
                <label className="text-[16px] leading-[24px] text-[#262626] font-medium w-[200px] 2xl:w-[300px]">
                  Quest Description <sup className="text-[#F15A5B]">*</sup>
                </label>
                <div className="w-[534px] 2xl:w-[800px]">
                  <Controller
                    name="description"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'Description is required' }}
                    render={({ field }) => (
                      <RichTextInput
                        {...field}
                        error={errors?.description}
                        placeholder="Add description about the quest"
                      />
                    )}
                  />
                  {errors?.description && (
                    <div className="text-[#F15A5B] text-[14px] leading-[150%]">
                      {errors?.description?.message}
                    </div>
                  )}
                </div>
              </div>
              <div className="flex items-center mb-[32px]">
                <label className="text-[16px] leading-[24px] text-[#262626] font-medium w-[200px] 2xl:w-[300px]">
                  Quest Tags <spa className="text-[#7D7D7D]">(Max. 5)</spa>
                </label>
                <label className="flex flex-wrap items-center w-[534px] 2xl:w-[800px]">
                  {
                    <ul className="flex flex-wrap items-center">
                      {selectedCategory?.map((item) => (
                        <li
                          key={item.id}
                          className="flex items-center justify-between whitespace-nowrap text-[14px] leading-[150%] text-[#5A5A60] bg-[#2c7d92]/10 mr-2 pl-[20px] py-[9px] rounded-[50px] mb-2"
                        >
                          {item.name}
                          <label>
                            <IoIosClose
                              onClick={() => {
                                removeValueAtIndex(item)
                                setValue(
                                  `quest_categories_attributes[${item.id}]`,
                                  {
                                    id: item.category_id,
                                    _destroy: true,
                                  }
                                )
                                setValue(
                                  `quest_categories_attributes[${item.id}].category_id`,
                                  null
                                )
                              }}
                              className="text-2xl ml-2 mr-[10px] text-[#2C7D92] cursor-pointer"
                            />
                          </label>
                        </li>
                      ))}
                    </ul>
                  }
                  <div>
                    <div
                      onClick={() => setCategoryOpen(true)}
                      className="text-[#2C7D92] cursor-pointer"
                    >
                      <a className="flex items-center text-[16px] leading-[24px] font-medium hover:text-[#2C7D92]">
                        <FaPlus />
                        &nbsp; Add tags
                      </a>
                    </div>
                  </div>
                </label>
              </div>
              <div className="flex items-center mb-[32px]">
                <label className="text-[16px] leading-[24px] text-[#262626] font-medium w-[200px] 2xl:w-[300px]">
                  Badge Reward <sup className="text-[#F15A5B]">*</sup>
                </label>
                <div className="w-[534px] 2xl:w-[800px]">
                  <Controller
                    name="badge_id"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'Badge is required' }}
                    render={({ field }) => (
                      <BadgeInput {...field} errorBadge={errors?.badge_id} />
                    )}
                  />
                  {errors?.badge_id && (
                    <div className="text-[#F15A5B] text-[14px] leading-[150%]">
                      {errors?.badge_id?.message}
                    </div>
                  )}
                </div>
              </div>
              <div className="flex items-center mb-[32px]">
                <label className="text-[16px] leading-[24px] text-[#262626] font-medium w-[200px] 2xl:w-[300px]">
                  Estimate Time <sup className="text-[#F15A5B]">*</sup>
                </label>
                <label>
                  <label className="w-[534px] flex items-center">
                    <input
                      type="hidden"
                      {...register('duration', {
                        required: 'Estimate Time is required',
                      })}
                    />
                    <div
                      className={
                        errors?.duration
                          ? 'flex items-center justify-center border-[1px] border-[#F15A5B] w-[100px] px-[24px] py-[12px] rounded-[24px] mr-6'
                          : 'flex items-center justify-center border-[1px] w-[100px] px-[24px] py-[12px] rounded-[24px] mr-6'
                      }
                    >
                      <input
                        type="number"
                        placeholder="0"
                        className="w-full text-center"
                        value={duration}
                        onChange={(e) => setDuration(e.target.value)}
                      />
                    </div>
                    <div className="flex items-center justify-between w-[160px] h-[50px] bg-[#F7F8FA] rounded-[24px] text-[14px] leading-[21px] text-[#7D7D7D]">
                      <span
                        onClick={() => setDurationHour(true)}
                        className={
                          durationHour
                            ? 'flex items-center cursor-pointer bg-[#2C7D92]/10 pl-[26px] w-[80px] h-full rounded-l-[24px] border-[1px] border-[#2C7D92] text-[#2C7D92]'
                            : 'flex items-center cursor-pointer pl-[26px] w-[80px] h-full rounded-r-[24px]'
                        }
                      >
                        Hour
                      </span>
                      <span
                        onClick={() => setDurationHour(false)}
                        className={
                          durationHour
                            ? 'flex items-center cursor-pointer pl-[26px] w-[80px] h-full rounded-r-[24px]'
                            : 'flex items-center cursor-pointer bg-[#2C7D92]/10 pl-[26px] w-[80px] h-full rounded-r-[24px] border-[1px] border-[#2C7D92] text-[#2C7D92]'
                        }
                      >
                        Day
                      </span>
                    </div>
                  </label>
                  {errors?.duration && (
                    <div className="text-[#F15A5B] text-[14px] leading-[150%]">
                      {errors?.duration?.message}
                    </div>
                  )}
                </label>
              </div>

              <div className="flex items-center mb-[32px]">
                <label className="text-[16px] leading-[24px] text-[#262626] font-medium w-[200px] 2xl:w-[300px]">
                  Route <sup className="text-[#F15A5B]">*</sup>
                </label>
                <div className="w-[534px]">
                  <div
                    className={
                      errors.route
                        ? 'flex items-center border-[1px] w-[120px] rounded-[24px] py-[12px] px-[24px] focus:outline-none focus:bg-[#F7F8FA] border-[#F15A5B] text-[#F15A5B]'
                        : 'flex items-center border-[1px] w-[120px] border-[#EDEDED] rounded-[24px] py-[12px] px-[24px] text-[#7D7D7D] focus:outline-none focus:bg-[#F7F8FA]'
                    }
                  >
                    <input
                      type="number"
                      placeholder="0"
                      className="w-full"
                      {...register('route', {
                        required: 'Route is required',
                      })}
                    />
                    <span className="text-[14px] leading-[150%] text-[#262626] font-medium">
                      Km
                    </span>
                  </div>
                  {errors?.route && (
                    <div className="text-[#F15A5B] text-[14px] leading-[150%]">
                      {errors?.route?.message}
                    </div>
                  )}
                </div>
              </div>
              <div className="mb-[32px]">
                <div className="flex items-center">
                  <label className="text-[16px] leading-[24px] text-[#262626] font-medium w-[200px] 2xl:w-[300px]">
                    Region Quest <sup className="text-[#F15A5B]">*</sup>
                  </label>
                  <div>
                    <div
                      onClick={togglingCity}
                      className={
                        errors.age
                          ? 'flex items-center justify-between cursor-pointer border-[1px] w-[276px] 2xl:w-[800px] border-[#F15A5B] text-[16px] rounded-[24px] text-[#262626] py-[12px] px-[24px]  focus:outline-none focus:bg-[#F7F8FA]'
                          : 'flex items-center justify-between cursor-pointer border-[1px] w-[276px] 2xl:w-[800px] border-[#EDEDED] text-[16px] rounded-[24px] text-[#262626] py-[12px] px-[24px]  focus:outline-none focus:bg-[#F7F8FA]'
                      }
                    >
                      {selectedCity || 'Select City'}{' '}
                      <IoIosArrowDown className="text-xl text-[#7D7D7D]" />
                    </div>
                    {cityOpen && (
                      <div className="bg-white absolute z-20 cursor-pointer h-[350px] overflow-y-auto">
                        <ul className="text-[16px] leading-[21px] text-[#262626] border-[1px] w-[276px] rounded-[12px]">
                          <div className="flex items-center justify-center w-full mt-2">
                            <div className="flex items-center w-[250px] rounded-[24px] py-[10px] px-[12px] border-[1px] border-[#EDEDED]">
                              <FaSearch className="text-md mr-2 text-[#7D7D7D]" />
                              <input
                                type="text"
                                className="text-[14px] leading-[21px]"
                                placeholder="Search Country"
                                onChange={inputHandlerCity}
                              />
                            </div>
                          </div>
                          {cities?.map((option, index) => (
                            <div key={index}>
                              <div
                                onClick={() => setCityClicked(!cityClicked)}
                                className="px-[18px] py-[10px] border-b-[1px]"
                              >
                                <div
                                  className="flex items-center justify-start cursor-pointer"
                                  onClick={onCityClicked(option)}
                                >
                                  <FaMapMarkerAlt className="text-sm text-[#FFCF50] mr-2" />{' '}
                                  {option.full_name}
                                </div>
                              </div>
                            </div>
                          ))}
                        </ul>
                        {/* <ul className="text-[16px] leading-[21px] text-[#262626] border-[1px] w-[276px] rounded-[12px]">
                            {Array.from('questeonsss')?.map((option, index) => (
                              <div
                                key={index}
                                className="animate-pulse space-x-5 px-2"
                              >
                                <li className="h-4 w-full rounded-sm bg-gray-100 my-2"></li>
                              </div>
                            ))}
                          </ul> */}
                      </div>
                    )}
                  </div>
                </div>
                {errors?.age && (
                  <div className="ml-[200px] 2xl:ml-[300px] text-[#F15A5B] text-[14px] leading-[150%]">
                    {errors?.age?.message}
                  </div>
                )}
              </div>
              <div className="mb-[32px]">
                <div className="flex items-center">
                  <label className="text-[16px] leading-[24px] text-[#262626] font-medium w-[200px] 2xl:w-[300px]">
                    Suggested Age <sup className="text-[#F15A5B]">*</sup>
                  </label>
                  <label onClick={toggling}>
                    {selectedOption === null ? (
                      <input type="hidden" {...register('age')} />
                    ) : (
                      <input
                        type="hidden"
                        {...register('age', {
                          required: 'Suggested age is required',
                        })}
                      />
                    )}
                    <div
                      className={
                        errors.age
                          ? 'flex items-center justify-between cursor-pointer border-[1px] w-[276px] 2xl:w-[800px] border-[#F15A5B] text-[16px] rounded-[24px] text-[#262626] py-[12px] px-[24px]  focus:outline-none focus:bg-[#F7F8FA]'
                          : 'flex items-center justify-between cursor-pointer border-[1px] w-[276px] 2xl:w-[800px] border-[#EDEDED] text-[16px] rounded-[24px] text-[#262626] py-[12px] px-[24px]  focus:outline-none focus:bg-[#F7F8FA]'
                      }
                    >
                      {optionAge[selectedOption] || 'Select Age'}{' '}
                      <IoIosArrowDown className="text-xl text-[#7D7D7D]" />
                    </div>
                    {isOpen && (
                      <div className="bg-white absolute z-20 cursor-pointer">
                        <ul className="text-[16px] leading-[21px] text-[#262626] border-[1px] w-[276px] rounded-[12px]">
                          {options.map((option, index) => (
                            <div key={index}>
                              <li
                                onClick={onOptionClicked(option)}
                                className="px-[18px] py-[10px] border-b-[1px]"
                              >
                                {optionAge[option]}
                              </li>
                            </div>
                          ))}
                        </ul>
                      </div>
                    )}
                  </label>
                </div>
                {errors?.age && (
                  <div className="ml-[200px] 2xl:ml-[300px] text-[#F15A5B] text-[14px] leading-[150%]">
                    {errors?.age?.message}
                  </div>
                )}
              </div>
              <div className="flex items-center mb-[32px]">
                <label className="text-[16px] leading-[24px] text-[#262626] font-medium w-[200px] 2xl:w-[300px]">
                  Price<sup className="text-[#F15A5B]">*</sup>
                </label>
                <div>
                  <div className="w-[534px] flex items-center">
                    <div
                      className={
                        errors.price
                          ? 'flex items-center border-[1px] w-[120px] rounded-[24px] py-[12px] px-[24px] focus:outline-none focus:bg-[#F7F8FA] border-[#F15A5B] text-[#F15A5B]'
                          : 'flex items-center border-[1px] w-[120px] border-[#EDEDED] rounded-[24px] py-[12px] px-[24px] text-[#7D7D7D] focus:outline-none focus:bg-[#F7F8FA]'
                      }
                    >
                      <input
                        type="number"
                        className="w-full"
                        placeholder="0"
                        {...register('price', {
                          required: 'Price is required',
                        })}
                      />
                      <span className="text-[14px] leading-[150%] text-[#262626] font-medium">
                        SGD
                      </span>
                    </div>
                    <div className="ml-2 text-[14px] leading-[150%] text-[#7D7D7D]">
                      0 SGD mean the quest will become{' '}
                      <b className="text-[#262626]">FREE</b>
                    </div>
                  </div>
                  {errors?.price && (
                    <div className="text-[#F15A5B] text-[14px] leading-[150%]">
                      {errors?.price?.message}
                    </div>
                  )}
                </div>
              </div>

              <div className="flex items-center mb-[32px]">
                <label className="text-[16px] leading-[24px] text-[#262626] font-medium w-[200px] 2xl:w-[300px]">
                  Start date
                </label>
                <div className="w-[534px]">
                  <div className="flex items-center justify-between border-[1px] w-[240px] border-[#EDEDED] rounded-[24px] py-[12px] px-[24px] text-[#7D7D7D] focus:outline-none focus:bg-[#F7F8FA]">
                    <div>
                      {startDate ? (
                        format(startDate, 'dd-MM-yyyy h:mm aa')
                      ) : (
                        <>Anytime</>
                      )}
                    </div>
                    <div
                      onClick={handleCalendarClickStart}
                      className="cursor-pointer"
                    >
                      <AiOutlineCalendar className="text-questeon text-xl" />
                    </div>
                    {openStartDate && (
                      <div className="absolute p-[10px] bg-white border-[1px] rounded-lg drop-shadow-xl">
                        <DatePicker
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          minDate={new Date()}
                          showTimeSelect
                          timeFormat="p"
                          timeIntervals={15}
                          dateFormat="Pp"
                          showDisabledMonthNavigation
                          inline
                        />
                        <div className="flex items-center justify-between">
                          <div
                            onClick={() => {
                              setOpenStartDate(!openStartDate)
                              setValue('start_date', 'null')
                              setStartDate(null)
                            }}
                            className="flex items-center justify-center text-[12px] leading-[16px] bg-questeon text-white cursor-pointer px-[24px] py-[5px] rounded-full"
                          >
                            Cancel
                          </div>
                          <div
                            onClick={() => {
                              setOpenStartDate(!openStartDate)
                              setValue('start_date', startDate)
                            }}
                            className="flex items-center justify-center text-[12px] leading-[16px] bg-questeon text-white cursor-pointer px-[24px] py-[5px] rounded-full"
                          >
                            Select
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="flex items-center mb-[32px]">
                <label className="text-[16px] leading-[24px] text-[#262626] font-medium w-[200px] 2xl:w-[300px]">
                  End date
                </label>
                <div className="w-[534px]">
                  <div className="flex items-center justify-between border-[1px] w-[240px] border-[#EDEDED] rounded-[24px] py-[12px] px-[24px] text-[#7D7D7D] focus:outline-none focus:bg-[#F7F8FA]">
                    <div>
                      {endDate ? (
                        format(endDate, 'dd-MM-yyyy h:mm aa')
                      ) : (
                        <>Anytime</>
                      )}
                    </div>
                    <div
                      onClick={handleCalendarClickEnd}
                      className="cursor-pointer"
                    >
                      <AiOutlineCalendar className="text-questeon text-xl" />
                    </div>
                    {openEndDate && (
                      <div className="absolute p-[10px] bg-white border-[1px] rounded-lg drop-shadow-xl">
                        <DatePicker
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                          minDate={new Date()}
                          showTimeSelect
                          timeFormat="p"
                          timeIntervals={15}
                          dateFormat="Pp"
                          showDisabledMonthNavigation
                          inline
                        />
                        <div className="flex items-center justify-between">
                          <div
                            onClick={() => {
                              setOpenEndDate(!openEndDate)
                              setValue('end_date', 'null')
                              setEndDate(null)
                            }}
                            className="flex items-center justify-center text-[12px] leading-[16px] bg-questeon text-white cursor-pointer px-[24px] py-[5px] rounded-full"
                          >
                            Cancel
                          </div>
                          <div
                            onClick={() => {
                              setOpenEndDate(!openEndDate)
                              setValue('end_date', endDate)
                            }}
                            className="flex items-center justify-center text-[12px] leading-[16px] bg-questeon text-white cursor-pointer px-[24px] py-[5px] rounded-full"
                          >
                            Select
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="flex items-center mb-[32px]">
                <label className="text-[16px] leading-[24px] text-[#262626] font-medium w-[200px] 2xl:w-[300px]">
                  Searchable <sup className="text-[#F15A5B]">*</sup>
                </label>
                <div className="w-[534px]">
                  <div className="flex items-center justify-between w-[160px] h-[50px] bg-[#F7F8FA] rounded-[24px] text-[14px] leading-[21px] text-[#7D7D7D]">
                    <span
                      onClick={() => {
                        setIsSearchable(true)
                        setValue('searchable', true)
                      }}
                      className={
                        isSearchable
                          ? 'flex items-center cursor-pointer bg-[#2C7D92]/10 pl-[26px] w-[80px] h-full rounded-l-[24px] border-[1px] border-[#2C7D92] text-[#2C7D92]'
                          : 'flex items-center cursor-pointer pl-[26px] w-[80px] h-full rounded-r-[24px]'
                      }
                    >
                      Yes
                    </span>
                    <span
                      onClick={() => {
                        setIsSearchable(false)
                        setValue('searchable', false)
                      }}
                      className={
                        isSearchable
                          ? 'flex items-center cursor-pointer pl-[26px] w-[80px] h-full rounded-r-[24px]'
                          : 'flex items-center cursor-pointer bg-[#2C7D92]/10 pl-[26px] w-[80px] h-full rounded-r-[24px] border-[1px] border-[#2C7D92] text-[#2C7D92]'
                      }
                    >
                      No
                    </span>
                  </div>
                </div>
              </div>

              <div className="flex mb-[32px]">
                <label className="text-[16px] leading-[24px] text-[#262626] font-medium w-[200px] 2xl:w-[300px] mt-4">
                  Highlights<sup className="text-[#F15A5B]">*</sup>
                </label>
                <div className="w-[534px] 2xl:w-[800px]">
                  {fields.map((item, index) => (
                    <div key={item.id}>
                      <div
                        className={
                          errors?.['highlights']?.[index]
                            ? 'flex items-center justify-between border-[1px] w-[534px] 2xl:w-[800px] rounded-[24px] py-[12px] px-[24px] focus:outline-none focus:bg-[#F7F8FA] border-[#F15A5B] text-[#F15A5B] mb-2'
                            : 'flex items-center justify-between border-[1px] w-[534px] 2xl:w-[800px] border-[#EDEDED] rounded-[24px] py-[12px] px-[24px] text-[#7D7D7D] focus:outline-none focus:bg-[#F7F8FA] mb-2'
                        }
                      >
                        <input
                          type="text"
                          defaultValue={`${item.value}`}
                          className="text-[16px] leading-[150%] text-[#262626] w-full"
                          placeholder="Type highlight"
                          {...register(`highlights[${index}].value`, {
                            required: 'Highlight is required',
                          })}
                        />
                        {index > 0 && (
                          <a
                            href="#0"
                            onClick={() => {
                              remove(index)
                            }}
                            style={{ color: '#F15A5B' }}
                          >
                            <FaTrash />
                          </a>
                        )}
                      </div>
                      {errors?.['highlights']?.[index] && (
                        <div className="text-[#F15A5B] text-[14px] leading-[150%] mb-2">
                          {errors?.['highlights']?.[index]?.value?.message}
                        </div>
                      )}
                    </div>
                  ))}

                  <div className="text-[#2C7D92]">
                    <a
                      href="#0"
                      onClick={() => {
                        append({ value: '' })
                      }}
                      className="flex items-center text-[16px] leading-[24px] font-medium hover:text-[#2C7D92]"
                    >
                      <FaPlus />
                      &nbsp; Add more highlight
                    </a>
                  </div>
                </div>
              </div>
              <div className="flex mb-[32px]">
                <label className="text-[16px] leading-[24px] text-[#262626] font-medium w-[200px] 2xl:w-[300px]">
                  Features
                </label>
                <div className="w-[534px] grid gap-5 grid-cols-2">
                  <label
                    className={
                      checkmarkPlace
                        ? 'p-[24px] rounded-[24px] border-2 border-questeon w-[264px] h-[210px] mr-[24px] cursor-pointer bg-[#2c7d92]/10'
                        : 'p-[24px] rounded-[24px] border-[1px] w-[264px] h-[210px] mr-[24px] cursor-pointer'
                    }
                  >
                    <label>
                      <div className="flex items-center justify-between text-[16px] cursor-pointer leading-[24px] text-[#262626] font-medium h-[55px]">
                        <div>
                          <img src={destination} alt="" />
                        </div>
                        <input
                          type="checkbox"
                          {...register('place_sequential')}
                          onClick={() => {
                            setCheckmarkPlace(!checkmarkPlace)
                          }}
                        />
                        {checkmarkPlace ? (
                          <span className="flex items-center justify-center w-[20px] h-[20px] bg-[#2C7D92] rounded-full cursor-pointer">
                            <FaCheck className="w-[10px] h-[10px] text-white" />
                          </span>
                        ) : (
                          <span className="w-[20px] h-[20px] border-[1px] bg-[#EDEDED] rounded-full cursor-pointer"></span>
                        )}
                      </div>
                      <p className="w-[200px] font-bold mt-[16px]">
                        Place Sequential
                      </p>
                    </label>
                    <div className="mt-[8px]">
                      <p className="text-[14px] leading-[21px] text-[#5A5A60]">
                        Players need to follow the order of places that have
                        been set.
                      </p>
                    </div>
                  </label>

                  <label
                    className={
                      checkmarkTime
                        ? 'p-[24px] rounded-[24px] border-2 border-questeon w-[264px] h-[210px] mr-[24px] cursor-pointer bg-[#2c7d92]/10'
                        : 'p-[24px] rounded-[24px] border-[1px] w-[264px] h-[210px] mr-[24px] cursor-pointer'
                    }
                  >
                    <div>
                      <label className="flex items-center cursor-pointer justify-between text-[16px] leading-[24px] text-[#262626] font-medium h-[55px]">
                        <div>
                          <img src={time} alt="" />
                        </div>
                        <input
                          type="checkbox"
                          className="cursor-pointer"
                          {...register('time_tracker')}
                          onClick={() => {
                            setCheckmarkTime(!checkmarkTime)
                          }}
                        />
                        {checkmarkTime ? (
                          <span className="flex items-center justify-center w-[20px] h-[20px] bg-[#2C7D92] rounded-full cursor-pointer">
                            <FaCheck className="w-[10px] h-[10px] text-white" />
                          </span>
                        ) : (
                          <span className="w-[20px] h-[20px] border-[1px] bg-[#EDEDED] rounded-full cursor-pointer"></span>
                        )}
                      </label>
                      <p className="w-[200px] font-bold mt-[16px]">
                        Time Tracker
                      </p>
                    </div>
                    <div className="mt-[8px] ">
                      <p className="text-[14px] leading-[21px] text-[#5A5A60]">
                        Track how long it takes the player to complete the
                        quest.
                      </p>
                    </div>
                  </label>

                  <label
                    className={
                      isGroupPlay
                        ? 'p-[24px] rounded-[24px] border-2 border-questeon w-[264px] h-[210px] mr-[24px] cursor-pointer bg-[#2c7d92]/10'
                        : 'p-[24px] rounded-[24px] border-[1px] w-[264px] h-[210px] mr-[24px] cursor-pointer'
                    }
                  >
                    <div>
                      <label className="flex items-center justify-between text-[16px] leading-[24px] text-[#262626] font-medium">
                        <div>
                          <img src={group} alt="" />
                        </div>
                        <input
                          type="checkbox"
                          {...register('group_play')}
                          onClick={() => {
                            setIsGroupPlay(!isGroupPlay)
                          }}
                        />
                        {isGroupPlay ? (
                          <span className="flex items-center justify-center w-[20px] h-[20px] bg-[#2C7D92] rounded-full cursor-pointer">
                            <FaCheck className="w-[10px] h-[10px] text-white" />
                          </span>
                        ) : (
                          <span className="w-[20px] h-[20px] border-[1px] bg-[#EDEDED] rounded-full cursor-pointer"></span>
                        )}
                      </label>
                      <p className="w-[200px] font-bold mt-[16px]">
                        Group Play
                      </p>
                    </div>
                    <div className="mt-[8px]">
                      <p className="text-[14px] leading-[21px] text-[#5A5A60]">
                        Let the player play and solves the quest together in
                        team.
                      </p>
                    </div>
                  </label>
                </div>
              </div>
              <div className="flex items-center justify-end mb-[38px]">
                <button
                  onClick={() => setIsSave(true)}
                  type="submit"
                  className="bg-[#2C7D92] rounded-[50px] text-[16px] leading-[24px] text-white font-semibold w-[160px] py-[13px]"
                >
                  Next
                </button>
              </div>
            </form>
          </div>
        </div>
        <MobileInfo
          control={control}
          selectedCategory={selectedCategory}
          duration={duration}
          durationHour={durationHour}
          optionAge={optionAge}
          selectedOption={selectedOption}
        />
      </div>
    </>
  )
}

export default QuestForm
