import Logo from 'components/Logo'
import React from 'react'
import { Link } from 'react-router-dom'

const ResetSuccess = () => {
  return (
    <div className="singin-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="singin-box hig">
              <div className="verify-box">
                <Logo />
                <div className="verify-containt">
                  <div className="verify-img">
                    <img src={require('assets/images/check-ff.png')} alt="" />
                  </div>
                  <div className="verify-text">
                    <h3>Password changed</h3>
                    <p>
                      You can now use your new password to sign in to your
                      account 😉
                    </p>
                  </div>
                  <div className="singin-btn">
                    <Link to="/signin">
                      <button type="submit">Sign In</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResetSuccess
