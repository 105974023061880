import React from 'react'
import emptyPlaceListIcon from 'assets/images/icon-empty-place-list.svg'
import { Link } from 'react-router-dom'
import { FaPlus } from 'react-icons/fa'
import { useSelector } from 'react-redux'

const EmptyPlaces = () => {
  const { quest } = useSelector((state) => state.quest)

  let navigateUrl = `/quests/${quest?.slug}/edit/place`
  if (quest?.step === 'quest_info') {
    navigateUrl = `/quests/${quest?.slug}/edit/intro`
  }

  return (
    <div className="flex items-center justify-center h-[400px]">
      <div>
        <div className="flex items-center justify-center">
          <img src={emptyPlaceListIcon} alt="" />
        </div>
        <div className="flex items-center justify-center">
          <div>
            <p className="text-center text-[18px] leading-[150%] text-[#5A5A60] font-semibold mt-2">
              No places has been added
            </p>
            <p className="text-center text-[14px] leading-[150%] text-[#5A5A60] font-extralight">
              Add places for user to play
            </p>
          </div>
        </div>
        <div className="mt-6 flex items-center justify-center">
          <Link
            to={navigateUrl}
            className="bg-[#2C7D92] flex items-center justify-center rounded-[50px] text-[16px] leading-[24px] text-white font-semibold w-[160px] py-[13px]"
          >
            <FaPlus />
            &nbsp; Add Place
          </Link>
        </div>
      </div>
    </div>
  )
}

export default EmptyPlaces
