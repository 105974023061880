import React, { useState, useRef, useEffect } from 'react'
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io'

const CoverCarousel = ({ task }) => {
  const maxScrollWidth = useRef(0)
  const [currentIndex, setCurrentIndex] = useState(0)
  const carousel = useRef(null)
  const { covers } = task

  const movePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevState) => prevState - 1)
    }
  }

  const moveNext = () => {
    if (
      carousel.current !== null &&
      carousel.current.offsetWidth * currentIndex <= maxScrollWidth.current
    ) {
      setCurrentIndex((prevState) => prevState + 1)
    }
  }

  const isDisabled = (direction) => {
    if (direction === 'prev') {
      return currentIndex <= 0
    }

    if (direction === 'next' && carousel.current !== null) {
      return (
        carousel.current.offsetWidth * currentIndex >= maxScrollWidth.current
      )
    }

    return false
  }

  useEffect(() => {
    if (carousel !== null && carousel.current !== null) {
      carousel.current.scrollLeft = carousel.current.offsetWidth * currentIndex
    }
  }, [currentIndex])

  useEffect(() => {
    maxScrollWidth.current = carousel.current
      ? carousel.current.scrollWidth - carousel.current.offsetWidth
      : 0
  }, [])

  return (
    <div className="h-[400px] w-[694px] mb-11">
      <div className="relative overflow-hidden">
        <div className="flex justify-between items-center absolute top left w-full h-full">
          <button
            onClick={movePrev}
            disabled={isDisabled('prev')}
            className="text-white h-fit text-center focus:outline-none active:outline-none opacity-75 hover:opacity-100 disabled:opacity-25 disabled:cursor-not-allowed z-10 p-0 m-0 transition-all ease-in-out duration-300"
          >
            <div className="border-2 flex items-center justify-center rounded-full w-[32px] h-[32px] ml-2">
              <IoIosArrowBack className="text-lg" />
            </div>
          </button>
          <button
            onClick={moveNext}
            className="text-white h-fit text-center focus:outline-none active:outline-none opacity-75 hover:opacity-100 disabled:opacity-25 disabled:cursor-not-allowed z-10 p-0 m-0 transition-all ease-in-out duration-300"
            disabled={isDisabled('next')}
          >
            <div className="border-2 flex items-center justify-center rounded-full w-[32px] h-[32px] mr-2">
              <IoIosArrowForward className="text-lg" />
            </div>
          </button>
        </div>

        <div
          ref={carousel}
          className="relative flex overflow-hidden scroll-smooth snap-x snap-mandatory touch-pan-x z-0"
        >
          {covers.map((resource, index) => {
            return (
              <div key={index} className="h-full w-full">
                <div className="h-[400px] w-[694px] rounded-[16px]">
                  <img
                    src={resource.file_url}
                    alt={resource.id}
                    className="h-[400px] w-[694px] object-cover object-center rounded-[16px]"
                  />
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default CoverCarousel
