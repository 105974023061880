import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import DeleteIcon from 'assets/images/icon/Close-Square.png'
import { FaPlus } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { setQuest } from 'features/questSlice'
import { useForm, useFieldArray, Controller } from 'react-hook-form'
import {
  useUpdateQuestMutation,
  useUpdateQuestStepMutation,
} from 'services/questApi'
import { serializeData } from 'utils/serializeData'
import ImageInput from 'components/inputs/ImageInput'
import RichTextInput from 'components/inputs/RichTextInput'
import SaveNotice from 'components/SaveNotice'
import ErrorNotice from 'components/ErrorNotice'
import MobileIntro from './mobilePreview/MobileIntro'

const IntroForm = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { quest, currentNavigation } = useSelector((state) => state.quest)
  const [updateQuest] = useUpdateQuestMutation()
  const [updateStep] = useUpdateQuestStepMutation()
  const [deletedIntros, setDeletedIntros] = useState([])
  const [saveNotice, setSaveNotice] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isSave, setIsSave] = useState(false)

  const emptyIntrosAttributes = {
    title: '',
    description: '',
  }

  const {
    register,
    control,
    handleSubmit,
    setError,
    reset,
    setValue,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm()

  const { fields, append, remove } = useFieldArray({
    name: 'quest_intros_attributes',
    control,
  })

  const onSubmit = async (data) => {
    const dataWithDeletedIntros = {
      ...data,
      quest_intros_attributes: [
        ...data.quest_intros_attributes,
        ...deletedIntros,
      ],
    }

    const questData = serializeData({ quest: dataWithDeletedIntros })
    if (quest) {
      try {
        const id = quest.id
        const questResponse = await updateQuest({
          id,
          quest: questData,
        }).unwrap()
        dispatch(setQuest(questResponse))
        if (currentNavigation === 'quest_info') {
          await updateStep({ id: id, step: 'intro' })
        }

        if (questResponse.intro === '<p></p>\r\n') {
          setError('intro', {
            type: 'intro',
            message: 'Intro is required',
          })
        }
        if (questResponse.intro !== '<p></p>\r\n') {
          navigate(`/quests/${quest?.slug}/edit/place`)
        }
      } catch ({ data: updateQuestError }) {
        updateQuestError?.data?.forEach(({ name, message }) => {
          setError(name, { type: 'server', message })
        })
      }
    }
  }

  const onRemoveIntroClick = (index) => {
    const intro = getValues(`quest_intros_attributes[${index}]`)
    if (intro && intro.id) {
      setDeletedIntros([...deletedIntros, { id: intro.id, _destroy: true }])
    }
    remove(index)
  }

  useEffect(() => {
    if (isSave) {
      setTimeout(() => {
        if (Object.keys(errors).length > 0) {
          setIsError(true)
          setTimeout(() => {
            setIsError(false)
            setIsSave(false)
            clearErrors(errors)
          }, 2000)
        }
      }, 600)
    }
  }, [isSave, errors])

  useEffect(() => {
    setSaveNotice(true)
    setTimeout(() => {
      setSaveNotice(false)
    }, 1000)
  }, [])

  useEffect(() => {
    if (quest) {
      const questIntros = quest.quest_intros

      const questIntrosAttributes =
        questIntros.length !== 0
          ? questIntros?.map((intro) => ({
              id: intro.id,
              title: intro.title,
              description: intro.description,
            }))
          : [
              {
                title: '',
                description: '',
              },
            ]

      const introValue = {
        title: quest.title,
        intro: quest.intro,
        intro_image: quest.intro_image_url,
        quest_intros_attributes: questIntrosAttributes,
      }
      if (quest.status == 'ready') {
        setValue('status', 'draft')
      }
      reset(introValue)
    }
  }, [reset, quest])

  return (
    <>
      {isError && (
        <ErrorNotice content={<>Please check fields marked in red</>} />
      )}
      {saveNotice && <SaveNotice />}
      <div className="flex justify-between pr-[40px]">
        <div className="w-9/12 mr-4 relative">
          <div className="flex items-center text-[24px] leading-[130%] text-[#262626] font-semibold">
            <span className="flex items-center justify-center text-center mr-2 bg-[#FFCF50] w-[30px] h-[30px] rounded-full text-[16px] leading-[24px] text-[#262626]">
              2
            </span>
            Intro
          </div>
          <div className="mt-[40px]">
            <form onSubmit={handleSubmit(onSubmit)}>
              <input type="hidden" {...register('status')} />
              <div className="flex mb-[32px]">
                <label className="text-[16px] leading-[24px] text-[#262626] font-medium w-[200px] 2xl:w-[300px]">
                  Intro Banner <sup className="text-[#F15A5B]">*</sup>
                </label>
                <div>
                  <Controller
                    name="intro_image"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'Intro Banner is required' }}
                    render={({ field }) => (
                      <ImageInput
                        {...field}
                        onError={setError}
                        error={errors.intro_image}
                        errorImage={errors?.intro_image}
                        clearErrors={clearErrors}
                      />
                    )}
                  />
                  {errors?.intro_image && (
                    <div className="text-[#F15A5B] text-[14px] leading-[150%]">
                      {errors?.intro_image?.message}
                    </div>
                  )}
                </div>
              </div>
              <div className="flex mb-[32px]">
                <label className="text-[16px] leading-[24px] text-[#262626] font-medium w-[200px] 2xl:w-[300px]">
                  Intro Description <sup className="text-[#F15A5B]">*</sup>
                </label>
                <label>
                  <div className="w-[534px] 2xl:w-[800px]">
                    <Controller
                      name="intro"
                      control={control}
                      rules={{ required: 'Description is required' }}
                      render={({ field }) => (
                        <RichTextInput
                          {...field}
                          error={errors?.intro}
                          placeholder="Add intro description"
                        />
                      )}
                    />
                  </div>
                  {errors?.intro && (
                    <div className="text-[#F15A5B] text-[14px] leading-[150%]">
                      {errors?.intro?.message}
                    </div>
                  )}
                </label>
              </div>
              <div className="flex mb-[32px]">
                <label className="text-[16px] leading-[24px] text-[#262626] font-medium w-[200px] 2xl:w-[300px]">
                  Intro Notes
                </label>
                <div className="w-[534px] 2xl:w-[800px]">
                  {fields.map((item, index) => (
                    <div
                      className="p-[24px] rounded-[24px] bg-[#F7F8FA] mb-[30px]"
                      key={item.id}
                    >
                      <div className="flex justify-between">
                        <h3 className="text-[16px] leading-[24px]">
                          Notes #{index + 1}
                        </h3>
                        <div className="">
                          {index !== 0 && (
                            <a
                              href="#0"
                              onClick={() => {
                                onRemoveIntroClick(index)
                              }}
                            >
                              <img src={DeleteIcon} alt="" />
                            </a>
                          )}
                        </div>
                      </div>
                      <div className="mb-[16px] border-[1px] border-[#EDEDED] rounded-[24px]">
                        {Number.isInteger(item.id) && (
                          <input
                            type="hidden"
                            value={item.id}
                            {...register(
                              `quest_intros_attributes[${index}].id`
                            )}
                          />
                        )}
                        <input
                          type="text"
                          className="w-full rounded-[24px] px-[24px] py-[14px]"
                          placeholder="Block title, e.g. : Tips, Facts, etc."
                          defaultValue={item.title}
                          {...register(
                            `quest_intros_attributes[${index}].title`
                          )}
                        />
                      </div>
                      <div>
                        <Controller
                          name={`quest_intros_attributes[${index}].description`}
                          control={control}
                          defaultValue={item.description}
                          render={({ field }) => (
                            <RichTextInput
                              {...field}
                              placeholder="Block description"
                            />
                          )}
                        />
                        {errors?.['quest_intros_attributes']?.[index]?.[
                          'description'
                        ] && (
                          <div className="invalid-feedback">
                            {
                              errors?.['quest_intros_attributes']?.[index]?.[
                                'description'
                              ].message
                            }
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                  <div className="text-[#2C7D92] mb-[30px]">
                    <a
                      href="#0"
                      onClick={() => {
                        append(emptyIntrosAttributes)
                      }}
                      className="flex items-center text-[16px] leading-[24px] font-medium hover:text-[#2C7D92]"
                    >
                      <FaPlus />
                      &nbsp; Add more notes
                    </a>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-between">
                <Link
                  to={`/quests/${quest?.slug}/edit`}
                  className="flex items-center justify-center bg-white rounded-[50px] text-[16px] leading-[24px] text-[#2C7D92] hover:text-[#2C7D92] border-[1px] border-[#2C7D92] font-semibold w-[160px] py-[13px]"
                >
                  Previous
                </Link>
                <button
                  onClick={() => setIsSave(true)}
                  type="submit"
                  className="bg-[#2C7D92] rounded-[50px] text-[16px] leading-[24px] text-white font-semibold w-[160px] py-[13px]"
                >
                  Next
                </button>
              </div>
            </form>
          </div>
        </div>
        <MobileIntro control={control} />
      </div>
    </>
  )
}

export default IntroForm
