/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import emptyUsersIcon from 'assets/images/icon-empty-users.svg'

const EmptyUsers = () => {
  return (
    <div className="flex items-center justify-center h-[400px]">
      <div>
        <div className="flex items-center justify-center">
          <img src={emptyUsersIcon} alt="" />
        </div>
        <div className="flex items-center justify-center">
          <div>
            <p className="text-center text-[18px] leading-[150%] text-[#5A5A60] font-semibold mt-2">
              No user found
            </p>
            <p className="text-center text-[14px] leading-[150%] text-[#5A5A60] font-extralight">
              People haven't played this quest yet
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EmptyUsers
