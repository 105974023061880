import React, { useEffect, useRef, useState } from 'react'
import Swal from 'sweetalert2'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { setHeader, setHeaderTitle } from 'features/navigationSlice'
import { useNavigate } from 'react-router-dom'
import { useChangePasswordMutation } from 'services/creatorApi'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { useSignOutMutation } from 'services/authApi'

const ChangePassword = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [signOut] = useSignOutMutation()
  const [changePassword] = useChangePasswordMutation()
  const [showCurrentPassword, setShowCurrentPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false)
  const confirmButtonColor = '#2C7D92'

  const handleSignOut = async () => {
    await signOut()
  }

  useEffect(() => {
    dispatch(setHeader('SETTING'))
    dispatch(setHeaderTitle('Change Password'))
  }, [dispatch])

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm()

  // validation for new pass and retype new pass
  const password = useRef({})
  password.current = watch('password', '')

  const handleShowCurrentPassword = () => {
    setShowCurrentPassword(!showCurrentPassword)
  }

  const handleShowNewPassword = () => {
    setShowNewPassword(!showNewPassword)
  }

  const handleShowConfirmNewPassword = () => {
    setShowConfirmNewPassword(!showConfirmNewPassword)
  }

  const onSubmit = async (data) => {
    try {
      const response = await changePassword({ creator: data }).unwrap()
      if (response) {
        reset()
        Swal.fire({
          title: 'Success!',
          text: 'Success',
          icon: 'success',
          confirmButtonText: 'Ok',
          confirmButtonColor,
        }).then((result) => {
          if (result) {
            handleSignOut()
          }
        })
      }
    } catch ({ data }) {
      Swal.fire({
        title: 'Error!',
        text: data.errors,
        icon: 'error',
        confirmButtonText: 'Ok',
        confirmButtonColor,
      })
    }
  }

  return (
    <div className="px-[32px] py-[38px]">
      <div className="w-9/12">
        <div className="text-[24px] leading-[130%] text-[#262626] font-semibold pb-[24px]">
          <p>Settings</p>
        </div>
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <div className="flex items-center justify-between mb-[32px]">
                <label className="text-[16px] leading-[24px] text-[#262626] font-medium w-[200px]">
                  Current Password
                </label>
                <div className="w-[534px]">
                  <div
                    className={
                      errors.current_password
                        ? 'flex items-center justify-between border-2 rounded-[24px] py-[14px] px-[24px] focus:outline-none focus:bg-[#F7F8FA] border-[#F15A5B] text-[#F15A5B]'
                        : 'flex items-center justify-between border-2 border-[#EDEDED] rounded-[24px] py-[14px] px-[24px] text-[#7D7D7D] focus:outline-none focus:bg-[#F7F8FA]'
                    }
                  >
                    <input
                      type={showCurrentPassword ? 'text' : 'password'}
                      className="text-[14px] leading-[150%] text-[#262626] w-full"
                      placeholder="Type Current Password"
                      {...register('current_password', {
                        required: 'Current password is required',
                      })}
                    />

                    <FontAwesomeIcon
                      onClick={handleShowCurrentPassword}
                      icon={showCurrentPassword ? faEyeSlash : faEye}
                      cursor="pointer"
                      className="8"
                    />
                  </div>
                  {errors?.current_password && (
                    <div className="text-[#F15A5B] text-[14px] leading-[150%]">
                      {errors?.current_password?.message}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="flex items-center justify-between mb-[32px]">
              <label className="text-[16px] leading-[24px] text-[#262626] font-medium w-[200px]">
                New Password
              </label>
              <div className="w-[534px]">
                <div
                  className={
                    errors.password
                      ? 'flex items-center justify-between border-2 rounded-[24px] py-[14px] px-[24px] focus:outline-none focus:bg-[#F7F8FA] border-[#F15A5B] text-[#F15A5B]'
                      : 'flex items-center justify-between border-2 border-[#EDEDED] rounded-[24px] py-[14px] px-[24px] text-[#7D7D7D] focus:outline-none focus:bg-[#F7F8FA]'
                  }
                >
                  <input
                    type={showNewPassword ? 'text' : 'password'}
                    placeholder="Type New Password"
                    className="text-[14px] leading-[150%] text-[#262626] w-full"
                    {...register('password', {
                      required: 'You must specify a password',
                      minLength: {
                        value: 8,
                        message: 'Password must have at least 8 characters',
                      },
                    })}
                  />
                  <FontAwesomeIcon
                    onClick={handleShowNewPassword}
                    icon={showNewPassword ? faEyeSlash : faEye}
                    cursor="pointer"
                    className="-ml-8"
                  />
                </div>
                {errors?.password && (
                  <div className="text-[#F15A5B] text-[14px] leading-[150%]">
                    {errors?.password?.message}
                  </div>
                )}
              </div>
            </div>

            <div className="flex items-center justify-between mb-[32px]">
              <label className="text-[16px] leading-[24px] text-[#262626] font-medium w-[200px]">
                Confirm New Password
              </label>
              <div className="w-[534px]">
                <div
                  className={
                    errors.password_confirmation
                      ? 'flex items-center justify-between border-2 rounded-[24px] py-[14px] px-[24px] focus:outline-none focus:bg-[#F7F8FA] border-[#F15A5B] text-[#F15A5B]'
                      : 'flex items-center justify-between border-2 border-[#EDEDED] rounded-[24px] py-[14px] px-[24px] text-[#7D7D7D] focus:outline-none focus:bg-[#F7F8FA]'
                  }
                >
                  <input
                    name="confirmPassword"
                    placeholder="Re-Type New Password"
                    className="text-[14px] leading-[150%] text-[#262626] w-full"
                    type={showConfirmNewPassword ? 'text' : 'password'}
                    {...register('password_confirmation', {
                      validate: (value) =>
                        value === password.current ||
                        'The passwords do not match',
                    })}
                  />
                  <FontAwesomeIcon
                    onClick={handleShowConfirmNewPassword}
                    icon={showConfirmNewPassword ? faEyeSlash : faEye}
                    cursor="pointer"
                    className="-ml-8"
                  />
                </div>
                {errors?.password_confirmation && (
                  <div className="text-[#F15A5B] text-[14px] leading-[150%]">
                    {errors?.password_confirmation?.message}
                  </div>
                )}
              </div>
            </div>
            <div className="ml-[400px] flex items-center justify-between">
              <button
                className="flex items-center justify-center bg-white rounded-[50px] text-[16px] leading-[24px] text-[#F15A5B] border-[1px] border-[#F15A5B] font-semibold w-[160px] py-[13px]"
                onClick={() => {
                  navigate('/setting')
                }}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="bg-[#2C7D92] rounded-[50px] text-[16px] leading-[24px] text-white font-semibold w-[200px] py-[13px]"
              >
                Save Changes
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ChangePassword
