import React, { useState } from 'react'
import { FaSearch } from 'react-icons/fa'
import Lightbox from 'react-image-lightbox'
import { AiOutlineClose } from 'react-icons/ai'
import 'react-image-lightbox/style.css'
import Video from 'assets/images/icon_video.svg'
import Audio from 'assets/images/icon_audio.svg'

const TaskClue = ({ taskData }) => {
  const [isOpenImageClue, setIsOpenImageClue] = useState(false)
  const file = taskData.task_clue?.file_url
  const type = taskData.task_clue?.clue_type

  return (
    <>
      {taskData.task_clue && (
        <div className="mt-[24px]">
          <p className="text-[18px] leading-[150%] text-[#262626] font-semibold mb-[24px]">
            Clue Reward 📜
          </p>
          <div
            onClick={() => setIsOpenImageClue(!isOpenImageClue)}
            className="flex items-center justify-between cursor-pointer w-[694px] px-[24px] py-[16px] bg-[#eaf2f4] rounded-[24px] mb-[16px]"
          >
            <div className="flex items-center">
              {type === 'image' && (
                <div className="mr-3">
                  <img
                    src={file}
                    alt=""
                    onClick={() => setIsOpenImageClue(true)}
                    className="cursor-pointer w-[80px] h-[80px] rounded-[16px] object-cover object-center"
                  />
                  {isOpenImageClue && (
                    <Lightbox
                      mainSrc={file}
                      onCloseRequest={() => setIsOpenImageClue(false)}
                    />
                  )}
                </div>
              )}
              {type === 'audio' && (
                <div className="mr-3">
                  <div
                    onClick={() => setIsOpenImageClue(true)}
                    className="w-[80px] h-[80px] rounded-[16px] cursor-pointer flex items-center justify-center border-[1px] p-2 border-dashed border-[#2C7D92] object-cover object-center"
                  >
                    <div className="overflow-hidden">
                      <div className="flex items-center justify-center">
                        <img className="" src={Audio} alt="" />
                      </div>
                    </div>
                  </div>
                  {isOpenImageClue && (
                    <div className="z-30 fixed top-0 left-0 right-0 bottom-0 bg-[rgba(0,0,0,0.8)] overflow-auto">
                      <div
                        onClick={() => setIsOpenImageClue(false)}
                        className="flex z-40 items-center justify-end bg-[rgba(0,0,0,0.4)] text-white h-[50px]"
                      >
                        <AiOutlineClose className="text-2xl mr-3 cursor-pointer" />
                      </div>
                      <div className="flex items-center justify-center h-screen -mt-[50px]">
                        <audio style={{ width: '100%' }} controls>
                          <source src={file} />
                        </audio>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {type === 'video' && (
                <div className="mr-3">
                  <div
                    onClick={() => setIsOpenImageClue(true)}
                    className="w-[80px] h-[80px] rounded-[16px] cursor-pointer flex items-center justify-center border-[1px] p-2 border-dashed border-[#2C7D92] object-cover object-center "
                  >
                    <div className="overflow-hidden">
                      <div className="flex items-center justify-center">
                        <img className="" src={Video} alt="" />
                      </div>
                    </div>
                  </div>
                  {isOpenImageClue && (
                    <div className="z-30 fixed top-0 left-0 right-0 bottom-0 bg-[rgba(0,0,0,0.8)] overflow-auto">
                      <div
                        onClick={() => setIsOpenImageClue(false)}
                        className="flex z-40 items-center justify-end bg-[rgba(0,0,0,0.4)] text-white h-[50px]"
                      >
                        <AiOutlineClose className="text-2xl mr-3 cursor-pointer" />
                      </div>
                      <div className="flex items-center justify-center h-screen -mt-[50px]">
                        <video
                          src={file}
                          style={{ height: '80%', width: 'auto' }}
                          controls
                        ></video>
                      </div>
                    </div>
                  )}
                </div>
              )}
              <div className="text-[14px] leading-[150%] text-[#262626]">
                <p>{taskData.task_clue.title}</p>
              </div>
            </div>
            <div>
              <span>
                <FaSearch className="text-[#2C7D92] text-xl" />
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default TaskClue
