import React, { useRef, useState, useEffect } from 'react'
import { FaPlus, FaTrash } from 'react-icons/fa'
import edit from 'assets/images/questdetail/white_edit.svg'
import { dataURLtoFile } from 'utils/imageUrlToFile'
import FileIcon from 'assets/images/tasktype/image_icon.svg'
import ARFileIcon from 'assets/images/icon-ar.svg'
import ImageCroppingModal from 'components/quest/modalForm/ImageCroppingModal'

const ImageInput = React.forwardRef((props, ref) => {
  const fileRef = useRef()
  const [image, setImage] = useState(null)
  const [files, setFiles] = useState(null)
  const [isHovering, setIsHovering] = useState(false)
  const [resultImage, setResultImage] = useState([])
  const [modal, setModal] = useState(false)
  const [cropResult, setCropResult] = useState(false)
  const resultImageLength = resultImage.length

  const handleMouseOver = () => {
    setIsHovering(true)
  }

  const handleMouseOut = () => {
    setIsHovering(false)
  }

  const {
    onChange,
    onError,
    name,
    value,
    imageType,
    errorImage,
    coverType,
    coverRemove,
    imageLength,
    clearErrors,
  } = props

  const onBrowseClick = (e) => {
    setImage(null)
    onChange('')
    setFiles(null)
    setCropResult(false)
    setResultImage([])
    fileRef.current.click()
    e.preventDefault()
  }

  const cropRatio = (imageType) => {
    switch (imageType) {
      case 'intro':
        return 1
      case 'place':
        return 4 / 3
      case 'augmentedReality':
        return 1
      case 'task':
        return coverType === 'banner' ? 16 / 9 : 4 / 5
      case 'multiple_choice_image':
        return 1
      default:
        return 1
    }
  }

  useEffect(() => {
    if (value && value?.constructor === String && value !== 'SERVER_FILE') {
      setImage(value)
      // onChange('SERVER_FILE')
    }
    if (files && !value) {
      onChange(files)
    }
  }, [value, onChange, files])

  useEffect(() => {
    if (files !== null) {
      setModal(true)
      if (cropResult !== false) {
        setTimeout(() => {
          setImage(resultImage.toString())
          setModal(false)
        }, 200)
      }
    }
  }, [files, cropResult])

  if (resultImageLength) {
    onChange(files)
  }

  useEffect(() => {
    if (resultImageLength) {
      dataURLtoFile(resultImage.toString(), files[0].name, files[0].type).then(
        function (file) {
          setFiles(file)
        }
      )
    }
  }, [resultImageLength])

  const handleFile = (files) => {
    let imageError = false

    const validTypes = () => {
      switch (imageType) {
        case 'augmentedReality':
          return ['image/png']
        default:
          return ['image/jpeg', 'image/jpg', 'image/png']
      }
    }

    if (files) {
      const file = files[0]
      const imageUrl = URL.createObjectURL(file)
      const inputTypes = validTypes()

      if (onError) {
        if (file.size / 1024 > 5120) {
          imageError = true
          onError(name, { type: 'file_size', message: 'File size is too big' })
        }
        if (inputTypes.indexOf(file.type) === -1) {
          imageError = true
          onError(name, { type: 'file_type', message: 'Invalid file type' })
        }
      }
      if (!imageError) {
        setImage(imageUrl)
        setFiles(files)
        onChange(files)
        clearErrors(name)
      }
    }
  }

  const onImageRemove = () => {
    setImage(null)
    onChange('')
    setFiles(null)
    setCropResult(false)
    setResultImage([])
  }

  const onFileInputChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      handleFile(event.target.files)
    }
  }

  const onFileDrop = (event) => {
    event.preventDefault()
    const files = event.dataTransfer.files
    if (files?.length > 0) {
      handleFile(files)
    }
  }

  const dragOver = (e) => {
    e.preventDefault()
  }

  const dragEnter = (e) => {
    e.preventDefault()
  }

  const dragLeave = (e) => {
    e.preventDefault()
  }

  const inputBox = (imageType) => {
    switch (imageType) {
      case 'augmentedReality':
        return (
          <>
            {image ? (
              <div
                onMouseOver={handleMouseOver}
                onMouseLeave={handleMouseOut}
                className="relative flex items-center justify-center w-[300px] h-[300px] rounded-[24px]"
              >
                {isHovering && (
                  <div className="absolute bg-[rgba(0,0,0,0.5)] flex w-full h-full items-center justify-center rounded-[24px] cursor-pointer">
                    <div className="flex items-center justify-between w-[160px]">
                      <button
                        onClick={onBrowseClick}
                        className="flex items-center justify-center py-[12px] px-[16px] border-[1px] border-white rounded-[20px]"
                      >
                        <div className="flex items-center text-[16px] leading-[24px] font-semibold text-white">
                          <img
                            src={edit}
                            alt=""
                            className="w-[25px] h-[25px] mr-2"
                          />
                          Edit
                        </div>
                      </button>
                      <button
                        onClick={onImageRemove}
                        className="flex items-center justify-center w-[50px] h-[50px] bg-white rounded-full "
                      >
                        <div className="flex items-center">
                          <FaTrash className="text-[#F15A5B] text-xl" />
                        </div>
                      </button>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="w-[300px] h-[300px] rounded-[24px] px-[36px] object-cover object-center border-[1px] border-dashed border-[#2C7D92] flex items-center justify-start ">
                <div
                  onDragOver={dragOver}
                  onDragEnter={dragEnter}
                  onDragLeave={dragLeave}
                  onDrop={onFileDrop}
                  onClick={onBrowseClick}
                  className="cursor-pointer"
                >
                  <div className="flex items-center justify-center">
                    <img src={ARFileIcon} alt="" />
                  </div>
                  <div className="mt-[20px] grid items-center justify-center">
                    <p className="text-center text-[14px] leading-[150%] text-[#262626]">
                      Drop image here or{' '}
                      <a
                        href="#0"
                        className="text-[#2C7D92] font-semibold hover:text-[#2C7D92]"
                      >
                        browse
                      </a>
                    </p>
                    <span className="text-center text-[12px] leading-[150%] text-[#7D7D7D] mt-2">
                      Must be a PNG file with a transparent background up to 5mb
                    </span>
                  </div>
                </div>
              </div>
            )}
          </>
        )
      case 'multiple_choice_image':
        return (
          <div>
            {image ? (
              <div
                onMouseOver={handleMouseOver}
                onMouseLeave={handleMouseOut}
                className="relative flex items-center justify-center w-[180px] h-[180px] rounded-[24px]"
              >
                {isHovering && (
                  <div className="absolute bg-[rgba(0,0,0,0.5)] flex w-full h-full items-center justify-center rounded-[24px] cursor-pointer">
                    <div className="flex items-center justify-between w-[160px]">
                      <button
                        onClick={onBrowseClick}
                        className="flex items-center justify-center py-[12px] px-[16px] border-[1px] border-white rounded-[20px]"
                      >
                        <div className="flex items-center text-[16px] leading-[24px] font-semibold text-white">
                          <img
                            src={edit}
                            alt=""
                            className="w-[25px] h-[25px] mr-2"
                          />
                          Edit
                        </div>
                      </button>
                      <button
                        onClick={
                          imageLength !== 2 ? coverRemove : onImageRemove
                        }
                        className="flex items-center justify-center w-[50px] h-[50px] bg-white rounded-full "
                      >
                        <div className="flex items-center">
                          <FaTrash className="text-[#F15A5B] text-xl" />
                        </div>
                      </button>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="w-[180px] h-[180px] rounded-[24px] px-[36px] object-cover object-center border-[1px] border-dashed border-[#2C7D92] flex items-center justify-center">
                <div
                  onDragOver={dragOver}
                  onDragEnter={dragEnter}
                  onDragLeave={dragLeave}
                  onDrop={onFileDrop}
                  onClick={onBrowseClick}
                  className="cursor-pointer"
                >
                  <div>
                    <div className="flex items-center justify-center">
                      <div className="flex items-center justify-center w-[40px] h-[40px] bg-[#2C7D92] rounded-full">
                        <FaPlus className="text-white" />
                      </div>
                    </div>
                    <p className="text-[14px] leading-[150%] text-[#7D7D7D] mt-[17px]">
                      Add image
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        )
      case 'task':
        return (
          <>
            {coverType === 'banner' ? (
              <div>
                {image ? (
                  <div
                    onMouseOver={handleMouseOver}
                    onMouseLeave={handleMouseOut}
                    className="relative flex items-center justify-center w-[320px] h-[180px] rounded-[24px]"
                  >
                    {isHovering && (
                      <div className="absolute bg-[rgba(0,0,0,0.5)] flex w-full h-full items-center justify-center rounded-[24px] cursor-pointer">
                        <div className="flex items-center justify-between w-[170px]">
                          <button
                            onClick={onBrowseClick}
                            className="flex items-center justify-center py-[12px] px-[16px] border-[1px] border-white rounded-[20px]"
                          >
                            <div className="flex items-center text-[16px] leading-[24px] font-semibold text-white">
                              <img
                                src={edit}
                                alt=""
                                className="w-[25px] h-[25px] mr-2"
                              />
                              Edit
                            </div>
                          </button>
                          <button
                            onClick={
                              imageLength !== 1 ? coverRemove : onImageRemove
                            }
                            className="flex items-center justify-center w-[50px] h-[50px] bg-white rounded-full "
                          >
                            <div className="flex items-center">
                              <FaTrash className="text-[#F15A5B] text-xl" />
                            </div>
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="w-[320px] h-[180px] rounded-[24px] px-[24px] object-cover object-center border-[1px] border-dashed border-[#2C7D92] flex items-center justify-center">
                    <div
                      onDragOver={dragOver}
                      onDragEnter={dragEnter}
                      onDragLeave={dragLeave}
                      onDrop={onFileDrop}
                      onClick={onBrowseClick}
                      className="cursor-pointer"
                    >
                      <div>
                        <p className="text-[14px] leading-[150%] text-[#262626] text-center">
                          Upload Banner
                        </p>
                        <div className="flex items-center justify-center my-[17px]">
                          <div className="flex items-center justify-center w-[40px] h-[40px] bg-[#2C7D92] rounded-full">
                            <FaPlus className="text-white" />
                          </div>
                        </div>
                        <p className="text-[12px] leading-[150%] text-[#7D7D7D] text-center">
                          Support file up to 20mb
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div>
                {image ? (
                  <div
                    onMouseOver={handleMouseOver}
                    onMouseLeave={handleMouseOut}
                    className="relative flex items-center justify-center h-[320px] w-[256px] rounded-[24px]"
                  >
                    {isHovering && (
                      <div className="absolute bg-[rgba(0,0,0,0.5)] flex w-full h-full items-center justify-center rounded-[24px] cursor-pointer">
                        <div className="flex items-center justify-between w-[170px]">
                          <button
                            onClick={onBrowseClick}
                            className="flex items-center justify-center py-[12px] px-[16px] border-[1px] border-white rounded-[20px]"
                          >
                            <div className="flex items-center text-[16px] leading-[24px] font-semibold text-white">
                              <img
                                src={edit}
                                alt=""
                                className="w-[25px] h-[25px] mr-2"
                              />
                              Edit
                            </div>
                          </button>
                          <button
                            onClick={
                              imageLength !== 1 ? coverRemove : onImageRemove
                            }
                            className="flex items-center justify-center w-[50px] h-[50px] bg-white rounded-full "
                          >
                            <div className="flex items-center">
                              <FaTrash className="text-[#F15A5B] text-xl" />
                            </div>
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="h-[320px] w-[256px] rounded-[24px] px-[24px] object-cover object-center border-[1px] border-dashed border-[#2C7D92] flex items-center justify-center">
                    <div
                      onDragOver={dragOver}
                      onDragEnter={dragEnter}
                      onDragLeave={dragLeave}
                      onDrop={onFileDrop}
                      onClick={onBrowseClick}
                      className="cursor-pointer"
                    >
                      <div>
                        <p className="text-[14px] leading-[150%] text-[#262626] text-center">
                          Upload Full Image
                        </p>
                        <div className="flex items-center justify-center my-[17px]">
                          <div className="flex items-center justify-center w-[40px] h-[40px] bg-[#2C7D92] rounded-full">
                            <FaPlus className="text-white" />
                          </div>
                        </div>
                        <p className="text-[12px] leading-[150%] text-[#7D7D7D] text-center">
                          Support file up to 20mb
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </>
        )
      case 'place':
        return (
          <>
            {image ? (
              <div
                onMouseOver={handleMouseOver}
                onMouseLeave={handleMouseOut}
                className="relative flex items-center justify-center w-[320px] h-[240px] rounded-[24px]"
              >
                {isHovering && (
                  <div className="absolute bg-[rgba(0,0,0,0.5)] flex w-full h-full  items-center justify-center rounded-[24px] cursor-pointer">
                    <div className="flex items-center justify-between w-[170px]">
                      <button
                        onClick={onBrowseClick}
                        className="flex items-center justify-center py-[12px] px-[16px] border-[1px] border-white rounded-[24px]"
                      >
                        <div className="flex items-center text-[16px] leading-[24px] font-semibold text-white">
                          <img
                            src={edit}
                            alt=""
                            className="w-[25px] h-[25px] mr-2"
                          />
                          Edit
                        </div>
                      </button>
                      <button
                        onClick={onImageRemove}
                        className="flex items-center justify-center w-[50px] h-[50px] bg-white rounded-full "
                      >
                        <div className="flex items-center">
                          <FaTrash className="text-[#F15A5B] text-xl" />
                        </div>
                      </button>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="w-[320px] h-[240px] rounded-[24px] px-[36px] object-cover object-center border-[1px] border-dashed border-[#2C7D92] flex items-center justify-center">
                <div
                  onDragOver={dragOver}
                  onDragEnter={dragEnter}
                  onDragLeave={dragLeave}
                  onDrop={onFileDrop}
                  onClick={onBrowseClick}
                  className="cursor-pointer"
                >
                  <div className="flex items-center justify-center">
                    <img src={FileIcon} alt="" />
                  </div>
                  <div className="mt-[20px] grid items-center justify-center">
                    <p className="text-center text-[14px] leading-[150%] text-[#262626]">
                      Drop your image here or{' '}
                      <a
                        href="#0"
                        className="text-[#2C7D92] font-semibold hover:text-[#2C7D92]"
                      >
                        browse
                      </a>
                    </p>
                    <span className="text-center text-[12px] leading-[150%] text-[#7D7D7D] mt-2">
                      Support JPG, JPEG & PNG up to 5mb
                    </span>
                  </div>
                </div>
              </div>
            )}
          </>
        )
      default:
        return (
          <>
            {image ? (
              <div
                onMouseOver={handleMouseOver}
                onMouseLeave={handleMouseOut}
                className="relative flex items-center justify-center w-[300px] h-[300px] rounded-[24px]"
              >
                {isHovering && (
                  <div className="absolute bg-[rgba(0,0,0,0.5)] flex w-full h-full  items-center justify-center rounded-[24px] cursor-pointer">
                    <div className="flex items-center justify-between w-[170px]">
                      <button
                        onClick={onBrowseClick}
                        className="flex items-center justify-center py-[12px] px-[16px] border-[1px] border-white rounded-[24px]"
                      >
                        <div className="flex items-center text-[16px] leading-[24px] font-semibold text-white">
                          <img
                            src={edit}
                            alt=""
                            className="w-[25px] h-[25px] mr-2"
                          />
                          Edit
                        </div>
                      </button>
                      <button
                        onClick={onImageRemove}
                        className="flex items-center justify-center w-[50px] h-[50px] bg-white rounded-full "
                      >
                        <div className="flex items-center">
                          <FaTrash className="text-[#F15A5B] text-xl" />
                        </div>
                      </button>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="w-[300px] h-[300px] rounded-[24px] px-[36px] object-cover object-center border-[1px] border-dashed border-[#2C7D92] flex items-center justify-center">
                <div
                  onDragOver={dragOver}
                  onDragEnter={dragEnter}
                  onDragLeave={dragLeave}
                  onDrop={onFileDrop}
                  onClick={onBrowseClick}
                  className="cursor-pointer"
                >
                  <div className="flex items-center justify-center">
                    <img src={FileIcon} alt="" />
                  </div>
                  <div className="mt-[20px] grid items-center justify-center">
                    <p className="text-center text-[14px] leading-[150%] text-[#262626]">
                      Drop your image here or{' '}
                      <a
                        href="#0"
                        className="text-[#2C7D92] font-semibold hover:text-[#2C7D92]"
                      >
                        browse
                      </a>
                    </p>
                    <span className="text-center text-[12px] leading-[150%] text-[#7D7D7D] mt-2">
                      Support JPG, JPEG & PNG up to 5mb
                    </span>
                  </div>
                </div>
              </div>
            )}
          </>
        )
    }
  }

  return (
    <>
      <ImageCroppingModal
        imageValue={image}
        modalConditional={modal}
        resultImage={resultImage}
        modalClicked={() => setModal(false)}
        resultClicked={() => setCropResult(true)}
        cropingRatio={cropRatio(imageType)}
      />
      <div
        className={
          errorImage
            ? 'rounded-[24px] border-[1px] border-[#F15A5B] w-max'
            : 'rounded-[24px]'
        }
      >
        <div
          className="z-40 rounded-[24px] object-contain object-center flex items-center justify-center"
          style={{
            backgroundImage: `url(${image})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
          onDragOver={dragOver}
          onDragEnter={dragEnter}
          onDragLeave={dragLeave}
          onDrop={onFileDrop}
        >
          <input
            type="file"
            className="hidden"
            ref={(e) => {
              ref(e)
              fileRef.current = e
            }}
            onChange={onFileInputChange}
            accept="image/*"
          />
          {inputBox(imageType)}
        </div>
      </div>
    </>
  )
})

export default ImageInput
