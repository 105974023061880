/* eslint-disable no-empty */
import { baseApi } from './baseApi'
import {
    setAuthentication,
    removeAuthentication,
} from 'features/authenticationSlice'

export const authApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        signIn: builder.mutation({
            query: (data) => ({
                url: '/api/v1/creator/sessions',
                method: 'POST',
                body: data,
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    dispatch(baseApi.util.resetApiState())
                    const { data: signInData } = await queryFulfilled
                    dispatch(setAuthentication(signInData))
                } catch {}
            },
        }),
        signOut: builder.mutation({
            query: () => ({
                url: '/api/v1/creator/sessions',
                method: 'DELETE',
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                await queryFulfilled
                dispatch(removeAuthentication())
            },
        }),
        resetPassword: builder.mutation({
            query: (data) => ({
                url: `/api/v1/creator/password`,
                method: 'PUT',
                body: data,
            }),
        }),
        forgotPassword: builder.mutation({
            query: (email) => ({
                url: `/api/v1/creator/password`,
                method: 'POST',
                body: email,
            }),
        }),
        sendEmailConfirmation: builder.mutation({
            query: (email) => ({
                url: `/api/v1/creator/confirmation`,
                method: 'POST',
                body: email,
            }),
        }),
        confirmEmail: builder.query({
            query: (confirmation_token) => ({
                url: `/api/v1/creator/confirmation?confirmation_token=${confirmation_token}`,
            }),
        }),
    }),
    overrideExisting: false,
})

export const {
    useSignInMutation,
    useSignOutMutation,
    useConfirmEmailQuery,
    useForgotPasswordMutation,
    useResetPasswordMutation,
    useSendEmailConfirmationMutation,
} = authApi
