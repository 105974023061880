import React from 'react'
import classNames from 'classnames'
import { useForm } from 'react-hook-form'
import { useRegistrationMutation } from 'services/creatorApi'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { FaCheck } from 'react-icons/fa'
import QuesteonLogo from 'assets/images/questeon_logo.svg'

function SignUp() {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({ mode: 'onChange' })
  const navigate = useNavigate()
  const [registration] = useRegistrationMutation()
  const [showPassword, setShowPassword] = useState(false)
  const [signup, setSignup] = useState(false)
  const handleSignup = () => {
    setSignup(!signup)
  }
  const handleShowPassword = () => {
    setShowPassword(!showPassword)
  }
  const onSubmit = async (signUpData) => {
    try {
      const result = await registration({ creator: signUpData }).unwrap()
      if (result) {
        navigate('/verify-email', {
          state: { email: signUpData.email },
        })
      }
    } catch ({ data: singUpError }) {
      singUpError?.data?.forEach(({ name, message }) => {
        setError(name, { type: 'server', message })
      })
    }
  }

  return (
    <div className="bg-[#F7F8FA] flex justify-center items-center text-[#262626] h-screen">
      <div className="w-[440px] bg-white py-[60px] px-[50px] border-1 border-[#EDEDED] rounded-[40px]">
        <div>
          <img src={QuesteonLogo} alt="" className="h-[40px]" />
        </div>
        <div className="py-[45px]">
          <h3 className="text-[24px] leading-[120%] text-[#262626] font-semibold">
            ✏️ Become a Creator
          </h3>
          <p className="text-[14px] leading-[21px] text-[#5A5A60] pt-[8px]">
            Enter your details below to become a creator :
          </p>
        </div>
        <div className="">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="text-[14px] leading-[21px] text-[#262626]">
              <label>
                Name <sup className="text-[#F15A5B]">*</sup>
              </label>
              <input
                type="text"
                className={
                  errors.name
                    ? 'w-[340px] border-2 rounded-[24px] py-[14px] px-[24px] mt-[8px] mb-[24px] focus:outline-none focus:bg-[#F7F8FA] border-[#F15A5B] text-[#F15A5B]'
                    : 'w-[340px] border-2 border-[#EDEDED] rounded-[24px] py-[14px] px-[24px] text-[#7D7D7D] mt-[8px] mb-[24px] focus:outline-none focus:bg-[#F7F8FA]'
                }
                placeholder="Your name"
                {...register('name', {
                  required: 'Name is required.',
                })}
              />
              {errors.name && (
                <div className="text-[#F15A5B] -mt-[24px] mb-[24px]">
                  {errors.name.message}
                </div>
              )}
            </div>
            <div className="text-[14px] leading-[21px] text-[#262626]">
              <label>
                Email <sup className="text-[#F15A5B]">*</sup>
              </label>
              <input
                type="email"
                className={
                  errors.email
                    ? 'w-[340px] border-2 rounded-[24px] py-[14px] px-[24px] mt-[8px] mb-[24px] focus:outline-none focus:bg-[#F7F8FA] border-[#F15A5B] text-[#F15A5B]'
                    : 'w-[340px] border-2 border-[#EDEDED] rounded-[24px] py-[14px] px-[24px] text-[#7D7D7D] mt-[8px] mb-[24px] focus:outline-none focus:bg-[#F7F8FA] invalid:border-1 invalid:border-[#F15A5B] invalid:text-[#F15A5B]'
                }
                placeholder="Your email"
                {...register('email', {
                  required: 'Email is required.',
                })}
              />
              {errors.email && (
                <div className="text-[#F15A5B] -mt-[24px] mb-[24px]">
                  {errors.email.message}
                </div>
              )}
            </div>
            <div className="text-[14px] leading-[21px] text-[#262626]">
              <label>
                Password <sup className="text-[#F15A5B]">*</sup>
              </label>
              <div className="flex items-center justify-between">
                <input
                  type={showPassword ? 'text' : 'password'}
                  className={
                    errors.password
                      ? 'w-[340px] border-2 rounded-[24px] py-[14px] px-[24px] mt-[8px] mb-[24px] focus:outline-none focus:bg-[#F7F8FA] border-[#F15A5B] text-[#F15A5B]'
                      : 'w-[340px] border-2 border-[#EDEDED] rounded-[24px] py-[14px] px-[24px] text-[#7D7D7D] mt-[8px] mb-[24px] focus:outline-none focus:bg-[#F7F8FA]'
                  }
                  placeholder="Your password"
                  {...register('password', {
                    required: 'Password is required.',
                  })}
                />
                <div
                  className="grid w-[40px] items-center -ml-10 -mt-3"
                  onClick={handleShowPassword}
                >
                  <FontAwesomeIcon
                    icon={showPassword ? faEyeSlash : faEye}
                    className="w-[20px] cursor-pointer"
                  />
                </div>
              </div>
              {errors.password && (
                <div className="text-[#F15A5B] -mt-[24px] mb-[24px]">
                  {errors.password.message}
                </div>
              )}
            </div>
            <div className="flex items-center leading-[21px] text-[#262626] mb-[20px]">
              <div onClick={handleSignup}>
                <div
                  className={classNames(
                    'flex items-center justify-center border-1 border-[#2C7D92] w-[16px] h-[16px] bg-[#2C7D92] cursor-pointer rounded-sm',
                    {
                      '!border-[#EDEDED] !bg-[#EDEDED] rounded-sm': !signup,
                    }
                  )}
                >
                  {signup && (
                    <FaCheck className="text-white w-[10px] h-[10px]" />
                  )}
                </div>
              </div>
              <div className="pl-2 text-[12px] leading-[16px]">
                I agree to the{' '}
                <Link
                  to="/terms-and-conditions"
                  target="_blank"
                  className="text-[#2C7D92] hover:text-[#2C7D92]"
                >
                  terms & conditions
                </Link>
              </div>
            </div>
            <div className="singin-btn">
              <button
                className={classNames(
                  'flex items-center justify-center text-[#FFFFFF] text-[16px] leading-[24px] border-[#C2C2C2] bg-[#2C7D92] font-bold py-[13px] px-[145px] rounded-[50px]',
                  {
                    '!bg-[#C2C2C2]': !signup,
                  }
                )}
                disabled={!signup}
              >
                Sign&nbsp;Up
              </button>
            </div>
            <div className="flex items-center justify-center pt-[24px]">
              <p className="text-[14px] leading-[21px] text-[#5A5A60]">
                Are you an existing Creator?{' '}
                <Link
                  to="/signin"
                  className="text-[#2C7D92] font-semibold hover:text-[#2C7D92]"
                >
                  Sign in
                </Link>{' '}
                here
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default SignUp
