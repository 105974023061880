import { baseApi, withPagination } from './baseApi'

const apiWithTag = baseApi.enhanceEndpoints({
  addTagTypes: ['Places', 'Quest', 'Step', 'Place'],
})

export const questApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    lastUserJoined: builder.query({
      query: () => ({
        url: '/api/v1/creator/users',
      }),
      providesTags: ['LastUserJoined'],
    }),
    popularQuest: builder.query({
      query: () => ({
        url: '/api/v1/creator/quests/popular',
      }),
      providesTags: [{ type: 'Quest', id: 'popular' }],
    }),
    listQuests: builder.query({
      query: ({
        page = 1,
        search = '',
        sort = 'desc',
        field = 'created_at',
      }) => ({
        url: `/api/v1/creator/quests?page=${page}&query=${search}&sort=${sort}&field=${field}`,
        providesTags: [{ type: 'Quest', id: 'LIST' }],
      }),
      transformResponse: (response, meta) => {
        const pagination = withPagination(meta)

        return {
          quests: response,
          ...pagination,
        }
      },
    }),
    listBadges: builder.query({
      query: () => ({
        url: '/api/v1/creator/badges',
      }),
      providesTags: ['ListBadges'],
    }),
    listCategory: builder.query({
      query: () => ({
        url: '/api/v1/categories',
      }),
      providesTags: ['ListCategory'],
    }),

    createQuest: builder.mutation({
      query: (quest) => ({
        url: '/api/v1/creator/quests',
        method: 'POST',
        body: quest,
      }),
    }),
    updateQuest: builder.mutation({
      query: ({ id, quest }) => ({
        url: `/api/v1/creator/quests/${id}`,
        method: 'PUT',
        body: quest,
      }),
    }),
    getQuest: builder.query({
      query: (id) => ({
        url: `/api/v1/creator/quests/${id}`,
      }),
      providesTags: (result, _error, _arg) => [
        { type: 'Quest', id: result.id },
      ],
    }),
    deleteQuest: builder.mutation({
      query: (id) => ({
        url: `/api/v1/creator/quests/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result) => [{ type: 'Quest', id: result?.quest_id }],
    }),
    createConclusion: builder.mutation({
      query: (conclusion) => ({
        url: '/api/v1/creator/conclusions',
        method: 'POST',
        body: conclusion,
      }),
      // invalidatesTags: (result) => [
      //   console.log(result),
      //   { type: 'Places', id: result.id },
      //   { type: 'Quest', id: result.id },
      // ],
    }),
    updateConclusion: builder.mutation({
      query: ({ id, conclusion }) => ({
        url: `/api/v1/creator/conclusions/${id}`,
        method: 'PUT',
        body: conclusion,
      }),
      // invalidatesTags: (result) => [
      //   console.log(result),
      //   { type: 'Places', id: result.id },
      //   { type: 'Quest', id: result.id },
      // ],
    }),
    getConclusion: builder.query({
      query: (id) => ({
        url: `/api/v1/creator/quests/${id}/conclusion`,
      }),
      providesTags: ['Conclusion'],
    }),
    listPlaces: builder.query({
      query: (id) => ({
        url: `/api/v1/creator/quests/${id}/places`,
      }),
      providesTags: (result, _error, arg) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'Place', id })),
              { type: 'Places', id: arg },
            ]
          : [{ type: 'Places', id: arg }],
    }),
    getPlace: builder.query({
      query: (id) => ({
        url: `/api/v1/creator/places/${id}`,
      }),
      providesTags: (_result, _error, arg) => [{ type: 'Place', id: arg }],
      invalidatesTags: (result) => [{ type: 'Quest', id: result?.quest_id }],
    }),
    createPlace: builder.mutation({
      query: (data) => ({
        url: '/api/v1/creator/places',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (result) => [
        { type: 'Places', id: result?.quest_id },
        { type: 'Quest', id: result?.quest_id },
      ],
    }),
    updatePlace: builder.mutation({
      query: ({ id, place }) => ({
        url: `/api/v1/creator/places/${id}`,
        method: 'PATCH',
        body: place,
      }),
      invalidatesTags: (result) => [{ type: 'Place', id: result?.id }],
    }),
    getReviews: builder.query({
      query: ({ id, page = 1 }) => ({
        url: `/api/v1/creator/quests/${id}/reviews?page=${page}`,
      }),
      transformResponse: (response, meta) => {
        const pagination = withPagination(meta)

        return {
          reviews: response,
          ...pagination,
        }
      },
    }),
    getListQuestUsers: builder.query({
      query: ({ id, page = 1, sort = '', field = '' }) => ({
        url: `/api/v1/creator/quests/${id}/users?page=${page}&sort=${sort}&field=${field}`,
      }),
      transformResponse: (response, meta) => {
        const pagination = withPagination(meta)

        return {
          users: response,
          ...pagination,
        }
      },
    }),
    getListResentUser: builder.query({
      query: (id) => ({
        url: `/api/v1/creator/quests/${id}/recent_users`,
      }),
    }),
    getUserAnalytics: builder.query({
      query: (id) => ({
        url: `/api/v1/creator/quests/${id}/users_analytics`,
      }),
    }),
    getTimeAnalytics: builder.query({
      query: (id) => ({
        url: `/api/v1/creator/quests/${id}/time_analytics`,
      }),
    }),
    getGenderAnalytics: builder.query({
      query: (id) => ({
        url: `/api/v1/creator/quests/${id}/gender_analytics`,
      }),
    }),
    getAgeAnalytics: builder.query({
      query: (id) => ({
        url: `/api/v1/creator/quests/${id}/age_analytics`,
      }),
    }),
    getCountryAnalytics: builder.query({
      query: (id) => ({
        url: `/api/v1/creator/quests/${id}/country_analytics`,
      }),
    }),
    getAnalyticsExport: builder.query({
      query: ({ id, data }) => ({
        url: `/api/v1/creator/quests/${id}/analytics_export${data}`,
      }),
    }),
    getReviewChart: builder.query({
      query: (id) => ({
        url: `/api/v1/creator/quests/${id}/reviews/chart`,
      }),
    }),
    getListCountries: builder.query({
      query: () => ({
        url: `/api/v1/countries`,
      }),
    }),
    generateTestLink: builder.mutation({
      query: (id) => ({
        url: `/api/v1/creator/quests/${id}/deep_link`,
      }),
      invalidatesTags: (_result, _error, arg) => [{ type: 'Quest', id: arg }],
    }),
    getQuestStep: builder.query({
      query: (id) => ({
        url: `/api/v1/creator/quests/${id}/step`,
      }),
      providesTags: ['Step'],
    }),
    updateQuestStep: builder.mutation({
      query: ({ id, step }) => ({
        url: `/api/v1/creator/quests/${id}/update_step`,
        method: 'PUT',
        body: {
          step,
        },
      }),
      invalidatesTags: ['Step'],
    }),
    updateQuestStatus: builder.mutation({
      query: ({ id, status }) => ({
        url: `/api/v1/creator/quests/${id}/status`,
        method: 'PUT',
        body: {
          status,
        },
      }),
      async onQueryStarted({ id, _status }, { dispatch, queryFulfilled }) {
        try {
          const { data: quest } = await queryFulfilled
          dispatch(
            questApi.util.updateQueryData('getQuest', id, (draft) => {
              Object.assign(draft, quest)
            })
          )
        } catch {
          dispatch(questApi.util.invalidateTags([{ type: 'Quest', id: id }]))
        }
      },
    }),
    getCities: builder.query({
      query: ({ page = 1, query = '' }) => ({
        url: `/api/v1/cities?page=${page}&query=${query}`,
      }),
    }),
  }),
})

export const {
  useLastUserJoinedQuery,
  usePopularQuestQuery,
  useListQuestsQuery,
  useListBadgesQuery,
  useListCategoryQuery,
  useCreateQuestMutation,
  useUpdateQuestMutation,
  useGetQuestQuery,
  useDeleteQuestMutation,
  useCreateConclusionMutation,
  useUpdateConclusionMutation,
  useGetConclusionQuery,
  useListPlacesQuery,
  useGetPlaceQuery,
  useCreatePlaceMutation,
  useUpdatePlaceMutation,
  useGetReviewsQuery,
  useGetListQuestUsersQuery,
  useGetListResentUserQuery,
  useGetReviewChartQuery,
  useGetUserAnalyticsQuery,
  useGetTimeAnalyticsQuery,
  useGetGenderAnalyticsQuery,
  useGetAgeAnalyticsQuery,
  useGetAnalyticsExportQuery,
  useGetCountryAnalyticsQuery,
  useGenerateTestLinkMutation,
  useGetQuestStepQuery,
  useGetListCountriesQuery,
  useUpdateQuestStepMutation,
  useUpdateQuestStatusMutation,
  useGetCitiesQuery,
} = questApi
