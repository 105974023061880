/* eslint-disable react/display-name */
import React, { useRef, useState, useEffect } from 'react'
import defaultUserImage from 'assets/images/dash-user.png'
import { FaTrash } from 'react-icons/fa'

const AvatarInput = React.forwardRef((props, ref) => {
  const fileRef = useRef()
  const [image, setImage] = useState(null)
  const [files, setFiles] = useState(null)
  const { onChange, onError, name, value } = props
  const [isHovering, setIsHovering] = useState(false)

  const handleMouseOver = () => {
    setIsHovering(true)
  }

  const handleMouseOut = () => {
    setIsHovering(false)
  }

  const onBrowseClick = () => {
    fileRef.current.click()
  }
  useEffect(() => {
    if (value && value?.constructor === String && value !== 'SERVER_FILE') {
      setImage(value)
      onChange('SERVER_FILE')
    }
    if (files && !value) {
      onChange(files)
    }
  }, [value, onChange, files])

  const handleFile = (files) => {
    let imageError = false
    const validTypes = ['image/jpeg', 'image/jpg', 'image/png']
    if (files) {
      const file = files[0]
      const imageUrl = URL.createObjectURL(file)
      if (onError) {
        if (file.size / 1024 > 5120) {
          imageError = true
          onError(name, {
            type: 'file_size',
            message: 'File size is too big',
          })
        }
        if (validTypes.indexOf(file.type) === -1) {
          imageError = true
          onError(name, {
            type: 'file_type',
            message: 'Invalid file type',
          })
        }
      }
      if (!imageError) {
        setImage(imageUrl)
        setFiles(files)
        onChange(files)
      }
    }
  }

  const onImageRemove = () => {
    setImage(null)
    onChange('')
  }

  const onFileInputChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      handleFile(event.target.files)
    }
  }

  const onFileDrop = (event) => {
    event.preventDefault()
    const files = event.dataTransfer.files
    if (files?.length > 0) {
      handleFile(files)
    }
  }

  const dragOver = (e) => {
    e.preventDefault()
  }

  const dragEnter = (e) => {
    e.preventDefault()
  }

  const dragLeave = (e) => {
    e.preventDefault()
  }

  return (
    <div className="flex items-center">
      <div
        className="w-[100px] h-[100px] rounded-full"
        style={
          image && {
            backgroundImage: `url(${image})`,
            backgroundSize: 'cover',
          }
        }
        onDragOver={dragOver}
        onDragEnter={dragEnter}
        onDragLeave={dragLeave}
        onDrop={onFileDrop}
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseOut}
      >
        <input
          type="file"
          className="hidden"
          ref={(e) => {
            ref(e)
            fileRef.current = e
          }}
          onChange={onFileInputChange}
        />
        {image && isHovering && (
          <div className="bg-[rgba(0,0,0,0.5)] flex w-full h-full items-center justify-center rounded-full cursor-pointer">
            <a href="#0" onClick={onImageRemove}>
              <FaTrash className="text-[#F15A5B]" />
            </a>
          </div>
        )}

        {!image && (
          <div
            onClick={onBrowseClick}
            className="w-[100px] h-[100px] rounded-full cursor-pointer"
          >
            <img
              src={defaultUserImage}
              alt=""
              className="w-[100px] h-[100px] rounded-full"
            />
          </div>
        )}
      </div>
      <div
        onClick={onBrowseClick}
        className="flex items-center justify-center cursor-pointer border-[1px] border-[#2C7D92] rounded-[24px] w-[140px] h-[40px] ml-[40px]"
      >
        <div className="flex items-center text-[14px] leading-[150%] font-semibold text-[#2C7D92]">
          Edit Avatar
        </div>
      </div>
    </div>
  )
})

export default AvatarInput
