import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RiCheckLine, RiFileDownloadLine } from 'react-icons/ri'
import { HiUserGroup, HiOutlineLightBulb } from 'react-icons/hi'
import { BsFillSquareFill } from 'react-icons/bs'
import { BiAlarm } from 'react-icons/bi'
import {
  useGetUserAnalyticsQuery,
  useGetAgeAnalyticsQuery,
  useGetGenderAnalyticsQuery,
  useGetCountryAnalyticsQuery,
  useGetTimeAnalyticsQuery,
  useGetAnalyticsExportQuery,
} from 'services/questApi'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import { Pie } from 'react-chartjs-2'
import { format, intervalToDuration, formatDuration } from 'date-fns'

ChartJS.register(ArcElement, Tooltip, Legend)

const Analytics = () => {
  const [lastDate, setLastDate] = useState(null)
  const [ageData, setAgeData] = useState(null)
  const [genderData, setGenderData] = useState(null)
  const [countryData, setCountryData] = useState(null)
  const [timeData, setTimeData] = useState({
    average: null,
    fastest: null,
    longest: null,
  })
  const [exportData, setExportData] = useState({
    id: '',
    data: '',
  })

  const { quest } = useSelector((state) => state.quest)
  const { data: userAnalytics } = useGetUserAnalyticsQuery(quest?.id, {
    skip: !quest?.id,
  })
  const { data: ageAnalytics } = useGetAgeAnalyticsQuery(quest?.id, {
    skip: !quest?.id,
  })
  const { data: genderAnalytics } = useGetGenderAnalyticsQuery(quest?.id, {
    skip: !quest?.id,
  })
  const { data: countryAnalytics } = useGetCountryAnalyticsQuery(quest?.id, {
    skip: !quest?.id,
  })
  const { data: timeAnalytics } = useGetTimeAnalyticsQuery(quest?.id, {
    skip: !quest?.id,
  })

  const { data: exportAnalytics } = useGetAnalyticsExportQuery(exportData, {
    skip: !exportData?.id,
  })
  const start_quest = quest?.start_date
  const end_quest = quest?.end_date
  const userCount = userAnalytics?.users?.count

  const onExportClicks = () => {
    setExportData({
      id: quest?.id,
      data: `?${start_quest === null ? '' : `start_date=${start_quest}`}&${
        end_quest === null ? '' : `end_date=${end_quest}`
      }`,
    })
  }

  const setAverage = (e) => {
    const eLength = e.split(' ').length
    if (eLength > 4) {
      setTimeData((existingValues) => ({
        ...existingValues,
        average: e.split(' ').slice(0, 4),
      }))
    } else {
      setTimeData((existingValues) => ({
        ...existingValues,
        average: e.split(' '),
      }))
    }
  }

  const setFastest = (e) => {
    const eLength = e.split(' ').length
    if (eLength > 4) {
      setTimeData((existingValues) => ({
        ...existingValues,
        fastest: e.split(' ').slice(0, 4),
      }))
    } else {
      setTimeData((existingValues) => ({
        ...existingValues,
        fastest: e.split(' '),
      }))
    }
  }

  const setLongest = (e) => {
    const eLength = e.split(' ').length
    if (eLength > 4) {
      setTimeData((existingValues) => ({
        ...existingValues,
        longest: e.split(' ').slice(0, 4),
      }))
    } else {
      setTimeData((existingValues) => ({
        ...existingValues,
        longest: e.split(' '),
      }))
    }
  }

  const colors = [
    '#2C7D92',
    '#FFCF50',
    '#5A5A60',
    '#7D7D7D',
    '#C2C2C2',
    '#EDEDED',
  ]

  useEffect(() => {
    if (userAnalytics) {
      if (userAnalytics.users.last_joined !== null) {
        setLastDate(
          format(new Date(userAnalytics.users.last_joined), 'mm/dd/yyyy')
        )
      } else {
        setLastDate('0 Users')
      }
    }
    if (ageAnalytics) {
      setAgeData(ageAnalytics.data)
    }
    if (genderAnalytics) {
      setGenderData(genderAnalytics.data)
    }
    if (exportData?.id !== '' && exportAnalytics) {
      var link = document.createElement('a')
      link.href = exportAnalytics.file_url
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      setExportData({
        id: '',
        data: '',
      })
    }
  }, [
    userAnalytics,
    ageAnalytics,
    genderAnalytics,
    exportAnalytics,
    exportData,
  ])

  useEffect(() => {
    if (countryAnalytics) {
      setCountryData(countryAnalytics.data)
    }
    if (timeAnalytics) {
      if (timeAnalytics.time_in_seconds.average !== 0) {
        setFastest(
          formatDuration(
            intervalToDuration({
              start: 0,
              end: timeAnalytics.time_in_seconds.fastest * 1000,
            }),
            { zero: false }
          )
        )
        setAverage(
          formatDuration(
            intervalToDuration({
              start: 0,
              end: timeAnalytics.time_in_seconds.average * 1000,
            }),
            { zero: false }
          )
        )
        setLongest(
          formatDuration(
            intervalToDuration({
              start: 0,
              end: timeAnalytics.time_in_seconds.longest * 1000,
            }),
            { zero: false }
          )
        )
      }
    }
  }, [countryAnalytics, timeAnalytics])

  const chartColor = [
    '#2C7D92',
    '#FFCF50',
    '#5A5A60',
    '#7D7D7D',
    '#C2C2C2',
    '#EDEDED',
  ]

  const chartAgeData = {
    labels: false,
    datasets: [
      {
        label: ' Users',
        data: ageData?.map((item) => item.users_count),
        backgroundColor: chartColor,
      },
    ],
  }

  const chartGenderData = {
    labels: false,
    datasets: [
      {
        label: ' Users',
        data: genderData?.map((item) => item.users_count),
        backgroundColor: chartColor,
      },
    ],
  }

  const chartCountryData = {
    labels: false,
    datasets: [
      {
        label: ' Users',
        data: countryData?.map((item) => item.users_count),
        backgroundColor: chartColor,
      },
    ],
  }

  return (
    <div className="pb-[60px]">
      <div className="flex items-center justify-between">
        <div className="text-[18px] leading-[150%] text-[#262626] font-semibold">
          <p>Quest Report</p>
        </div>
        <div className="flex items-center">
          <div
            onClick={onExportClicks}
            className="flex items-center justify-center cursor-pointer w-[200px] text-[14px] leading-[150%] text-white font-semibold bg-questeon py-[12px] rounded-[50px]"
          >
            <RiFileDownloadLine className="text-white text-xl mr-2" />
            <div>Download Report</div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between mt-[40px]">
        <div className="w-[246px] h-[146px] flex items-center justify-start bg-[#F7F8FA] rounded-[16px]">
          <div className="pl-[24px]">
            <div className="text-[18px] leading-[27px] text-[#262626] font-semibold mb-[8px]">
              Total users
            </div>
            <div className="flex items-center justify-start text-[36px] leading-[120%] text-[#262626] font-semibold">
              <HiUserGroup className="text-questeon text-2xl mr-2" />
              {userAnalytics?.users?.count}
            </div>
            <div className="text-[12px] leading-[16px] text-[#5A5A60] mt-[4px]">
              Last Joined: {lastDate}
            </div>
          </div>
        </div>
        <div className="w-[246px] h-[146px] flex items-center justify-start bg-[#F7F8FA] rounded-[16px]">
          <div className="pl-[24px]">
            <div className="text-[18px] leading-[27px] text-[#262626] font-semibold mb-[8px]">
              Quest Completion
            </div>
            <div className="flex items-center justify-start text-[36px] leading-[120%] text-[#262626] font-semibold">
              <RiCheckLine className="text-questeon text-3xl mr-2" />
              {userAnalytics?.quest_completion?.percentage}
            </div>
            <div className="text-[12px] leading-[16px] text-[#5A5A60] mt-[4px]">
              {userAnalytics?.quest_completion?.users_count} of{' '}
              {userAnalytics?.users?.count} users finish the quest
            </div>
          </div>
        </div>
        <div className="w-[246px] h-[146px] flex items-center justify-start bg-[#F7F8FA] rounded-[16px]">
          <div className="pl-[24px]">
            <div className="text-[18px] leading-[27px] text-[#262626] font-semibold mb-[8px]">
              Overall Hint Used
            </div>
            <div className="flex items-center justify-start text-[36px] leading-[120%] text-[#262626] font-semibold">
              <HiOutlineLightBulb className="text-questeon text-3xl mr-2" />
              {userAnalytics?.overall_hint_used?.percentage}
            </div>
            <div className="text-[12px] leading-[16px] text-[#5A5A60] mt-[4px]">
              {userAnalytics?.overall_hint_used?.users_count} of{' '}
              {userAnalytics?.users?.count} users use the hint
            </div>
          </div>
        </div>
      </div>
      {userCount !== 0 && (
        <>
          <div className="flex items-center justify-between mt-[40px]">
            <div className="w-6/12">
              <div className="text-[18px] leading-[27px] text-[#262626] font-semibold mb-5">
                Age Range
              </div>
              <div className="flex items-start justify-start">
                <div className="w-7/12">
                  <Doughnut data={chartAgeData} />
                </div>
                <div className="mt-5 ml-5">
                  {ageData?.map((item, index) => (
                    <div key={index}>
                      <div className="flex items-start justify-start h-[80px]">
                        <div className="mr-2 mt-[2px]">
                          <BsFillSquareFill
                            style={{
                              color: colors[index],
                            }}
                          />
                        </div>
                        <div>
                          <p className="text-[14px] leading-[21px] text-[#5A5A60]">
                            {item?.label}
                          </p>
                          <p className="text-[18px] leading-[27px] text-[#262626] font-semibold">
                            {item?.percentage}
                          </p>
                          <p className="text-[12px] leading-[16px] text-[#5A5A60]">
                            {item?.users_count} users
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="w-6/12">
              <div className="text-[18px] leading-[27px] text-[#262626] font-semibold mb-5">
                Gender
              </div>
              <div className="flex items-start justify-start">
                <div className="w-7/12">
                  <Doughnut data={chartGenderData} />
                </div>
                <div className="mt-5 ml-5">
                  {genderData?.map((item, index) => (
                    <div key={index}>
                      <div className="flex items-start justify-start h-[80px]">
                        <div className="mr-2 mt-[2px]">
                          <BsFillSquareFill
                            style={{
                              color: colors[index],
                            }}
                          />
                        </div>
                        <div>
                          <p className="text-[14px] leading-[21px] text-[#5A5A60]">
                            {item?.label}
                          </p>
                          <p className="text-[18px] leading-[27px] text-[#262626] font-semibold">
                            {item?.percentage}
                          </p>
                          <p className="text-[12px] leading-[16px] text-[#5A5A60]">
                            {item?.users_count} users
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-start mt-[40px]">
            <div className="w-full">
              <div className="text-[18px] leading-[27px] text-[#262626] font-semibold mb-5">
                Country of Residence
              </div>
              <div className="flex items-start justify-start">
                <div className="w-4/12">
                  <Pie data={chartCountryData} />
                </div>
                <div className="mt-5 ml-5 grid grid-rows-2 grid-flow-col gap-4">
                  {countryData?.map((item, index) => (
                    <div key={index}>
                      <div className="flex items-start justify-start h-[80px]">
                        <div className="mr-2 mt-[2px]">
                          <BsFillSquareFill
                            style={{
                              color: colors[index],
                            }}
                          />
                        </div>
                        <div>
                          <p className="text-[14px] leading-[21px] text-[#5A5A60]">
                            {item?.label}
                          </p>
                          <p className="text-[18px] leading-[27px] text-[#262626] font-semibold">
                            {item?.percentage}
                          </p>
                          <p className="text-[12px] leading-[16px] text-[#5A5A60]">
                            {item?.users_count} users
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <div className="mt-[40px]">
        <div className="text-[18px] leading-[27px] text-[#262626] font-semibold mb-5">
          Time Completion
        </div>
        <div className="flex items-center justify-between">
          <div
            style={{
              background: 'rgba(44, 125, 146, 0.1)',
            }}
            className="w-[246px] h-[146px] flex items-center justify-start rounded-[16px]"
          >
            <div className="px-[24px] w-full">
              <div className="flex items-center justify-end w-full">
                <BiAlarm className="text-3xl text-[#2C7D92]" />
              </div>
              <div className="text-[14px] leading-[21px] text-[#5A5A60] mb-[4px]">
                Fastest time
              </div>
              {timeData?.fastest === null ? (
                <div className="flex items-center justify-start text-[24px] leading-[120%] text-[#262626] font-semibold">
                  0
                </div>
              ) : (
                <div className="flex items-center justify-start text-[24px] leading-[120%] text-[#262626] font-semibold">
                  {timeData?.fastest?.map((item, index) =>
                    index % 2 === 0 ? (
                      <div key={index}>{item}</div>
                    ) : (
                      <div key={index} className="mr-1">
                        {item.slice(0, 1)}
                      </div>
                    )
                  )}
                </div>
              )}
            </div>
          </div>

          <div
            style={{
              background: 'rgba(255, 207, 80, 0.1)',
            }}
            className="w-[246px] h-[146px] flex items-center justify-start rounded-[16px]"
          >
            <div className="px-[24px] w-full">
              <div className="flex items-center justify-end w-full">
                <BiAlarm className="text-3xl text-[#FFCF50]" />
              </div>
              <div className="text-[14px] leading-[21px] text-[#5A5A60] mb-[4px]">
                Longest Time
              </div>
              {timeData?.longest === null ? (
                <div className="flex items-center justify-start text-[24px] leading-[120%] text-[#262626] font-semibold">
                  0
                </div>
              ) : (
                <div className="flex items-center justify-start text-[24px] leading-[120%] text-[#262626] font-semibold">
                  <div className="flex items-center justify-start text-[24px] leading-[120%] text-[#262626] font-semibold">
                    {timeData?.longest?.map((item, index) =>
                      index % 2 === 0 ? (
                        <div key={index}>{item}</div>
                      ) : (
                        <div key={index} className="mr-1">
                          {item.slice(0, 1)}
                        </div>
                      )
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="w-[246px] h-[146px] flex items-center bg-[#F7F8FA] justify-start rounded-[16px]">
            <div className="px-[24px] w-full">
              <div className="flex items-center justify-end w-full">
                <BiAlarm className="text-3xl text-[#7D7D7D]" />
              </div>
              <div className="text-[14px] leading-[21px] text-[#5A5A60] mb-[4px]">
                Average time
              </div>
              {timeData?.average === null ? (
                <div className="flex items-center justify-start text-[24px] leading-[120%] text-[#262626] font-semibold">
                  0
                </div>
              ) : (
                <div className="flex items-center justify-start text-[24px] leading-[120%] text-[#262626] font-semibold">
                  <div className="flex items-center justify-start text-[24px] leading-[120%] text-[#262626] font-semibold">
                    <div className="flex items-center justify-start text-[24px] leading-[120%] text-[#262626] font-semibold">
                      {timeData?.average?.map((item, index) =>
                        index % 2 === 0 ? (
                          <div key={index}>{item}</div>
                        ) : (
                          <div key={index} className="mr-1">
                            {item.slice(0, 1)}
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Analytics
