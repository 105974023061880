import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  Link,
  Outlet,
  NavLink,
  useLocation,
  useNavigate,
} from 'react-router-dom'
import { useGetNotificationQuery } from 'services/creatorApi'
import { FaPlus } from 'react-icons/fa'
import RecentUserJoin from 'components/quest/RecentUserJoin'
import { resetHeader } from 'features/navigationSlice'
import useWindowDimensions from '../hooks/WindowDimensions'

function Home() {
  const { data: notificationData } = useGetNotificationQuery()
  const dispatch = useDispatch()
  const { height } = useWindowDimensions()
  const routePath = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (routePath.pathname === '/') {
      navigate('/quests/list')
    }
  }, [routePath])

  useEffect(() => {
    dispatch(resetHeader())
  }, [dispatch])

  return (
    <div
      style={{
        height: height - 80,
      }}
    >
      <div className="flex justify-between px-[32px] py-[38px]">
        <div className="w-9/12">
          <div className="flex justify-between items-center">
            <ul className="flex items-center">
              <li>
                <NavLink
                  to="/quests/list"
                  className={({ isActive }) =>
                    isActive
                      ? 'h-[60px] flex items-center justify-between text-[16px] leading-[24px] font-semibold py-[18px] px-[40px] text-[#2C7D92] border-b-2 border-[#2C7D92] hover:text-[#7D7D7D]'
                      : 'h-[60px] flex items-center justify-between text-[16px] leading-[24px] text-[#262626] font-semibold py-[18px] px-[40px] border-b-[1px] hover:text-[#7D7D7D]'
                  }
                >
                  Quest List
                  {notificationData?.quests_count > 0 && (
                    <span className="flex items-center justify-center text-center ml-2 bg-[#FFCF50] w-[30px] h-[30px] rounded-full text-[12px] leading-[16px] text-[#262626]">
                      {notificationData?.quests_count}
                    </span>
                  )}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/quests/popular"
                  className={({ isActive }) =>
                    isActive
                      ? 'h-[60px] flex items-center justify-between text-[16px] leading-[24px] font-semibold py-[18px] px-[40px] text-[#2C7D92] border-b-2 border-[#2C7D92] hover:text-[#7D7D7D]'
                      : 'h-[60px] flex items-center justify-between text-[16px] leading-[24px] text-[#262626] font-semibold py-[18px] px-[40px] border-b-[1px] hover:text-[#7D7D7D]'
                  }
                >
                  Popular Quest
                </NavLink>
              </li>
            </ul>

            <div
              // onClick={() => window.location.reload()}
              className="flex items-center text-[#FFFFFF] bg-[#2C7D92] rounded-[50px] px-[31px] py-[13px] text-[16px] leading-[24px] font-semibold"
            >
              <FaPlus />
              <Link
                to="/quests/new"
                className="text-[#FFFFFF] hover:text-[#FFFFFF]"
              >
                &nbsp; Create Quest
              </Link>
            </div>
          </div>
          <div className="pt-[24px] overflow-auto">
            <div>
              <Outlet />
            </div>
          </div>
        </div>
        <div className="pl-[20px] w-3/12">
          <RecentUserJoin />
        </div>
      </div>
    </div>
  )
}

export default Home
