/* eslint-disable no-unused-vars */
import React, { useState, useCallback } from 'react'
import Modal from 'react-bootstrap/Modal'
import { useCloseModal } from 'utils/questModal'
import Cropper from 'react-easy-crop'
import getCroppedImg from '../../inputs/CropImage'
import ReactSlider from 'react-slider'
import styled from 'styled-components'
import { AiOutlineZoomIn, AiOutlineZoomOut } from 'react-icons/ai'

const StyledSlider = styled(ReactSlider)`
  width: 100%;
  height: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledThumb = styled.div`
  height: 15px;
  line-height: 15px;
  width: 15px;
  background-color: #2c7d92;
  color: #fff;
  border-radius: 50%;
  cursor: grab;
`

const StyledTrack = styled.div`
  top: 0;
  bottom: 0;
  background: ${(props) => (props.index === 1 ? '#ddd' : '#2C7D92')};
  border-radius: 999px;
`

const Track = (props, state) => <StyledTrack {...props} index={state.index} />

const ImageCroppingModal = ({
  imageValue,
  modalConditional,
  modalClicked,
  resultImage,
  resultClicked,
  cropingRatio,
}) => {
  const closeModal = useCloseModal()
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [croppedImage, setCroppedImage] = useState(null)

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(imageValue, croppedAreaPixels)
      setCroppedImage(croppedImage)
      resultImage.push(croppedImage)
    } catch (e) {
      console.error(e)
    }
  }, [croppedAreaPixels])

  return (
    <Modal
      show={modalConditional}
      onHide={closeModal}
      className="fixed top-0 left-0 right-0 bottom-0 bg-[rgba(0,0,0,0.8)] overflow-auto"
    >
      <div className="flex items-center justify-center w-screen h-screen">
        <Modal.Body className="flex items-center justify-center">
          <div className="flex items-center justify-between py-[22px] -mt-[200px]">
            <div className="">
              <div
                className="cropper bg-[rgba(0,0,0,0.8)] rounded-t-[20px]"
                style={{
                  width: '500px',
                }}
              >
                <Cropper
                  image={imageValue}
                  crop={crop}
                  zoom={zoom}
                  aspect={cropingRatio}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                  objectFit="contain"
                  style={{
                    containerStyle: {
                      borderRadius: '20px',
                      padding: '20px',
                    },
                  }}
                />
              </div>
              <div className="bg-white rounded-b-[20px] p-[20px] flex items-center justify-between">
                <div className="w-6/12 flex items-center justify-between">
                  <AiOutlineZoomOut className="text-xl text-questeon mr-2" />
                  <StyledSlider
                    onChange={(value) => (value > 10 ? setZoom(value / 10) : 1)}
                    renderTrack={Track}
                    renderThumb={(props) => (
                      <StyledThumb {...props}></StyledThumb>
                    )}
                  />
                  <AiOutlineZoomIn className="text-xl text-questeon ml-2" />
                </div>

                <div onClick={resultClicked}>
                  <button
                    onClick={showCroppedImage}
                    className="flex items-center justify-center bg-[#2C7D92] rounded-[50px] text-[16px] leading-[24px] text-white hover:text-white font-semibold w-[160px] py-[13px]"
                  >
                    Crop & Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  )
}

export default ImageCroppingModal
