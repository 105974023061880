import QInfo from 'components/QInfo'
import React from 'react'
import image from 'assets/images/404.svg'

function NotFound() {
  return (
    <QInfo
      title="Ooops! Page Not Found"
      subtitle="This page doesn’t exist or was removed! We suggest you to go back"
      image={image}
      buttonRoute="/"
      buttonText="Go Back"
    />
  )
}

export default NotFound
