import React from 'react'
import timerIcon from 'assets/images/timer.png'
import starIcon from 'assets/images/popularquest/star.svg'
import userIcon from 'assets/images/popularquest/user.svg'
import { usePopularQuestQuery } from 'services/questApi'
import { useGetNotificationQuery } from 'services/creatorApi'
import Skeleton from 'react-loading-skeleton'
import { useNavigate } from 'react-router-dom'
import EmptyQuest from './EmptyQuest'

const PopularQuest = () => {
  const { data: popularQuestData, isLoading } = usePopularQuestQuery()
  const { data: notificationData } = useGetNotificationQuery()
  const popularQuestDataEmpty = popularQuestData?.length === 0
  const questEmpty = notificationData?.quests_count === 0
  const navigate = useNavigate()
  const onQuestClick = (quest) => {
    navigate(`/quests/${quest.slug}/`)
  }
  return (
    <>
      {isLoading && <SkeletonQuest count={5} />}
      {popularQuestDataEmpty && questEmpty && <EmptyQuest />}
      {popularQuestDataEmpty && !questEmpty && <EmptyPopularQuest />}
      {!popularQuestDataEmpty && (
        <div className="grid grid-cols-3 gap-3 gap-y-8">
          {popularQuestData?.map((quest) => (
            <div
              className="bg-[#F7F8FA] rounded-[24px]"
              onClick={() => {
                onQuestClick(quest)
              }}
              key={quest.id}
              style={{ cursor: 'pointer' }}
            >
              <div className="h-[250px] overflow-hidden">
                <img
                  src={quest.image_url}
                  alt=""
                  className="h-full w-full object-cover object-center rounded-t-[24px]"
                />
              </div>
              <div className="px-[16px] pt-[16px] pb-[24px]">
                <h3 className="text-[18px] leading-[27px] text-[#262626] font-semibold">
                  {quest.title}
                </h3>
                <div
                  href="#0"
                  className="flex items-center text-[12px] leading-[16px] text-[#7D7D7D]"
                >
                  <img src={userIcon} alt="" className="mr-2 w-[17px] " />
                  {quest.users_count} Users
                </div>
                <div
                  href="#0"
                  className="flex items-center text-[12px] leading-[16px] text-[#7D7D7D] pt-2"
                >
                  <img src={starIcon} alt="" className="mr-2 w-[17px]" />
                  <span className="font-bold">{quest.star}</span>
                  &nbsp;(
                  {quest.reviews_count} Reviews)
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  )
}

const EmptyPopularQuest = () => {
  return (
    <div className="mt-[100px]">
      <div className="flex items-center justify-center">
        <img src={timerIcon} alt="" />
      </div>
      <div className="flex items-center justify-center">
        <div>
          <h3 className="text-center text-[18px] leading-[150%] text-[#5A5A60] font-semibold mt-2">
            We still calculating...
          </h3>
          <p className="text-center text-[14px] leading-[150%] text-[#5A5A60] font-extralight">
            Your quest need more reviews & users to join
          </p>
        </div>
      </div>
    </div>
  )
}

const SkeletonQuest = ({ count = 1 }) => {
  return (
    <div className="w-full">
      {Array.from({ length: count }).map((_, index) => (
        <div className="" key={`popular-${index}`}>
          <div className="w-full">
            <Skeleton height="200px" />
          </div>
          <div className="w-full">
            <h3>
              <Skeleton width={200} />
            </h3>
            <a href="#0">
              <Skeleton width={200} />
            </a>
            <a href="#0">
              <Skeleton width={200} />
            </a>
          </div>
        </div>
      ))}
    </div>
  )
}

export default PopularQuest
