import React from 'react'
import { useSelector } from 'react-redux'
import { FaBars } from 'react-icons/fa'
import { CgChevronRight, CgClose } from 'react-icons/cg'
import { useDispatch } from 'react-redux'
import { setActive } from 'features/navigationSlice'
import { Link } from 'react-router-dom'

function Header() {
  const { header, active } = useSelector((state) => state.navigation)
  const dispatch = useDispatch()
  return (
    <div className="flex items-center justify-between pr-[32px] border-b-[1px] h-[80px] overflow-auto">
      {header === 'QUEST' || header === 'SETTING' ? (
        <QuestHeader />
      ) : (
        <HomeHeader />
      )}

      <div className="lg:invisible">
        <a href="#0" onClick={() => dispatch(setActive())}>
          {active ? <CgClose /> : <FaBars />}
        </a>
      </div>
    </div>
  )
}

const HomeHeader = () => {
  return (
    <div className="grid justify-start items-center text-[#7D7D7D] text-[18px] leading-[27px] font-semibold pl-[32px]">
      Hi Creator! Welcome to Questeon 👋
    </div>
  )
}

const QuestHeader = () => {
  const { header, headerTitle } = useSelector((state) => state.navigation)
  const { quest } = useSelector((state) => state.quest)

  return (
    <div className="flex justify-start items-center text-[#7D7D7D] text-[16px] leading-[24px] pl-[32px] overflow-auto h-[80px]">
      {(() => {
        switch (header) {
          case 'QUEST':
            return (
              <Link to="/quests" className="text-[#2C7D92]">
                Quest
              </Link>
            )
          case 'SETTING':
            return (
              <Link to="/setting" className="text-[#2C7D92]">
                Setting
              </Link>
            )
          default:
            return null
        }
      })()}
      <span className="px-2">
        <CgChevronRight />
      </span>
      {quest ? (
        <Link to={`/quests/${quest.slug}`}>{quest.title}</Link>
      ) : (
        headerTitle
      )}
    </div>
  )
}

export default Header
