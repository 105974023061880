/* eslint-disable no-extra-semi */
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import {
  faAngleLeft,
  faAngleRight,
  faEllipsis,
} from '@fortawesome/free-solid-svg-icons'
import Skeleton from 'react-loading-skeleton'
import { Link } from 'react-router-dom'

import { usePagination, DOTS, getPagination } from 'utils/pagination'

const Pagination = ({ isLoading, total, perPage, currentPage }) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount: total,
    pageSize: perPage,
  })
  const { to, from, totalPages } = getPagination(total, perPage, currentPage)
  const currentPageNumber = Number(currentPage)

  return (
    <>
      {isLoading ? (
        <>
          <Skeleton width={200} />
          <div className="table-nav-fl">
            <Skeleton width={200} />
          </div>
        </>
      ) : (
        <>
          {paginationRange && (
            <>
              <div className="result_lf">
                <p>
                  Results : {from}-{to} of {total}
                </p>
              </div>
              <div className="result__number">
                <ul>
                  <li>
                    {currentPageNumber > 1 ? (
                      <Link to={`?page=${currentPageNumber - 1}`}>
                        <FontAwesomeIcon icon={faAngleLeft} />
                      </Link>
                    ) : (
                      <FontAwesomeIcon icon={faAngleLeft} />
                    )}
                  </li>
                  {paginationRange?.map((pageNumber, index) => {
                    if (pageNumber === DOTS) {
                      ;<a href="#0" key={index}>
                        <FontAwesomeIcon icon={faEllipsis} />{' '}
                      </a>
                    }
                    return (
                      <li
                        key={index}
                        className={classNames({
                          active: pageNumber === currentPageNumber,
                        })}
                      >
                        <Link to={`?page=${pageNumber}`}>{pageNumber}</Link>
                      </li>
                    )
                  })}
                  <li>
                    {totalPages > currentPageNumber ? (
                      <Link to={`?page=${currentPageNumber + 1}`}>
                        <FontAwesomeIcon icon={faAngleRight} />
                      </Link>
                    ) : (
                      <FontAwesomeIcon icon={faAngleRight} />
                    )}
                  </li>
                </ul>
              </div>
            </>
          )}
        </>
      )}
    </>
  )
}

export default Pagination
