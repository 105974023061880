import { serialize } from 'object-to-formdata'

const normalizeObjectData = (data) => {
  let dataObject = {}
  Object.entries(data).forEach(([key, value]) => {
    let objectValue
    if (!value && typeof value !== 'boolean') {
      return
    }
    switch (value?.constructor) {
      case Object:
        objectValue = normalizeObjectData(value)
        break
      case Array:
        if (value && value[0]?.constructor === Object && value[0].value) {
          objectValue = value.map((item) => item.value)
        } else {
          objectValue = normalizeObjectData(value)
        }
        break
      case FileList:
        objectValue = value[0]
        break
      case String:
        if (value?.split('/')[0] === 'https:') {
          return
        }
        objectValue = value
        break
      default:
        objectValue = value
    }
    dataObject = { ...dataObject, [key]: objectValue }
  })
  return dataObject
}

export const serializeData = (data) => {
  return serialize(normalizeObjectData(data))
}
