import React from 'react'
import logo from 'assets/images/logo.png'
import { Link } from 'react-router-dom'

const Logo = () => {
  return (
    <div className="site-logo">
      <Link to="/">
        <img src={logo} alt="" />
      </Link>
    </div>
  )
}

export default Logo
