import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Outlet } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import useWindowDimensions from '../../hooks/WindowDimensions'
import { useGetQuestQuery } from 'services/questApi'
import { setQuest, unSetQuest, setAction } from 'features/questSlice'
import { setHeader } from 'features/navigationSlice'
import QuestNavigation from 'components/quest/QuestNavigation'

const QuestEdit = () => {
  const dispatch = useDispatch()
  const { id } = useParams()
  const { data } = useGetQuestQuery(id)
  const { height } = useWindowDimensions()

  useEffect(() => {
    dispatch(setHeader('QUEST'))
    if (data) {
      dispatch(setQuest(data))
      dispatch(setAction('EDIT'))
    }
  }, [dispatch, data])

  useEffect(() => {
    return () => {
      dispatch(unSetQuest())
    }
  }, [dispatch])

  return (
    <div
      className="px-[32px] py-[38px] overflow-y-auto overflow-x-hidden"
      style={{
        height: height - 80,
      }}
    >
      <div className="w-[730px]">
        <QuestNavigation />
      </div>
      <Outlet />
    </div>
  )
}

export default QuestEdit
