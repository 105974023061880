import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import list from 'assets/images/questdetail/list.svg'
import dollar from 'assets/images/questdetail/dollar.svg'
import time from 'assets/images/questdetail/time.svg'
import edit from 'assets/images/questdetail/edit.svg'
import places from 'assets/images/questinfo/places.svg'
import route from 'assets/images/questinfo/route.svg'
import task from 'assets/images/questinfo/task.svg'
import age from 'assets/images/questinfo/age.svg'
import duration from 'assets/images/questinfo/duration.svg'
import HTMLView from 'components/HTMLView'
import ListPlace from './ListPlace'
import SaveNotice from 'components/SaveNotice'
import { HiOutlineUserGroup } from 'react-icons/hi'
import { AiOutlineCalendar } from 'react-icons/ai'
import { format } from 'date-fns'

const OverviewForm = () => {
  const { quest: data } = useSelector((state) => state.quest)
  const [saveNotice, setSaveNotice] = useState(false)

  const optionAge = {
    child: '0-12 years',
    teen: '13+ years',
    adult: '16+ years',
  }

  let isFree = true
  if (data) {
    isFree = data?.price === 0
  }

  useEffect(() => {
    setSaveNotice(true)
    setTimeout(() => {
      setSaveNotice(false)
    }, 1000)
  }, [])

  return (
    <>
      {saveNotice && <SaveNotice />}
      <div className="flex justify-between pr-[40px]">
        <div className="w-full mr-4 relative">
          <div className="flex items-center text-[24px] leading-[130%] text-[#262626] font-semibold">
            <span className="flex items-center justify-center text-center mr-2 bg-[#FFCF50] w-[30px] h-[30px] rounded-full text-[16px] leading-[24px] text-[#262626]">
              5
            </span>
            Overview
          </div>
          {data && (
            <>
              <div className="mt-[40px]">
                <div className="flex items-start justify-between text-[16px] leading-[24px] font-semibold text-[#2C7D92] mb-[20px]">
                  <h3 className="flex items-center text-[24px] leading-[130%] text-[#262626] font-semibold">
                    {data.title}
                  </h3>
                  <Link
                    to={`/quests/${data?.slug}/edit`}
                    className="flex items-center hover:text-[#2C7D92]"
                  >
                    <img src={edit} alt="" className="mr-2" />
                    Edit Section
                  </Link>
                </div>
                <div className="">
                  <div>
                    <div className="h-[300px] w-full rounded-[24px]">
                      <img
                        src={data.image_url}
                        alt=""
                        className="h-[300px] w-full object-cover object-center rounded-[24px]"
                      />
                    </div>
                    <div className="flex items-start justify-end -mt-16 mr-[50px]">
                      <img
                        src={data.badge?.image?.url}
                        className="h-[100px] rounded-full"
                      />
                    </div>
                  </div>
                  <div className="">
                    <div className="flex items-center">
                      <p className="flex items-center text-[14px] leading-[21px] font-semibold text-[#5A5A60] mr-6">
                        <img src={dollar} alt="" />
                        &nbsp; {isFree ? 'Free' : data.price}
                      </p>
                      {data.place_sequential && (
                        <p className="flex items-center text-[14px] leading-[21px] font-semibold text-[#5A5A60] mr-6">
                          <img src={list} alt="" />
                          &nbsp; Place Sequential
                        </p>
                      )}
                      {data.time_tracker && (
                        <p className="flex items-center text-[14px] leading-[21px] font-semibold text-[#5A5A60] mr-6">
                          <img src={time} alt="" />
                          &nbsp; Time Tracker
                        </p>
                      )}
                      {data.group_play && (
                        <p className="flex items-center">
                          <HiOutlineUserGroup className="text-xl text-[#C2C2C2]" />
                          &nbsp; Group play
                        </p>
                      )}
                    </div>
                    {data.end_date || data.start_date ? (
                      <div className="mt-[24px] flex items-center justify-start">
                        <div>
                          <AiOutlineCalendar className="text-[#7D7D7D] text-xl" />
                        </div>
                        <div className="ml-[18px] flex items-center justify-start">
                          {data.start_date !== null && (
                            <div>
                              <p className="text-[14px] leading-[21px] text-[#5A5A60] font-semibold">
                                Start date{' '}
                                <span className="text-[14px] leading-[150%] text-[#262626] font-light">
                                  {format(
                                    new Date(data.start_date),
                                    'dd-MM-yyyy h:mm aa'
                                  )}
                                </span>
                              </p>
                            </div>
                          )}
                          {data.end_date !== null && (
                            <div className="ml-[18px]">
                              <p className="text-[14px] leading-[21px] text-[#5A5A60] font-semibold">
                                End date{' '}
                                <span className="text-[14px] leading-[150%] text-[#262626] font-light">
                                  {format(
                                    new Date(data.end_date),
                                    'dd-MM-yyyy h:mm aa'
                                  )}
                                </span>
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    ) : null}
                    <div className="my-[26px] text-[14px] leading-[150%] text-[#5A5A60]">
                      <HTMLView content={data.description} />
                    </div>
                    <div className="flex items-start pt-[24px] text-[14px] leading-[150%] text-[#7D7D7D] mb-[26px]">
                      <div>
                        <div className="h-[30px]">
                          <img src={places} alt="" className="h-[20px]" />
                        </div>
                        <p className="py-[4px]">Check Point</p>
                        <h4 className="text-[24px] leading-[130%] text-[#262626] font-semibold">
                          {data.places_count}
                        </h4>
                      </div>
                      <div className="mx-20">
                        <div className="h-[30px]">
                          <img src={task} alt="" className="h-[20px]" />
                        </div>
                        <p className="py-[4px]">Task</p>
                        <h4 className="text-[24px] leading-[130%] text-[#262626] font-semibold">
                          {data.tasks_count}
                        </h4>
                      </div>
                      <div>
                        <div className="h-[30px]">
                          <img src={route} alt="" className="h-[20px]" />
                        </div>
                        <p className="py-[4px]">Route</p>
                        <h4 className="text-[24px] leading-[130%] text-[#262626] font-semibold">
                          {data.route} <sub>km</sub>
                        </h4>
                      </div>
                      <div className="ml-20">
                        <div className="h-[30px]">
                          <img src={duration} alt="" className="h-[25px]" />
                        </div>
                        <p className="py-[4px]">Duration</p>
                        <h4 className="text-[24px] leading-[130%] text-[#262626] font-semibold">
                          {data.duration}
                        </h4>
                      </div>
                      <div className="ml-20">
                        <div className="h-[30px]">
                          <img src={age} alt="" className="h-[25px]" />
                        </div>
                        <p className="py-[4px]">Suggested Age</p>
                        <p className="text-[24px] leading-[130%] text-[#262626] font-semibold">
                          {data.age?.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                            letter.toUpperCase()
                          ) || 'All ages'}
                        </p>
                        <p className="pt-[4px] text-[14px] leading-[150%] text-[#262626]">
                          {optionAge[data.age]}
                        </p>
                      </div>
                    </div>
                    <div>
                      <h3 className="text-[18px] leading-[150%] text-[#262626] font-semibold">
                        Highlights
                      </h3>
                      <ul className="list-disc list-inside">
                        {data.highlights.map((highlight, index) => (
                          <li
                            key={`highlight-${index}`}
                            className="text-[#FFCF50]"
                          >
                            <span className="text-[#5A5A60] text-[14px] leading-[150%]">
                              {highlight}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="my-[70px]">
                  <div className="flex items-start justify-between text-[16px] leading-[24px] font-semibold text-[#2C7D92] mb-[20px]">
                    <h3 className="flex items-center text-[24px] leading-[130%] text-[#262626] font-semibold">
                      Intro
                    </h3>
                    <Link
                      to={`/quests/${data?.slug}/edit/intro`}
                      className="flex items-center hover:text-[#2C7D92]"
                    >
                      <img src={edit} alt="" className="mr-2" />
                      Edit Section
                    </Link>
                  </div>
                  <div className="flex w-full">
                    <div className="h-[300px] w-[300px] mr-[46px]">
                      {data.intro_image_url && (
                        <div className="h-[300px] w-[300px] mr-[46px]">
                          <img
                            src={data.intro_image_url}
                            alt=""
                            className="w-full h-full object-cover object-center rounded-[24px]"
                          />
                        </div>
                      )}
                    </div>
                    <div className="text-[#5A5A60] text-[14px] leading-[150%] overflow-auto">
                      <HTMLView content={data.intro} />
                      {data.quest_intros.map((intro) => (
                        <div key={intro.id} className="mt-[24px]">
                          <p className="text-[18px] leading-[150%] text-[#262626] font-semibold mb-1">
                            {intro.title}
                          </p>
                          <HTMLView content={intro.description} />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="flex items-start justify-between text-[16px] leading-[24px] font-semibold text-[#2C7D92] mb-[20px]">
                  <h3 className="flex items-center text-[24px] leading-[130%] text-[#262626] font-semibold">
                    Places & Tasks
                  </h3>
                  <Link
                    to={`/quests/${data?.slug}/edit/place`}
                    className="flex items-center hover:text-[#2C7D92]"
                  >
                    <img src={edit} alt="" className="mr-2" />
                    Edit Section
                  </Link>
                </div>
                <ListPlace id={data?.id} />
              </div>
              <div className="mt-[70px]">
                <div className="flex items-start justify-between text-[16px] leading-[24px] font-semibold text-[#2C7D92] mb-[20px]">
                  <h3 className="flex items-center text-[24px] leading-[130%] text-[#262626] font-semibold">
                    Conclusion
                  </h3>
                  <Link
                    to={`/quests/${data?.slug}/edit/conclusion`}
                    className="flex items-center hover:text-[#2C7D92]"
                  >
                    <img src={edit} alt="" className="mr-2" />
                    Edit Section
                  </Link>
                </div>
                <div className="text-[#5A5A60] text-[14px] leading-[150%]">
                  <HTMLView content={data.conclusion?.description} />
                </div>
              </div>
            </>
          )}
          <div className="mt-[70px]">
            <div className="flex items-center justify-center">
              <Link
                onClick={() =>
                  setTimeout(() => {
                    window.location.reload()
                  }, 30)
                }
                to={`/quests/${data?.slug}/`}
                className="flex items-center justify-center bg-[#2C7D92] rounded-[50px] text-[16px] leading-[24px] text-white hover:text-white font-semibold w-[200px] py-[13px]"
              >
                See your quest
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default OverviewForm
