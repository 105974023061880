/* eslint-disable no-extra-semi */
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAngleLeft,
  faAngleRight,
  faEllipsis,
} from '@fortawesome/free-solid-svg-icons'
import Skeleton from 'react-loading-skeleton'
import { usePagination, DOTS, getPagination } from 'utils/pagination'

const Pagination = ({
  isLoading,
  total,
  perPage,
  currentPage,
  onPageChange,
}) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount: total,
    pageSize: perPage,
  })
  const { to, from, totalPages } = getPagination(total, perPage, currentPage)

  const onNext = () => {
    if (totalPages > currentPage) {
      onPageChange(currentPage + 1)
    }
  }

  const onPrevious = () => {
    onPageChange(currentPage - 1)
  }

  return (
    <div className="table-nav">
      {isLoading ? (
        <>
          <Skeleton width={200} />
          <div className="table-nav-fl">
            <Skeleton width={200} />
          </div>
        </>
      ) : (
        <>
          <p>
            Results : {from}-{to} of {total}
          </p>

          <div className="table-nav-fl">
            <a
              href="#0"
              onClick={() => {
                if (currentPage !== 1) {
                  onPrevious()
                }
              }}
            >
              <FontAwesomeIcon icon={faAngleLeft} />
            </a>
            {paginationRange?.map((pageNumber, index) => {
              if (pageNumber === DOTS) {
                ;<a href="#0" key={index}>
                  <FontAwesomeIcon icon={faEllipsis} />{' '}
                </a>
              }
              return (
                <a
                  href="#0"
                  key={index}
                  className={`${pageNumber === currentPage ? 'actives' : ''}`}
                  onClick={() => onPageChange(pageNumber)}
                >
                  {pageNumber}
                </a>
              )
            })}
            <a href="#0" onClick={onNext}>
              <FontAwesomeIcon icon={faAngleRight} />
            </a>
          </div>
        </>
      )}
    </div>
  )
}

export default Pagination
