import React, { useEffect } from 'react'
import QuestNavigation from 'components/quest/QuestNavigation'
import { Outlet } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setHeader, setHeaderTitle } from 'features/navigationSlice'
import { unSetQuest, setAction } from 'features/questSlice'
import useWindowDimensions from '../../hooks/WindowDimensions'

const QuestNew = () => {
  const dispatch = useDispatch()
  const { height } = useWindowDimensions()

  useEffect(() => {
    dispatch(setHeader('QUEST'))
    dispatch(setHeaderTitle('Add Quest'))
    dispatch(unSetQuest())
    dispatch(setAction('NEW'))
  }, [dispatch])

  return (
    <div
      className="px-[32px] py-[38px] overflow-auto"
      style={{
        height: height - 80,
      }}
    >
      <div className="w-[730px]">
        <QuestNavigation />
      </div>
      <Outlet />
    </div>
  )
}

export default QuestNew
