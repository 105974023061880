import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import NotFound from './components/NotFound'
import SignIn from './pages/SignIn'
import SignUp from 'pages/SignUp'
import Home from 'pages/Home'
import ForgotPassword from 'pages/ForgotPassword'
import Confirmation from 'components/Confirmation'
import ResetSuccess from 'components/ResetSuccess'
import ResetPassword from 'pages/ResetPassword'
import ProtectedRoutes from 'components/ProtectedRoutes'
import QuestNew from 'pages/quest/QuestNew'
import QuestPopular from 'components/quest/PopularQuest'
import QuestList from 'components/quest/ListQuest'
import VerifyEmail from 'pages/VerifyEmail'
import ChangePasswordEmail from 'pages/ChangePasswordEmail'
import QuestForm from 'components/quest/QuestForm'
import IntroForm from 'components/quest/IntroForm'
import PlaceAndTaskForm from 'components/quest/PlaceAndTaskForm'
import ConclusionForm from 'components/quest/ConclusionForm'
import OverviewForm from 'components/quest/OverviewForm'
import QuestDetail from 'pages/quest/QuestDetail'
import QuestEdit from 'pages/quest/QuestEdit'
import QuestInfo from 'pages/quest/QuestDetail/Info'
import QuestPlaces from 'pages/quest/QuestDetail/Places'
import QuestUsers from 'pages/quest/QuestDetail/Users'
import QuestReview from 'pages/quest/QuestDetail/Review'
import QuestAnalytics from 'pages/quest/QuestDetail/Analytics'
import TaskDetail from 'pages/quest/TaskDetail'
import ChangePassword from 'pages/setting/ChangePassword'
import Setting from 'pages/setting/Setting'
import ErrorPage from 'components/ErrorPage'
import SignUpTerms from 'pages/SignUpTerms'

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="signup" element={<SignUp />} />
          <Route path="signin" element={<SignIn />} />
          <Route path="verify-email" element={<VerifyEmail />} />
          <Route path="check-email" element={<ChangePasswordEmail />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="creators/confirmation" element={<Confirmation />} />
          <Route path="reset-success" element={<ResetSuccess />} />
          <Route path="creators/password/edit" element={<ResetPassword />} />
          <Route path="terms-and-conditions" element={<SignUpTerms />} />
          <Route element={<ProtectedRoutes />}>
            <Route exact path="/" element={<Home />}>
              <Route index element={<QuestPopular />} />
            </Route>

            <Route exact path="quests/" element={<Home />}>
              <Route index element={<QuestPopular />} />
              <Route path="list" element={<QuestList />} />
              <Route path="popular" element={<QuestPopular />} />
            </Route>

            <Route exact path="setting" element={<Setting />}></Route>
            <Route
              path="setting/change-password"
              element={<ChangePassword />}
            />

            <Route exact path="quests/new" element={<QuestNew />}>
              <Route index element={<QuestForm />} />
              <Route path="intro" element={<IntroForm />} />
              <Route path="place" element={<PlaceAndTaskForm />} />
              <Route path="conclusion" element={<ConclusionForm />} />
              <Route path="overview" element={<OverviewForm />} />
            </Route>

            <Route exact path="quests/:id/edit" element={<QuestEdit />}>
              <Route index element={<QuestForm />} />
              <Route path="intro" element={<IntroForm />} />
              <Route path="place" element={<PlaceAndTaskForm />} />
              <Route path="conclusion" element={<ConclusionForm />} />
              <Route path="overview" element={<OverviewForm />} />
            </Route>

            <Route exact path="quests/:id" element={<QuestDetail />}>
              <Route index element={<QuestInfo />} />
              <Route path="places" element={<QuestPlaces />} />
              <Route path="users" element={<QuestUsers />} />
              <Route path="reviews" element={<QuestReview />} />
              <Route path="analytics" element={<QuestAnalytics />} />
            </Route>
            <Route
              path="/quests/:questId/:taskParentType/:taskParentId/tasks/:id"
              element={<TaskDetail />}
            />
          </Route>
          <Route path="error" element={<ErrorPage />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App
