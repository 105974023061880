import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { RiArrowUpDownFill } from 'react-icons/ri'
import { FaPlus } from 'react-icons/fa'
import ListPlace from './ListPlace'
import { showModal } from 'features/modalSlice'
import SaveNotice from 'components/SaveNotice'
import ErrorNotice from 'components/ErrorNotice'
import { useListPlacesQuery } from 'services/questApi'
import { useDeletePlaceMutation } from 'services/placeApi'
import MobilePlaces from './mobilePreview/MobilePlaces'

const PlaceAndTaskForm = () => {
  const dispatch = useDispatch()
  const { quest: data } = useSelector((state) => state.quest)
  const { data: places } = useListPlacesQuery(data?.id)
  const [deletePlace] = useDeletePlaceMutation()
  const [showPlaceSequence, setShowShowPlaceSequence] = useState(false)
  const [saveNotice, setSaveNotice] = useState(false)
  const [isNext, setIsNext] = useState(false)
  const [errorToast, setErrorToast] = useState(false)
  const [taskError, setTaskError] = useState(false)
  const [isError, setIsError] = useState([])
  const [isErrorSubplace, setIsErrorSubplace] = useState([])
  const [placesCount, setPlacesCount] = useState(0)
  const haveSubplaces = places?.map((option) => option.have_subplaces)
  const placesTaskCount = places?.map((option) => option.tasks_count)
  const hiddenLength = places?.filter((option) => option.is_hidden).length
  const hiddenId = parseInt(
    places
      ?.filter((option) => option.is_hidden)
      ?.map((item) => item.id)
      .slice(-1)
  )
  const listNoTask = places?.filter(
    (option) => option.tasks_count === 0 && option.have_subplaces === false
  )
  const indexPlaceNoTask = listNoTask?.map((option) => option.id.toString())
  const indexPlaceNoTaskLength = indexPlaceNoTask?.length
  const listSubplace = places?.filter(
    (option) => option.have_subplaces === true
  )
  const listSubplaceNoTask = places?.filter(
    (option) => option.tasks_count === 0
  )
  const indexSubplaceNoTask = listSubplaceNoTask?.map((option) =>
    option.id.toString()
  )
  const listNestedSubplace = listSubplace
    ?.map((option) =>
      option?.subplaces
        ?.filter((sub) => sub.tasks_count === 0)
        ?.map((item) => item?.tasks_count.toString())
        ?.includes('0')
        ? option.id.toString()
        : null
    )
    ?.flat()
    ?.filter((item) => item)
  const listNestedSubplaceLength = listNestedSubplace?.length

  const onAddPlaceClick = () => {
    if (data) {
      dispatch(showModal({ component: 'PlaceFormModal' }))
    }
  }

  const onEditSequenceClick = () => {
    if (data) {
      dispatch(showModal({ component: 'PlaceSequenceModal' }))
    }
  }

  const onHiddenDuplicate = async () => {
    const id = hiddenId
    try {
      await deletePlace(id).unwrap()
    } catch ({ data }) {
      console.log(data)
    }
  }

  useEffect(() => {
    if (data && haveSubplaces?.includes(true) !== true) {
      if (data.places_count === 0) {
        setIsNext(false)
        setIsError(false)
      }

      if (data.tasks_count > 0 && placesTaskCount?.includes(0) === false) {
        setIsNext(true)
        setIsError(false)
        setSaveNotice(true)
        setTimeout(() => {
          setSaveNotice(false)
        }, 1000)
      }

      if (data.places_count > placesCount) {
        setPlacesCount(data.places_count)
        setSaveNotice(true)
        setTimeout(() => {
          setSaveNotice(false)
        }, 1000)
      }

      if (data.places_count > 1) {
        setShowShowPlaceSequence(true)
      }

      if (placesTaskCount?.includes(0) === false) {
        setIsError(false)
      }
    }
  }, [data, placesCount, isError])

  useEffect(() => {
    if (places?.length !== 0) {
      if (listNoTask?.length === 0) {
        setIsError('null')
      }
      if (
        listNestedSubplace?.length === 0 &&
        indexSubplaceNoTask?.length === 0
      ) {
        setIsErrorSubplace('null')
      }

      if (
        listNoTask?.length === 0 &&
        listNestedSubplace?.length === 0 &&
        listSubplaceNoTask?.length === 0
      ) {
        setIsNext(true)
      } else {
        setIsNext(false)
      }
    }
    if (places?.length === 0) {
      setIsNext(false)
      setTaskError(false)
    }
  }, [places, placesTaskCount])

  useEffect(() => {
    if (errorToast) {
      if (placesTaskCount?.includes(0) === true) {
        setIsNext(false)
        setIsError(indexPlaceNoTask)
        setTaskError(true)
      }

      setTimeout(() => {
        if (indexSubplaceNoTask?.length !== 0) {
          setIsNext(false)
          setIsErrorSubplace([listNestedSubplace, indexSubplaceNoTask].flat())
          setTaskError(true)
        }
      }, 100)

      if (listNestedSubplace?.length !== 0) {
        setIsNext(false)
        setIsErrorSubplace(listNestedSubplace)
        setTaskError(true)
      }

      setTimeout(() => {
        setErrorToast(false)
      }, 2000)
    }
  }, [errorToast])

  useEffect(() => {
    if (taskError) {
      if (indexPlaceNoTaskLength) {
        setIsError(indexPlaceNoTask)
      }
      if (listNestedSubplaceLength !== -1) {
        setIsErrorSubplace([listNestedSubplace, indexSubplaceNoTask].flat())
      }
    }
  }, [indexPlaceNoTaskLength, taskError, listNestedSubplaceLength])

  useEffect(() => {
    if (hiddenLength > 1) {
      onHiddenDuplicate()
    }
  }, [hiddenLength])

  useEffect(() => {
    setSaveNotice(true)
    setTimeout(() => {
      setSaveNotice(false)
    }, 1000)
  }, [])

  return (
    <>
      {errorToast && (
        <ErrorNotice
          content={
            data.places_count === 0 ? (
              <>Required to add at least one place</>
            ) : (
              <>Please check fields marked in red</>
            )
          }
        />
      )}
      {saveNotice && <SaveNotice />}
      <div className="flex justify-between pr-[40px]">
        <div className="w-9/12 mr-6 relative">
          <div className="flex items-center justify-between mb-[40px]">
            <div className="flex items-center text-[24px] leading-[130%] text-[#262626] font-semibold">
              <span className="flex items-center justify-center text-center mr-2 bg-[#FFCF50] w-[30px] h-[30px] rounded-full text-[16px] leading-[24px] text-[#262626]">
                3
              </span>
              Places & Tasks
            </div>
            <div className="flex items-center justify-between">
              <a
                href="#0"
                onClick={onAddPlaceClick}
                className="flex items-center justify-center text-white hover:text-white font-semibold py-[13px] px-[22px] bg-[#2C7D92] rounded-[50px]"
              >
                <FaPlus />
                &nbsp; Add Place
              </a>
              {showPlaceSequence && (
                <a
                  href="#0"
                  onClick={onEditSequenceClick}
                  className="ml-4 flex items-center justify-center text-[#2C7D92] hover:text-[#2C7D92] border-[1px] border-[#2C7D92] font-semibold py-[13px] px-[22px] bg-white rounded-[50px]"
                >
                  <RiArrowUpDownFill className="text-[20px]" />
                  &nbsp;Edit Sequence
                </a>
              )}
            </div>
          </div>

          {data && (
            <ListPlace
              id={data?.id}
              editable={true}
              errorPlaces={isError}
              errorSubplaces={isErrorSubplace}
            />
          )}

          <div className="flex items-center justify-between">
            <Link
              to={`/quests/${data?.slug}/edit/intro`}
              className="flex items-center justify-center bg-white rounded-[50px] text-[16px] leading-[24px] text-[#2C7D92] hover:text-[#2C7D92] border-[1px] border-[#2C7D92] font-semibold w-[160px] py-[13px]"
            >
              Previous
            </Link>
            {isNext ? (
              <Link
                to={`/quests/${data?.slug}/edit/conclusion`}
                className="flex items-center justify-center bg-[#2C7D92] rounded-[50px] text-[16px] leading-[24px] text-white hover:text-white font-semibold w-[160px] py-[13px]"
              >
                Next
              </Link>
            ) : (
              <div
                onClick={() => setTaskError(true)}
                className="flex items-center justify-center bg-[#2C7D92] rounded-[50px] text-[16px] leading-[24px] text-white hover:text-white font-semibold w-[160px] py-[13px]"
              >
                <button
                  className="w-full focus:outline-none"
                  onClick={() => setErrorToast(true)}
                >
                  Next
                </button>
              </div>
            )}
          </div>
        </div>
        <MobilePlaces />
      </div>
    </>
  )
}

export default PlaceAndTaskForm
