import React, { useState, useEffect } from 'react'
import { DeviceFrameset } from 'react-device-frameset'
import 'react-device-frameset/styles/marvel-devices.min.css'
import { useListPlacesQuery } from 'services/questApi'
import { useGetTaskQuery } from 'services/taskApi'
import { useSelector } from 'react-redux'
import {
  MdAccessTimeFilled,
  MdSubdirectoryArrowLeft,
  MdQrCode,
  MdOutlineSplitscreen,
} from 'react-icons/md'
import { IoIosArrowBack, IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'
import { IoIosArrowDropdownCircle } from 'react-icons/io'
import { CgArrowsHAlt } from 'react-icons/cg'
import { FiCompass, FiMapPin, FiBox, FiSearch } from 'react-icons/fi'
import { BsPatchQuestion } from 'react-icons/bs'
import { BiInfoCircle, BiRun, BiBarChartAlt2 } from 'react-icons/bi'
import { FaMapMarker, FaMapMarkerAlt } from 'react-icons/fa'
import { AiOutlineCamera } from 'react-icons/ai'
import { HiOutlineViewGrid } from 'react-icons/hi'
import bag from 'assets/images/mobile/bag.svg'
import loadable from '@loadable/component'

const TaskViewer = loadable((props) => import(`./taskForm/${props.taskType}`), {
  cacheKey: (props) => props.taskType,
})

const taskAction = {
  action: <BiRun className="mr-1" />,
  check_in: <FiMapPin className="mr-1" />,
  short_answer: <BsPatchQuestion className="mr-1" />,
  information: <BiInfoCircle className="mr-1" />,
  polling: <BiBarChartAlt2 className="mr-1 rotate-90" />,
  photo: <AiOutlineCamera className="mr-1" />,
  guess_the_difference: <FiSearch className="mr-1" />,
  scan_qr: <MdQrCode className="mr-1" />,
  multiple_choice: <MdOutlineSplitscreen className="mr-1" />,
  augmented_reality: <FiBox className="mr-1" />,
  multiple_choice_image: <HiOutlineViewGrid className="mr-1" />,
}

const MobilePlaces = () => {
  const { quest: data } = useSelector((state) => state.quest)
  const { data: places } = useListPlacesQuery(data?.id)
  const [taskId, setTaskId] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  const [phoneShow, setPhoneShow] = useState(true)
  const [listTasks, setListTasks] = useState([])
  const { data: tasks } = useGetTaskQuery(taskId, {
    skip: !taskId,
  })

  const getlistTasks = places
    ?.map((item) => ({
      title: item.title,
      tasks: item.tasks
        ?.map((task) => ({
          id: task.id,
          task_type: task.task_type,
          title: task.title,
        }))
        .flat(),
    }))
    .filter((item) => item.tasks.length !== 0)

  const getListTasksSubplaces = places
    ?.filter((item) => item.have_subplaces === true)
    ?.map((item) => ({
      title: item.title,
      tasks: item.subplaces?.map((sub) => sub.tasks).flat(),
    }))

  const components = {
    action: 'MobileActionForm',
    check_in: 'MobileCheckInForm',
    short_answer: 'MobileShortAnswerForm',
    information: 'MobileInformationForm',
    polling: 'MobilePollingForm',
    photo: 'MobilePhotoForm',
    guess_the_difference: 'MobileSpotItForm',
    scan_qr: 'MobileScanQRForm',
    multiple_choice: 'MobileMultipleChoiceForm',
    augmented_reality: 'MobileAugmentedRealityForm',
    multiple_choice_image: 'MobileMultipleChoiceImageForm',
    numpad: 'MobileNumpadForm',
    symbol_pad: 'MobileSymbolPadForm',
  }

  const indexListTasks = listTasks
    ?.map((item) => item.tasks)
    .flat()
    ?.findIndex((obj) => obj['id'] === taskId)

  const listAllTasks = listTasks?.map((item) => item.tasks).flat()
  const getlistTasksLength = getlistTasks?.length
  const getListTasksSubplacesLength = getListTasksSubplaces?.length

  const onOptionClicked = (value) => {
    setTaskId(value)
    setIsOpen(false)
    setPhoneShow(true)
  }

  const onToogleClicked = () => {
    setIsOpen(!isOpen)
    setPhoneShow(!phoneShow)
  }

  useEffect(() => {
    if (data) {
      if (getlistTasksLength) {
        setListTasks(getlistTasks)
      }
      if (getListTasksSubplacesLength) {
        setListTasks([...getlistTasks, ...getListTasksSubplaces])
      }
    }
  }, [data, getlistTasksLength, getListTasksSubplacesLength])

  return (
    <>
      <div className="w-3/12 -mt-[100px] sticky top-0 h-[400px]">
        <div className="-ml-16 relative -mt-[80px]">
          <div className="absolute ml-[90px] mt-[90px] ">
            <div
              onClick={() => onToogleClicked()}
              className="flex items-center justify-between cursor-pointer border-[1px] w-[244px] py-[11px] border-questeon rounded-[12px] px-[17px] text-[14px] leading-[21px] text-[#262626]"
            >
              <div className="flex items-center">
                {taskAction[listAllTasks?.[indexListTasks]?.task_type] || (
                  <FaMapMarkerAlt className="mr-1" />
                )}
                {listAllTasks?.[indexListTasks]?.title || 'All Places'}
              </div>
              {isOpen ? (
                <IoIosArrowUp className="text-xl" />
              ) : (
                <IoIosArrowDown className="text-xl" />
              )}
            </div>
            {isOpen && (
              <div
                className={
                  listAllTasks.length > 5
                    ? 'scroolsub mt-2 px-[17px] py-[8px] h-[300px] border-[1px] border-questeon rounded-[8px] overflow-y-auto'
                    : 'scroolsub mt-2 px-[17px] py-[8px] border-[1px] border-questeon rounded-[8px] overflow-y-auto'
                }
              >
                <div
                  onClick={() => onOptionClicked(null)}
                  className="flex items-center justify-start py-2 text-[14px] leading-[21px] pl-2 hover:text-questeon hover:bg-questeon/10 rounded-[8px] cursor-pointer"
                >
                  <FaMapMarkerAlt className="mr-1" />
                  All Places
                </div>
                {listTasks?.map((item, index) => (
                  <div key={index} className="">
                    <div className="flex items-center justify-start text-[14px] leading-[21px] text-[#262626] font-semibold ">
                      <FaMapMarkerAlt className="mr-1" />
                      {item.title}
                    </div>
                    {item.tasks?.map((task, index) => (
                      <div
                        key={index}
                        onClick={() => onOptionClicked(task.id)}
                        className="flex items-center py-2 text-[14px] hover:text-questeon leading-[21px] text-[#262626] hover:bg-questeon/10 pl-2 rounded-[8px] cursor-pointer"
                      >
                        {taskAction[task.task_type]} {task.title}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            )}
          </div>
          {phoneShow && (
            <DeviceFrameset device="iPhone X" zoom={0.6} potrait="true">
              {taskId ? (
                <div className="h-[800px] overflow-auto">
                  <>
                    <TaskViewer
                      taskType={
                        components[listAllTasks?.[indexListTasks]?.task_type]
                      }
                      data={tasks}
                    />
                  </>
                </div>
              ) : (
                <div className="h-[800px] overflow-auto">
                  <div className="mt-[30px] p-[17px] flex items-center justify-between">
                    <div>
                      <IoIosArrowBack className="text-black text-2xl" />
                    </div>
                    <div className="flex items-center">
                      <div className="text-[16px] leading-[150%] text-[#5A5A60]">
                        20h : 54m : 43s
                      </div>
                      <div className="mx-2">
                        <MdAccessTimeFilled className="text-[#FFCF50] text-2xl" />
                      </div>
                      <div className="flex items-center justify-center bg-[#5A5A60]/10 rounded-[30px] w-[60px] py-[8px]">
                        <div>
                          <img src={bag} alt="" className="mr-2" />
                        </div>
                        <div className="text-[12px] leading-[150%] text-black">
                          0
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center justify-between mt-[24px] border-b-[1px] text-[16px] leading-[150%]">
                    <div className="flex items-center justify-center border-b-2 border-[#FFCF50] w-full pb-[10px] text-[#2C7D92] font-semibold">
                      Places
                    </div>
                    <div className="flex items-center justify-center w-full pb-[10px] text-[#C2C2C2]">
                      Map
                    </div>
                  </div>
                  <div className="flex items-center justify-between mt-[24px] text-[16px] leading-[150%] px-[17px]">
                    <div className="flex items-center justify-start w-full pb-[10px] font-semibold ">
                      {places?.length} Places
                    </div>
                    <div className="flex items-center justify-end w-full pb-[10px]">
                      <CgArrowsHAlt className="text-[#FFCF50] text-2xl" />
                      <div className="mx-2">Distance</div>
                      <IoIosArrowDropdownCircle className="text-[#2C7D92] text-2xl" />
                    </div>
                  </div>
                  <div className="flex items-start justify-center mt-[24px] text-[16px] leading-[150%] px-[17px]">
                    <div className="w-full">
                      {places?.length !== 0 ? (
                        places?.map((item, index) => (
                          <div
                            key={item.id}
                            className="w-full bg-[#F7F8FA] rounded-[24px] mb-[24px]"
                          >
                            <div>
                              <img
                                src={item.image_url}
                                alt=""
                                className="w-full rounded-t-[24px]"
                              />
                            </div>
                            <div className="w-full bg-[#F7F8FA] rounded-b-[24px] p-[16px]">
                              <div className="flex items-start">
                                <div className="mr-3 relative flex items-start justify-start">
                                  <div>
                                    <div className="flex items-center justify-center w-[40px] absolute mt-1">
                                      <div className="flex items-center justify-center w-[20px] h-[20px] rounded-full bg-white text-[#FFCF50]">
                                        {index + 1}
                                      </div>
                                    </div>
                                    <div className="flex items-center justify-center w-[40px]">
                                      <FaMapMarker className="text-[#FFCF50] text-[40px]" />
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <p className="text-black font-semibold text-[18px]">
                                    {item.title}
                                  </p>
                                  <p className="text-[14px] leading-[150%] text-[#7D7D7D] mt-1">
                                    1,224 km
                                  </p>
                                </div>
                              </div>
                              <div className="flex items-start justify-between mt-[24px]">
                                <div className="flex items-center justify-center font-semibold text-questeon w-[145px] rounded-[32px] py-[12px] border-[1px] border-questeon bg-white">
                                  <MdSubdirectoryArrowLeft className="mr-2 text-questeon rotate-180 text-xl" />
                                  Direction
                                </div>
                                <div className="flex items-center justify-center w-[145px] rounded-[32px] py-[12px] bg-questeon font-semibold text-white">
                                  <FiCompass className="mr-2 text-white text-xl" />
                                  Explore
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="flex items-center justify-start mt-[17px] border-[1px] rounded-[12px] py-[12px] px-[14px]">
                          <div>
                            <div className="text-[16px] leading-[130%] text-[#7D7D7D] font-bold inline-block">
                              Place
                            </div>
                            <div className="text-[14px] leading-[150%] text-[#7D7D7D] mt-2">
                              Places list goes here ...
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </DeviceFrameset>
          )}
        </div>
      </div>
    </>
  )
}

export default MobilePlaces
