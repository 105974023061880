import React from 'react'
import { useSelector } from 'react-redux'
import HTMLView from 'components/HTMLView'
import places from 'assets/images/questinfo/places.svg'
import route from 'assets/images/questinfo/route.svg'
import task from 'assets/images/questinfo/task.svg'
import age from 'assets/images/questinfo/age.svg'
import duration from 'assets/images/questinfo/duration.svg'

const Info = () => {
  const { quest } = useSelector((state) => state.quest)

  const optionAge = {
    child: '0-12 years',
    teen: '13+ years',
    adult: '16+ years',
  }
  return (
    <>
      {quest && (
        <>
          <div className="text-[14px] leading-[150%] text-[#5A5A60]">
            <HTMLView content={quest.description} />
          </div>
          <div className="flex items-start my-[24px] text-[#5A5A60]">
            <ul className="flex flex-wrap items-center justify-start">
              {quest?.quest_categories?.map((item) => (
                <li
                  key={item.id}
                  className="text-center bg-[#F7F8FA] text-[14px] leading-[150%] px-[20px] py-[9px] rounded-[40px] border-[1px] border-[#EDEDED] mr-[12px] mb-2"
                >
                  {item.name}
                </li>
              ))}
            </ul>
          </div>
          <div className="flex items-start text-[14px] leading-[150%] text-[#7D7D7D]">
            <div>
              <div className="h-[30px]">
                <img src={places} alt="" className="h-[20px]" />
              </div>
              <p className="py-[4px]">Check Point</p>
              <h4 className="text-[24px] leading-[130%] text-[#262626] font-semibold">
                {quest.places_count}
              </h4>
            </div>
            <div className="mx-20">
              <div className="h-[30px]">
                <img src={task} alt="" className="h-[20px]" />
              </div>
              <p className="py-[4px]">Task</p>
              <h4 className="text-[24px] leading-[130%] text-[#262626] font-semibold">
                {quest.tasks_count}
              </h4>
            </div>
            <div>
              <div className="h-[30px]">
                <img src={route} alt="" className="h-[20px]" />
              </div>
              <p className="py-[4px]">Route</p>
              <h4 className="text-[24px] leading-[130%] text-[#262626] font-semibold">
                {quest.route} <sub>km</sub>
              </h4>
            </div>
            <div className="ml-20">
              <div className="h-[30px]">
                <img src={duration} alt="" className="h-[25px]" />
              </div>
              <p className="py-[4px]">Duration</p>
              <h4 className="text-[24px] leading-[130%] text-[#262626] font-semibold">
                {quest.duration}
              </h4>
            </div>
            <div className="ml-20">
              <div className="h-[30px]">
                <img src={age} alt="" className="h-[25px]" />
              </div>
              <p className="py-[4px]">Suggested Age</p>
              <p className="text-[24px] leading-[130%] text-[#262626] font-semibold">
                {quest.age?.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                  letter.toUpperCase()
                ) || 'All ages'}
              </p>
              <p className="pt-[4px] text-[14px] leading-[150%] text-[#262626]">
                {optionAge[quest.age]}
              </p>
            </div>
          </div>
          <div className="py-[24px]">
            <h4 className="text-[18px] leading-[150%] text-[#262626] font-semibold">
              Reward
            </h4>
            <div className="flex items-center w-[323px] bg-[#F7F8FA] rounded-[24px] py-[18px] px-[20px]">
              <div className="mr-2">
                <img
                  src={quest.badge?.image?.url}
                  alt=""
                  className="w-[64px] h-[64px] rounded-full object-cover"
                />
              </div>
              <div className="text-[16px] leading-[24px] text-[#262626] font-semibold">
                {quest.badge?.title}
              </div>
            </div>
          </div>
          <div className="pb-[24px] border-b-[1px]">
            <h4 className="text-[18px] leading-[150%] text-[#262626] font-semibold">
              Highlights
            </h4>
            <div>
              <ul className="list-disc list-inside">
                {quest.highlights.map((highlight, index) => (
                  <li key={`highlight-${index}`} className="text-[#FFCF50]">
                    <span className="text-[#5A5A60] text-[14px] leading-[150%]">
                      {highlight}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="py-[24px] border-b-[1px]">
            <h4 className="text-[24px] leading-[150%] text-[#262626] font-semibold">
              Intro
            </h4>
            <div className="flex w-full">
              <div className="h-[300px] w-[300px] mr-[46px]">
                {quest.intro_image_url && (
                  <div className="h-[300px] w-[300px] mr-[46px]">
                    <img
                      src={quest.intro_image_url}
                      alt=""
                      className="w-full h-full object-fit object-center rounded-[24px]"
                    />
                  </div>
                )}
              </div>
              <div className="text-[#5A5A60] text-[14px] leading-[150%] overflow-auto">
                <HTMLView content={quest.intro} />
                {quest.quest_intros.map((intro) => (
                  <div key={intro.id} className="mt-[24px]">
                    <p className="text-[18px] leading-[150%] text-[#262626] font-semibold mb-1">
                      {intro.title}
                    </p>
                    <HTMLView content={intro.description} />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="py-[24px]">
            <h4 className="text-[24px] leading-[150%] text-[#262626] font-semibold">
              Conclusion
            </h4>
            <div className="text-[#5A5A60] text-[14px] leading-[150%]">
              <HTMLView content={quest.conclusion?.description} />
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default Info
