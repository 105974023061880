import React, { useMemo } from 'react'
import { Modal as ModalBootstrap } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import loadable from '@loadable/component'
import Skeleton from 'react-loading-skeleton'
import { useCloseModal } from 'utils/questModal'
import ModalErrorBoundary from './error/ModalErrorBoundary'

const Modal = () => {
  const { show, component, props } = useSelector((state) => state.modal)
  const closeModal = useCloseModal()

  const Component = useMemo(() => {
    const SelectedComponent = loadable(
      () => import(`./quest/modalForm/${component}`),
      {
        fallback: <EmptyModal />,
      }
    )
    if (SelectedComponent) {
      return <SelectedComponent {...props} />
    }
  }, [props, component])

  return (
    <div>
      <ModalBootstrap show={show} onHide={closeModal}>
        <ModalBootstrap.Body>
          <ModalErrorBoundary>
            <div className="z-30 fixed top-0 left-0 right-0 bottom-0 bg-[rgba(0,0,0,0.5)] flex items-center justify-center overflow-auto">
              {Component}
            </div>
          </ModalErrorBoundary>
        </ModalBootstrap.Body>
      </ModalBootstrap>
    </div>
  )
}

export const EmptyModal = ({ message }) => {
  const closeModal = useCloseModal()
  return (
    <div className="">
      <div className="">{!message && <Skeleton width={150} />}</div>
      <div className="">
        {message ? (
          <h3>{message}</h3>
        ) : (
          <div>
            <div className="">
              {Array.from({ length: 10 }).map((_, index) => (
                <Skeleton key={`skleton-modal-${index}`} />
              ))}
            </div>
          </div>
        )}
        <div className="">
          <a href="#0" onClick={closeModal} className="">
            Cancel
          </a>
        </div>
      </div>
    </div>
  )
}

export default Modal
