import React, { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'
import AuthNotice from 'components/AuthNotice'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { useResetPasswordMutation } from 'services/authApi'
import QuesteonLogo from 'assets/images/questeon_logo.svg'

const ResetPassword = () => {
  const [searchParams] = useSearchParams()
  const resetPasswordToken = searchParams.get('reset_password_token')
  const [resetPassword] = useResetPasswordMutation()
  const navigate = useNavigate()
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [error, setError] = useState(null)
  const onSubmit = async (newPasswordData) => {
    try {
      const result = await resetPassword({
        ...newPasswordData,
        reset_password_token: resetPasswordToken,
      }).unwrap()
      if (result) {
        navigate('/reset-success')
      }
    } catch ({ data }) {
      setError(data?.errors)
    }
  }
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm()
  const password = useRef({})
  password.current = watch('password', '')
  const handleShowPassword = () => {
    setShowPassword(!showPassword)
    setShowConfirmPassword(!showConfirmPassword)
  }

  return (
    <div className="bg-[#F7F8FA] flex justify-center items-center h-screen">
      {error && <AuthNotice type="error" message={error} />}
      <div>
        <div className="bg-white w-[440px] px-[45px] py-[60px] rounded-[40px] border-1 border-[#EDEDED]">
          <img src={QuesteonLogo} className="h-[40px]" />
          <div className="my-[40px]">
            <h3 className="text-[24px] leading-[120%] text-[#262626] text-left font-semibold">
              Create new password
            </h3>
          </div>
          <div className="">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="text-[14px] text-[#5A5A60] leading-[21px]">
                <label>Password</label>
                <div className="flex items-center justify-between border-[1px] px-[24px] py-[12px] rounded-[50px] mt-2">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    className="w-full"
                    placeholder="Enter new password"
                    {...register('password', {
                      required: 'Password is required.',
                    })}
                  />
                  <a href="#0" className="" onClick={handleShowPassword}>
                    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                  </a>
                  {errors.password && (
                    <div className="invalid-feedback">
                      {errors.password.message}
                    </div>
                  )}
                </div>
              </div>
              <div className="text-[14px] text-[#5A5A60] leading-[21px] mt-6">
                <label>Confirm Password</label>
                <div className="flex items-center justify-between border-[1px] px-[24px] py-[12px] rounded-[50px] mt-2">
                  <input
                    type={showConfirmPassword ? 'text' : 'password'}
                    // className={`form-control ${
                    //   errors.password_confirmation ? 'is-invalid' : ''
                    // }`}
                    className="w-full"
                    placeholder="Confirm new password"
                    {...register('password_confirmation', {
                      required: 'Confirm Password is required.',
                      validate: (value) =>
                        value === password.current ||
                        "Confirm Password doesn't match Password",
                    })}
                  />
                  <a
                    href="#0"
                    className={`cc fa fa-fw field-icon toggle-password
                          ${errors.password_confirmation && 'is-invalid'}
                        `}
                    onClick={handleShowPassword}
                  >
                    <FontAwesomeIcon
                      icon={showConfirmPassword ? faEyeSlash : faEye}
                    />
                  </a>
                  {errors.password_confirmation && (
                    <div className="invalid-feedback">
                      {errors.password_confirmation.message}
                    </div>
                  )}
                </div>
              </div>
              <div className="mt-[40px] flex items-center justify-center">
                <button
                  type="submit"
                  className="flex items-center justify-center bg-[#2C7D92] rounded-[50px] text-[16px] leading-[24px] text-white font-semibold w-full py-[13px]"
                >
                  Reset Password
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResetPassword
