import React from 'react'

const StatusLabel = ({ questStatus }) => {
  if (questStatus === 'draft') {
    return (
      <div className="text-[12px] leading-[16px] text-[#262626] border-1 border-[#7D7D7D] bg-[#262626]/10 py-[4px] px-[16px] rounded-[8px]">
        Draft
      </div>
    )
  }

  if (questStatus === 'review') {
    return (
      <div className="text-[12px] leading-[16px] text-[#7D7D7D] border-1 border-[#C2C2C2] bg-[#EDEDED] py-[4px] px-[16px] rounded-[8px]">
        On Review
      </div>
    )
  }

  if (questStatus === 'rejected') {
    return (
      <div className="text-[12px] leading-[16px] text-[#F15A5B] border-1 border-[#F15A5B] bg-[#f15a5b]/10 py-[4px] px-[16px] rounded-[8px]">
        Rejected
      </div>
    )
  }

  if (questStatus === 'ready') {
    return (
      <div className="text-[12px] leading-[16px] text-[#394DA0] border-1 border-[#394DA0] bg-[#394da0]/10 py-[4px] px-[16px] rounded-[8px]">
        Ready
      </div>
    )
  }

  if (questStatus === 'publish') {
    return (
      <div className="text-[12px] leading-[16px] text-[#2FAE55] border-1 border-[#2FAE55] bg-[#2fae55]/10 py-[4px] px-[16px] rounded-[8px]">
        Publish
      </div>
    )
  }

  return <></>
}

export default StatusLabel
