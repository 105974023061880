import React, { useMemo, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useGetQuestStepQuery } from 'services/questApi'
import { setCurrentNavigation } from 'features/questSlice'

const QuestNavigation = () => {
  const dispatch = useDispatch()
  const { quest, currentAction } = useSelector((state) => state.quest)
  const { data } = useGetQuestStepQuery(quest?.id, { skip: !quest?.id })
  const routePath = useLocation()
  const currentNavigation = routePath.pathname.split('/').slice(-1).toString()

  const steps = useMemo(() => {
    return ['quest_info', 'intro', 'place_and_task', 'conclusion', 'overview']
  }, [])

  const currentStep = useMemo(() => {
    if (data) {
      return steps.findIndex((step) => step === data.step)
    }
    return -1
  }, [data, steps])

  const navigationActive = (navigationStep) => {
    const indexStep = steps.findIndex((step) => step === navigationStep)
    return currentStep + 1 >= indexStep
  }

  const navigationChecked = (navigationStep) => {
    if (!data) {
      return false
    }
    const indexStep = steps.findIndex((step) => step === navigationStep)
    return currentStep >= indexStep
  }

  let basePath
  switch (currentAction) {
    case 'EDIT':
      basePath = `/quests/${quest?.slug}/edit`
      break
    default:
      basePath = '/quests/new'
  }
  const navigators = [
    {
      name: 'Quest Info',
      active: true,
      path: basePath,
      checkMark: true,
      checked: navigationChecked('quest_info'),
      param: ['edit', 'new'],
    },
    {
      name: 'Intro',
      active: navigationActive('intro'),
      path: `${basePath}/intro`,
      checkMark: true,
      checked: navigationChecked('intro'),
      param: ['intro'],
    },
    {
      name: 'Places',
      active: navigationActive('place_and_task'),
      path: `${basePath}/place`,
      checkMark: true,
      checked: navigationChecked('place_and_task'),
      param: ['place'],
    },
    {
      name: 'Conclusion',
      active: navigationActive('conclusion'),
      path: `${basePath}/conclusion`,
      checkMark: true,
      checked: navigationChecked('conclusion'),
      param: ['conclusion'],
    },
    {
      name: 'Overview',
      active: navigationActive('overview'),
      path: `${basePath}/overview`,
      checkMark: false,
      checked: navigationChecked('overview'),
      param: ['overview'],
    },
  ]

  useEffect(() => {
    if (data) {
      dispatch(setCurrentNavigation(data.step))
    }
  }, [dispatch, data])

  return (
    <div className="bg-[#F7F8FA] text-[14px] text-[#5A5A60] rounded-[16px] h-[56px] mb-[40px] flex items-center justify-between">
      {navigators.map((navigation, index) => (
        <div
          className={
            Array.from(navigation.param)?.includes(currentNavigation)
              ? 'flex items-center justify-start w-[148px] h-[56px] pl-4 bg-[#2c7d92]/10 rounded-[16px]'
              : 'flex items-center justify-start w-[148px] h-[56px] pl-4 rounded-[16px] bg-[#F7F8FA]'
          }
          key={`quest-navigation-${index}`}
        >
          <input type="checkbox" checked={navigation.checked} disabled />
          <div className="flex items-center ">
            <Link
              className="flex items-center"
              to={navigation.active ? navigation.path : '#0'}
            >
              <div
                className={
                  navigation.active
                    ? 'flex items-center justify-center text-[12px] leading-[16px] text-[#262626] font-bold w-[24px] h-[24px] bg-[#FFCF50] mr-2 rounded-full'
                    : 'flex items-center justify-center text-[12px] leading-[16px] text-[#262626] w-[24px] h-[24px] bg-[#C2C2C2] mr-2 rounded-full'
                }
              >
                {index + 1}
              </div>
              <p
                className={
                  navigation.active
                    ? 'font-bold text-[#262626]'
                    : 'font-normal text-[#262626]'
                }
              >
                {navigation.name}
              </p>
            </Link>
          </div>
        </div>
      ))}
    </div>
  )
}

export default QuestNavigation
