import React from 'react'
import { useForm } from 'react-hook-form'
import { useSignInMutation } from 'services/authApi'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import QuesteonLogo from 'assets/images/questeon_logo.svg'
import SigninIcon from 'assets/images/authpage/signin_icon.svg'
import classNames from 'classnames'
import AuthNotice from 'components/AuthNotice'
import { FaCheck } from 'react-icons/fa'

export default function SignIn() {
  const { isAuthenticated, error: errorMessage } = useSelector(
    (state) => state.authentication
  )
  const navigate = useNavigate()
  const [showPassword, setShowPassword] = useState(false)
  const [error, setError] = useState(null)
  const [remember, setRemember] = useState(false)
  const handleRemember = () => {
    setRemember(!remember)
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const [signIn] = useSignInMutation()
  const onSubmit = async (dataSignIn) => {
    try {
      await signIn(dataSignIn).unwrap()
    } catch ({ data }) {
      setError(data?.errors)
      if (data?.data?.type === 'EMAIL_NOT_VERIFIED') {
        navigate('/verify-email', {
          state: { email: dataSignIn.email },
        })
      }
    }
  }
  const handleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(null)
      }, 2000)
    }
  }, [error])

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/')
    }
    if (errorMessage) {
      setError(errorMessage)
    }
  }, [isAuthenticated, navigate, errorMessage])

  return (
    <div className="bg-[#F7F8FA] flex justify-center items-center text-[#262626] h-screen">
      {error && <AuthNotice message={error} />}
      <div className="w-[440px] bg-white py-[60px] px-[50px] border-1 border-[#EDEDED] rounded-[40px]">
        <div>
          <img src={QuesteonLogo} alt="" className="h-[40px]" />
        </div>
        <div className="py-[45px]">
          <h3 className="text-[24px] leading-[120%] text-[#262626] font-semibold">
            ✏️ Creator Dashboard
          </h3>
          <p className="text-[14px] leading-[21px] text-[#5A5A60] pt-[8px]">
            Enter your details to login to your account :
          </p>
        </div>
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="text-[14px] leading-[21px] text-[#262626]">
              <label>Email</label>
              <br />
              <input
                type="email"
                className={
                  errors.email
                    ? 'w-[340px] border-2 rounded-[24px] py-[14px] px-[24px] mt-[8px] mb-[24px] focus:outline-none focus:bg-[#F7F8FA] border-[#F15A5B] text-[#F15A5B]'
                    : 'w-[340px] border-2 border-[#EDEDED] rounded-[24px] py-[14px] px-[24px] text-[#7D7D7D] mt-[8px] mb-[24px] focus:outline-none focus:bg-[#F7F8FA] invalid:border-1 invalid:border-[#F15A5B] invalid:text-[#F15A5B]'
                }
                placeholder="Your email"
                {...register('email', {
                  required: 'Email is required.',
                })}
              />
              {errors.email && (
                <div className="text-[#F15A5B] -mt-[24px] mb-[24px]">
                  {errors.email.message}
                </div>
              )}
            </div>
            <div className="text-[14px] leading-[21px] text-[#262626]">
              <label>Password</label>
              <br />
              <div className="flex items-center justify-between">
                <input
                  type={showPassword ? 'text' : 'password'}
                  className={
                    errors.password
                      ? 'w-[340px] border-2 rounded-[24px] py-[14px] px-[24px] mt-[8px] mb-[24px] focus:outline-none focus:bg-[#F7F8FA] border-[#F15A5B] text-[#F15A5B]'
                      : 'w-[340px] border-2 border-[#EDEDED] rounded-[24px] py-[14px] px-[24px] text-[#7D7D7D] mt-[8px] mb-[24px] focus:outline-none focus:bg-[#F7F8FA]'
                  }
                  placeholder="Your password"
                  {...register('password', {
                    required: 'Password is required.',
                  })}
                />

                <div
                  className="grid w-[40px] items-center -ml-10 -mt-3"
                  onClick={handleShowPassword}
                >
                  <FontAwesomeIcon
                    icon={showPassword ? faEyeSlash : faEye}
                    className="w-[20px] cursor-pointer"
                  />
                </div>
              </div>
              {errors.password && (
                <div className="text-[#F15A5B] -mt-[24px] mb-[24px]">
                  {errors.password.message}
                </div>
              )}
            </div>
            <div className="flex items-center justify-between text-[14px] leading-[21px]">
              <label className="text-[#5A5A60] flex items-center">
                <div onClick={handleRemember}>
                  <div
                    className={classNames(
                      'flex items-center justify-center border-1 border-[#2C7D92] w-[15px] h-[15px] bg-[#2C7D92] cursor-pointer rounded-sm',
                      {
                        '!border-[#EDEDED] !bg-[#EDEDED]': !remember,
                      }
                    )}
                  >
                    {remember && (
                      <FaCheck className="text-white w-[10px] h-[10px]" />
                    )}
                  </div>
                </div>
                <p className="ml-2">Remember me</p>
              </label>
              <Link
                to="/forgot-password"
                className="text-[#2C7D92] font-semibold hover:text-[#2C7D92]"
              >
                Forgot password?
              </Link>
            </div>
            <div className="pt-[24px] text-white font-semibold">
              <button
                type="submit"
                className="flex justify-center items-center w-[340px] text-center bg-[#2C7D92] rounded-[50px] py-[11px] focus:outline-none"
              >
                <img src={SigninIcon} alt="SigninIcon" className="pr-3" />
                Sign In
              </button>
            </div>
            <div className="flex items-center justify-center pt-[24px]">
              <p className="text-[14px] leading-[21px] text-[#5A5A60]">
                Want to be a Creator?{' '}
                <Link
                  to="/signup"
                  className="text-[#2C7D92] font-semibold hover:text-[#2C7D92]"
                >
                  Sign Up
                </Link>{' '}
                here
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
