import React from 'react'
import emptyPlaceListIcon from 'assets/images/icon-empty-place-list.svg'

const EmptyPlaceList = () => {
  return (
    <div className="flex items-center justify-center h-[400px]">
      <div>
        <div className="flex items-center justify-center">
          <img src={emptyPlaceListIcon} alt="" />
        </div>
        <div className="flex items-center justify-center">
          <div>
            <p className="text-center text-[18px] leading-[150%] text-[#5A5A60] font-semibold mt-2">
              No places has been added
            </p>
            <p className="text-center text-[14px] leading-[150%] text-[#5A5A60] font-extralight">
              Add places for user to play
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EmptyPlaceList
