import React from 'react'
import { Link } from 'react-router-dom'
import verifyIcon from 'assets/images/authpage/verify_icon.svg'
import QuesteonLogo from 'assets/images/questeon_logo.svg'

const ChangePasswordEmail = () => {
  return (
    <div className="bg-[#F7F8FA] flex justify-center items-center h-screen">
      <div>
        <div className="bg-white w-[440px] px-[45px] py-[60px] rounded-[40px] border-1 border-[#EDEDED]">
          <img src={QuesteonLogo} className="h-[40px]" />
          <div>
            <div className="flex items-center justify-center my-[40px]">
              <img src={verifyIcon} alt="" />
            </div>
            <div className="grid items-center justify-center text-[14px] text-[#5A5A60] leading-[21px]">
              <h3 className="text-[24px] text-center leading-[120%] text-[#262626] font-semibold">
                Check your email
              </h3>
              <p className="text-center mb-[20px]">
                We have sent a password recover instructions <br />
                to your email.
              </p>
              <p className="text-center">
                If you have not received the email after a few <br /> minutes,
                please check your spam folder
              </p>
            </div>
          </div>
          <div className="mt-[60px] flex items-center justify-center text-[14px] text-[#5A5A60] leading-[21px] text-center">
            <p>
              Still not receiving email?&nbsp;
              <Link
                to="/forgot-password"
                className="text-[#2C7D92] font-semibold hover:text-[#2C7D92] focus:outline-none focus:ring-0"
              >
                Try another email address
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChangePasswordEmail
