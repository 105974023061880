import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Moment from 'react-moment'
import { useGetListResentUserQuery } from 'services/questApi'
import recent_user_join from 'assets/images/recent_user_join.svg'

const RecentUser = () => {
  const { id } = useParams()
  const { data } = useGetListResentUserQuery(id)
  const [recentUser, setRecentUser] = useState(false)

  useEffect(() => {
    if (data) {
      if (data.length > 0) {
        setRecentUser(true)
      }
    }
  }, [data])

  return (
    <>
      {recentUser && (
        <div className="w-full">
          <div className="mb-[24px]">
            <div className="flex items-center text-[18px] font-semibold">
              <img src={recent_user_join} alt="" className="pr-2" />
              Recent User Join
            </div>
          </div>
          {data?.map((user) => (
            <div
              className="flex items-center p-[20px] bg-[#F7F8FA] my-4 rounded-[24px] w-full"
              key={user.id}
            >
              <div className="mr-[20px]">
                <img
                  src={user.user_avatar_url || recent_user_join}
                  alt=""
                  className="w-[50px] h-[50px] rounded-full object-cover"
                />
              </div>
              <div className="grid items-center">
                <p className="text-[14px] leading-[21px] text-[#5A5A60] font-bold">
                  {user.user_username}
                </p>
                <p className="text-[12px] leading-[16px] text-[#7D7D7D] py-1">
                  joined this quest
                </p>
                <span className="text-[12px] leading-[16px] text-[#7D7D7D]">
                  <Moment format="DD MMM . HH:mm">{user.created_at}</Moment>
                </span>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  )
}

export default RecentUser
