import React, { useEffect, useState } from 'react'
import { useWatch } from 'react-hook-form'
import { useListBadgesQuery } from 'services/questApi'
import { useGetCreatorQuery } from 'services/creatorApi'
import { useSelector } from 'react-redux'
import { FaCircle } from 'react-icons/fa'
import { DeviceFrameset } from 'react-device-frameset'
import 'react-device-frameset/styles/marvel-devices.min.css'
import FileIcon from 'assets/images/mobile/image_icon_gray.png'
import back from 'assets/images/mobile/back.svg'
import share from 'assets/images/mobile/share.svg'
import starIcon from 'assets/images/popularquest/star.svg'
import dollar from 'assets/images/questdetail/dollar.svg'
import places from 'assets/images/questinfo/places.svg'
import routeIcon from 'assets/images/questinfo/route.svg'
import task from 'assets/images/questinfo/task.svg'
import age from 'assets/images/questinfo/age.svg'
import durationIcon from 'assets/images/questinfo/duration.svg'

const MobileInfo = ({
  control,
  selectedCategory,
  duration,
  durationHour,
  selectedOption,
  optionAge,
}) => {
  const { quest } = useSelector((state) => state.quest)
  const { data: badge } = useListBadgesQuery()
  const { data: creator } = useGetCreatorQuery()
  const [previewMobile, setPreviewMobile] = useState({
    description: '',
    image: '',
    badge_id: [],
  })

  const getTitle = useWatch({
    control,
    name: 'title',
  })

  const getRoute = useWatch({
    control,
    name: 'route',
  })

  const getPrice = useWatch({
    control,
    name: 'price',
  })

  const getDescription = useWatch({
    control,
    name: 'description',
  })

  const getImage = useWatch({
    control,
    name: 'image',
  })

  const getBadge = useWatch({
    control,
    name: 'badge_id',
  })

  const getHighlights = useWatch({
    control,
    name: 'highlights',
  })

  const setDescription = (e) => {
    setPreviewMobile((existingValues) => ({
      ...existingValues,
      description: e,
    }))
  }

  const setImage = (e) => {
    setPreviewMobile((existingValues) => ({
      ...existingValues,
      image: e,
    }))
  }

  const setBadge = (e) => {
    setPreviewMobile((existingValues) => ({
      ...existingValues,
      badge_id: e,
    }))
  }

  useEffect(() => {
    if (badge) {
      const findBadge = badge?.find((item) => item.id === getBadge)
      setBadge(findBadge)
    }
    if (getDescription) {
      setDescription(getDescription.replace(/<[^>]+>/g, ''))
    }
  }, [badge, getBadge, getDescription])

  useEffect(() => {
    if (!quest) {
      if (getImage.length !== 0 && typeof getImage === 'object') {
        setTimeout(() => {
          const imageUrl = URL.createObjectURL(getImage)
          setImage(imageUrl)
        }, 100)
      }
    }
    if (quest) {
      if (getImage !== 'SERVER_FILE' && typeof getImage !== 'object') {
        setImage(getImage)
      }
      if (getImage.length !== 0 && typeof getImage === 'object') {
        setTimeout(() => {
          const imageUrl = URL.createObjectURL(getImage)
          setImage(imageUrl)
        }, 100)
      }
    }
  }, [getImage, quest])

  return (
    <>
      <div className="w-3/12 -mt-[130px] sticky top-0 h-[600px]">
        <div className="-ml-14 -mt-[130px]">
          <DeviceFrameset device="iPhone X" zoom={0.6} potrait="true">
            <div className="h-[800px] overflow-auto">
              <div className="flex items-center justify-between w-full border-2 border-black h-[60px] pt-[60px] px-[20px]">
                <div>
                  <img src={back} alt="" className="w-[40px]" />
                </div>
                <div>
                  <img src={share} alt="" className="w-[40px]" />
                </div>
              </div>
              <div
                style={{
                  backgroundImage: `url(${previewMobile?.image} )`,
                  backgroundSize: 'cover',
                }}
                className="bg-[#EDEDED] h-[250px] flex justify-center -mt-[62px]"
              >
                {previewMobile?.image === '' && (
                  <div className="flex items-center justify-center">
                    <img src={FileIcon} alt="" />
                  </div>
                )}
              </div>
              <div className="bg-white h-[800px] rounded-t-[29px] -mt-2 p-[17px]">
                <div className="flex items-center justify-start">
                  <div className="mr-2">
                    <img
                      src={creator?.avatar_url}
                      alt=""
                      className="w-[33px] rounded-full h-[33px]"
                    />
                  </div>
                  <div>
                    <div className="text-[11px] leading-[150%] text-[#5A5A60]">
                      A quest by:
                    </div>
                    <div className="text-[11px] leading-[150%] text-[#262626] font-bold">
                      {creator?.name}
                    </div>
                  </div>
                </div>
                <div className="text-[18px] leading-[130%] text-[#7D7D7D] font-bold mt-[18px] inline-block">
                  {getTitle || 'Quest title goes here'}
                </div>
                <div className="flex items-center justify-start mt-[13px]">
                  <div className="flex items-center justify-start mr-4">
                    <div className="mr-2">
                      <img src={starIcon} alt="" className="w-[20px]" />
                    </div>
                    <div className="text-[11px] leading-[150%] text-[#262626] font-bold">
                      5.0
                    </div>
                  </div>
                  <div className="flex items-center justify-start">
                    <div className="mr-2">
                      <img src={dollar} alt="" className="w-[20px]" />
                    </div>
                    <div className="text-[11px] leading-[150%] text-[#7D7D7D] font-bold">
                      ${getPrice || '0'}
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-start mt-[13px]">
                  <div className="mr-2 flex">
                    {selectedCategory?.map((item) => (
                      <li
                        key={item.id}
                        className="flex items-center justify-center whitespace-nowrap text-[11px] leading-[150%] text-[#7D7D7D] border-[1px] border-[#7D7D7D] mr-2 py-[9px] px-[16px] rounded-[50px] mb-2"
                      >
                        {item.name}
                      </li>
                    ))}
                    {selectedCategory.length === 0 && (
                      <div className="flex items-center justify-center whitespace-nowrap text-[11px] leading-[150%] text-[#7D7D7D] border-[1px] border-[#7D7D7D] mr-2 py-[9px] px-[16px] rounded-[50px] mb-2">
                        Add tags
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex items-center justify-start mt-[13px]">
                  <div className="mr-2 flex">
                    <div className="text-[11px] leading-[150%] text-[#7D7D7D]">
                      {previewMobile.description ||
                        'Quest description goes here ...'}
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap items-start justify-start pt-[24px] text-[14px] leading-[150%] text-[#7D7D7D] mt-[13px]">
                  <div className="w-[90px] mr-4">
                    <div className="h-[30px] ">
                      <img src={places} alt="" className="h-[20px]" />
                    </div>
                    <p className="py-[4px]">Check Point</p>
                    <h4 className="text-[24px] leading-[130%] text-[#262626] font-semibold">
                      X
                    </h4>
                  </div>
                  <div className="w-[90px] mr-4">
                    <div className="h-[30px]">
                      <img src={task} alt="" className="h-[20px]" />
                    </div>
                    <p className="py-[4px]">Task</p>
                    <h4 className="text-[24px] leading-[130%] text-[#262626] font-semibold">
                      X
                    </h4>
                  </div>
                  <div className="w-[90px] mr-4">
                    <div className="h-[30px]">
                      <img src={routeIcon} alt="" className="h-[20px]" />
                    </div>
                    <p className="py-[4px]">Route</p>
                    <h4 className="text-[24px] leading-[130%] text-[#262626] font-semibold">
                      {getRoute || 0} <sub>km</sub>
                    </h4>
                  </div>
                  <div className="w-[90px] mr-4">
                    <div className="h-[30px]">
                      <img src={durationIcon} alt="" className="h-[25px]" />
                    </div>
                    <p className="py-[4px]">Duration</p>
                    <h4 className="text-[24px] leading-[130%] text-[#262626] font-semibold">
                      {duration || 0} {durationHour ? 'Hour' : 'Day'}
                    </h4>
                  </div>
                  <div className="w-[140px]">
                    <div className="h-[30px]">
                      <img src={age} alt="" className="h-[25px]" />
                    </div>
                    <p className="py-[4px]">Suggested Age</p>
                    <p className="text-[24px] leading-[130%] text-[#262626] font-semibold">
                      {(selectedOption === 'null'
                        ? 'All ages'
                        : selectedOption?.replace(
                            /(^\w{1})|(\s+\w{1})/g,
                            (letter) => letter.toUpperCase()
                          )) || 'All ages'}
                    </p>
                    <p className="pt-[4px] text-[14px] leading-[150%] text-[#262626]">
                      {optionAge[selectedOption] || 'All ages'}
                    </p>
                  </div>
                </div>
                <div className="flex items-center justify-start mt-[30px] pt-[32px] border-t-[1px]">
                  <div className="w-full">
                    <div className="text-[18px] leading-[150%] text-[#262626] font-semibold mb-[16px]">
                      Badges you can earn
                    </div>
                    <div className="flex items-center justify-start border-[1px] w-full py-[18px] px-[20px] rounded-[16px]">
                      <div className="flex items-center justify-center h-[64px] w-[64px] rounded-full bg-[#EDEDED]">
                        <img
                          src={previewMobile?.badge_id?.image_url || FileIcon}
                          alt=""
                          className={
                            previewMobile?.badge_id ? 'rounded-full' : ''
                          }
                        />
                      </div>
                      <div className="ml-4 text-[16px] leading-[24px] text-[#7D7D7D] font-semibold">
                        {previewMobile?.badge_id?.title || 'Badge title'}
                      </div>
                    </div>
                    <div className="text-[18px] leading-[150%] text-[#262626] font-semibold mt-[30px] mb-[16px]">
                      Highlights
                    </div>
                    <div className="text-[14px] leading-[150%] text-[#5A5A60]">
                      <ul>
                        {getHighlights?.map((item, index) => (
                          <li
                            key={index}
                            className="mt-[8px] flex items-center justify-start"
                          >
                            <FaCircle className="text-[#FFCF50] w-[8px] mr-2" />
                            {item.value || 'Highlight goes here ...'}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </DeviceFrameset>
        </div>
      </div>
    </>
  )
}

export default MobileInfo
