import React from 'react'
import { sanitize } from 'dompurify'

const HTMLView = ({ content }) => {
  return (
    <p
      dangerouslySetInnerHTML={{
        __html: sanitize(content, {
          FORBID_ATTR: ['style'],
        }),
      }}
    ></p>
  )
}

export default HTMLView
