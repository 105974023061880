import React, { useEffect, useRef } from 'react'
import editIcon from 'assets/images/edit.svg'
import InfoIcon from 'assets/images/icon/info.png'
import { useForm, Controller } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Tooltip } from 'bootstrap'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import AvatarInput from 'components/inputs/AvatarInput'
import {
  useGetCreatorQuery,
  useUpdateCreatorMutation,
} from 'services/creatorApi'
import { serializeData } from 'utils/serializeData'
import RichTextInput from 'components/inputs/RichTextInput'

const Setting = () => {
  const showComponent = false // Hide currently unused inputs
  const dispatch = useDispatch()
  const paypalRef = useRef()
  const { quest } = useSelector((state) => state.quest)
  const { data } = useGetCreatorQuery()
  const [updateCreator] = useUpdateCreatorMutation()
  const emptySetting = {
    name: '',
    email: '',
    paypal_email: '',
    description: '',
    avatar: '',
  }

  const {
    register,
    control,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: emptySetting,
  })

  useEffect(() => {
    if (showComponent) {
      new Tooltip(paypalRef.current, {
        title: 'Paypal Email',
        placement: 'top',
        trigger: 'hover',
      })
    }
  }, [dispatch, paypalRef, showComponent])

  useEffect(() => {
    if (data) {
      const settingValue = {
        name: data?.name,
        email: data?.email,
        paypal_email: data?.paypal_email,
        description: data?.description,
        avatar: data?.avatar_url,
      }
      reset(settingValue)
    }
  }, [quest, reset, data])

  const handleError = (errors) => {
    errors?.data?.forEach(({ name, message }) => {
      setError(name, { type: 'server', message })
    })
  }

  const onSubmit = async (data) => {
    const creatorDataForm = serializeData({ creator: data })
    try {
      const response = await updateCreator(creatorDataForm).unwrap()
      if (response) {
        // alert('Your changes has been saved!')
      }
    } catch ({ data }) {
      handleError(data)
    }
  }

  return (
    <div className="px-[32px] py-[38px]">
      <div className="w-9/12">
        <div className="text-[24px] leading-[130%] text-[#262626] font-semibold pb-[24px]">
          <p>Settings</p>
        </div>
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="text-[18px] leading-[150%] text-[#262626] font-semibold">
              Account Info
            </div>
            <div className="flex items-center py-[24px]">
              <label className="text-[16px] leading-[24px] text-[#262626] w-[200px]">
                Avatar
              </label>
              <Controller
                name="avatar"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <AvatarInput
                    {...field}
                    onError={setError}
                    error={errors.avatar}
                  />
                )}
              />
              {errors?.avatar && (
                <div className="">{errors?.avatar?.message}</div>
              )}
            </div>
            <div className="flex items-center">
              <label className="text-[16px] leading-[24px] text-[#262626] w-[200px]">
                Full Name
              </label>
              <div className="border-[1px] border-[#EDEDED] rounded-[24px] py-[12px] px-[24px] w-[400px]">
                <input
                  type="text"
                  placeholder="Type Full Name"
                  className={classNames('form-control', {
                    'is-invalid': errors.name,
                  })}
                  {...register('name', {
                    required: 'Full Name is required',
                  })}
                />

                {errors?.name && (
                  <div className="">{errors?.name?.message}</div>
                )}
              </div>
            </div>

            {showComponent && (
              <div className="flex items-center">
                <label className="text-[16px] leading-[24px] text-[#262626] w-[200px]">
                  Bio
                </label>
                <div className="border-[1px] border-[#EDEDED] rounded-[24px] py-[12px] px-[24px] w-[400px]">
                  <Controller
                    name="description"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <RichTextInput {...field} placeholder="Type Your Bio" />
                    )}
                  />
                  {errors?.description && (
                    <div className="">{errors?.description?.message}</div>
                  )}
                </div>
              </div>
            )}

            <div className="flex items-center py-[24px]">
              <label className="text-[16px] leading-[24px] text-[#262626] w-[200px]">
                Email
              </label>
              <div className="border-[1px] border-[#EDEDED] rounded-[24px] py-[12px] px-[24px] w-[400px] bg-[#EDEDED]">
                <input
                  type="text"
                  disabled
                  control={control}
                  placeholder="Type Email"
                  {...register('email')}
                  className="w-[300px] text-[#7D7D7D]"
                />
              </div>
            </div>
            {showComponent && (
              <>
                <div className="text-[18px] leading-[150%] text-[#262626] font-semibold">
                  Payments
                </div>

                <div className="flex items-center">
                  <label>
                    <div className="flex items-center">
                      PayPal Email{' '}
                      <img
                        type="button"
                        ref={paypalRef}
                        src={InfoIcon}
                        alt=""
                      />
                    </div>
                  </label>
                  <div className="border-[1px] border-[#EDEDED] rounded-[24px] py-[12px] px-[24px] w-[400px]">
                    <input
                      type="email"
                      placeholder="Type PayPal Email"
                      className={classNames('form-control', {
                        'is-invalid': errors?.paypal_email,
                      })}
                      {...register('paypal_email', {
                        required: 'Paypal Email required',
                      })}
                    />
                    {errors?.paypal_email && (
                      <div className="">{errors?.paypal_email?.message}</div>
                    )}
                  </div>
                </div>
              </>
            )}

            <div className="text-[18px] leading-[150%] text-[#262626] font-semibold">
              Password
            </div>

            <div className="flex items-center py-[24px]">
              <label className="text-[16px] leading-[24px] text-[#262626] w-[200px]">
                Change Password
              </label>
              <Link
                to="/setting/change-password"
                className="flex items-center text-[16px] leading-[24px] text-[#2C7D92] font-semibold hover:text-[#2C7D92]"
              >
                <img src={editIcon} alt="" className="mr-2 w-[18px] h-[18px]" />{' '}
                Change My Password
              </Link>
            </div>

            <div className="flex items-center">
              <label className="text-[16px] leading-[24px] text-[#262626] w-[200px]">
                &nbsp;
              </label>
              <div>
                <button
                  type="submit"
                  className="text-[16px] leading-[24px] text-[#FFFFFF] py-[12px] px-[50px] bg-[#2C7D92] rounded-[24px] font-semibold"
                >
                  Save Changes
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Setting
