import React, { useState, useMemo, useCallback } from 'react'
import { FaSortDown, FaSortUp, FaSort } from 'react-icons/fa'
import Moment from 'react-moment'
import { useSearchParams, useParams } from 'react-router-dom'
import { useGetListQuestUsersQuery } from 'services/questApi'
import defaultUserImage from 'assets/images/dash-user.png'
import Pagination from './Pagination'
import EmptyUsers from 'pages/quest/QuestDetail/EmptyStates/EmptyUsers'

const Users = () => {
  let [searchParams] = useSearchParams()
  const page = searchParams.get('page') || 1
  const [sortConfig, setSortConfig] = useState(null)
  const { id } = useParams()

  const queryParams = useMemo(() => {
    let query = { page, id }
    if (sortConfig) {
      return { ...query, sort: sortConfig.sort, field: sortConfig.field }
    }
    return query
  }, [page, sortConfig, id])

  const { data, isLoading } = useGetListQuestUsersQuery(queryParams)
  const { users, total, perPage } = data || {}
  const emptyUsers = users?.length === 0

  const requestSort = (field) => {
    let sort = 'asc'
    if (sortConfig && sortConfig.field === field && sortConfig.sort === 'asc') {
      sort = 'desc'
    }
    setSortConfig({ sort, field })
  }

  const sortIcon = useCallback(
    (field) => {
      if (sortConfig && sortConfig.field === field) {
        if (sortConfig.sort === 'asc') {
          return <FaSortUp />
        } else {
          return <FaSortDown />
        }
      }
      return <FaSort />
    },
    [sortConfig]
  )

  return (
    <>
      {emptyUsers && <EmptyUsers />}
      {!emptyUsers && (
        <>
          <div className="w-full">
            <table className="w-full">
              <thead className="bg-[#F7F8FA] text-[14px] text-[#7D7D7D] leading-[150%] mb-10">
                <tr>
                  <th
                    scope="col"
                    onClick={() => requestSort('user_username')}
                    className="rounded-l-[16px]"
                  >
                    <div className="flex items-center py-[12px] pl-[20px] rounded-l-[16px]">
                      Username&nbsp;
                      {sortIcon('user_username')}
                    </div>
                  </th>
                  <th scope="col" onClick={() => requestSort('tasks_count')}>
                    <div className="flex items-center">
                      Progress&nbsp;
                      {sortIcon('tasks_count')}
                    </div>
                  </th>
                  <th scope="col" onClick={() => requestSort('points')}>
                    <div className="flex items-center pl-6">
                      Points&nbsp;{sortIcon('points')}
                    </div>
                  </th>
                  <th
                    scope="col"
                    onClick={() => requestSort('created_at')}
                    className="rounded-r-[16px]"
                  >
                    <div className="flex items-center mx-2 py-[12px] rounded-r-[16px]">
                      Date&nbsp;Joined&nbsp;
                      {sortIcon('created_at')}
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {!isLoading &&
                  users?.map((user) => (
                    <tr key={user.id} className="px-6">
                      <td className="pl-[20px] ">
                        <div className="flex items-center text-[14px] my-[20px] leading-[150%] text-[#262626] font-light">
                          <div className="mr-2">
                            <img
                              src={user.user_avatar_url || defaultUserImage}
                              alt=""
                              className="w-[32px] h-[32px] rounded-full object-cover object-center "
                            />
                          </div>
                          {user.user_username}
                        </div>
                      </td>
                      <td>
                        <div className="flex items-center">
                          <div className="w-full bg-[#EDEDED] rounded-full h-[8px]">
                            <div
                              className="bg-[#2C7D92] h-[8px] rounded-full"
                              style={{
                                width: user.progress,
                              }}
                            ></div>
                          </div>
                          <div className="ml-2">
                            <span>{user.progress}</span>
                          </div>
                        </div>
                      </td>
                      <td className="pl-6">
                        <div className="flex items-center pl-6">
                          {user.points}
                        </div>
                      </td>
                      <td>
                        {' '}
                        <Moment format="MMM DD, YYYY  HH:mm">
                          {user.created_at}
                        </Moment>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="table__result">
            <Pagination
              isLoading={isLoading}
              total={total}
              currentPage={page}
              perPage={perPage}
            />
          </div>
        </>
      )}
    </>
  )
}

export default Users
