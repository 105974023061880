import React from 'react'
import successIcon from 'assets/images/icon/success.svg'

const SaveNotice = () => {
  return (
    <div className="absolute flex items-center justify-end -ml-[340px] -mt-[200px] w-full h-max z-20">
      <div className="flex items-center justify-start w-[318px] h-[56px] rounded-[14px] px-[22px] bg-[#2FAE55] mr-6">
        <div>
          <img src={successIcon} alt="" className="mr-2" />
        </div>
        <div className="text-[14px] leading-[21px] text-white">
          Successful save
        </div>
      </div>
    </div>
  )
}

export default SaveNotice
