/* eslint-disable react/display-name */
import React, { forwardRef, useState, useEffect, useCallback } from 'react'
import { useListBadgesQuery } from 'services/questApi'
import MedalIcon from 'assets/images/questinfo/medal.svg'
import SelectInput from './SelectInput'

const BadgeInput = forwardRef((props, ref) => {
  const { data, isLoading } = useListBadgesQuery()
  const [badge, setBadge] = useState(MedalIcon)
  const [options, setOptions] = useState([])
  const { onChange, value, errorBadge } = props

  const selectBadge = useCallback(
    (selected) => {
      const badge = data?.find((badge) => badge.id === Number(selected))
      return badge?.image_url
    },
    [data]
  )

  const onBadgeChange = (selectedBadge) => {
    const badge = selectBadge(selectBadge)
    setBadge(badge)
    onChange(selectedBadge)
  }

  useEffect(() => {
    const options = data?.map((badge) => ({
      label: badge.title,
      value: badge.id,
    }))
    setOptions(options)
    if (value) {
      setBadge(selectBadge(value))
    }
  }, [data, value, selectBadge])

  return (
    <div className="flex items-center">
      <div className="flex items-center justify-center bg-[#F7F8FA] border-[1px] border-dashed border-[#2C7D92] w-[100px] h-[100px] rounded-full mr-4 overflow-hidden">
        <img src={badge} alt="" className="object-fit object-center" />
      </div>
      <div>
        <SelectInput
          ref={ref}
          error={errorBadge}
          isLoading={isLoading}
          onChange={({ value }) => {
            onBadgeChange(value)
          }}
          options={options}
          isSearchable={true}
          overridingOnChange={true}
          value={value}
        />
      </div>
    </div>
  )
})

export default BadgeInput
