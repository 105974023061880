import React, { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import loadable from '@loadable/component'
import edit from 'assets/images/questdetail/edit.svg'
import ListTask from 'pages/quest/TaskDetail/ListTask'
import { useGetTaskQuery } from 'services/taskApi'
import { useShowModal } from 'utils/questModal'
import HTMLView from 'components/HTMLView'
import useWindowDimensions from '../../hooks/WindowDimensions'
import { setAction } from 'features/questSlice'
import { setHeader } from 'features/navigationSlice'
import { useGetQuestQuery } from 'services/questApi'
import { setQuest } from 'features/questSlice'
import TaskClueView from './TaskDetail/TaskClue'
import CoverCarousel from './TaskDetail/CoverCarousel'

const TaskDetail = () => {
  const { id, questId } = useParams()
  const { data } = useGetTaskQuery(id)
  const { data: quest } = useGetQuestQuery(questId)
  const showModal = useShowModal()
  const dispatch = useDispatch()
  const { height } = useWindowDimensions()

  const onEditTaskClick = () => {
    showModal({ component: 'TaskFormModal', props: { id } })
  }

  const { task_type: taskType } = data || {}

  const taskTypeViewComponent = useMemo(() => {
    const components = {
      action: 'TaskActionView',
      check_in: 'TaskCheckInView',
      information: 'TaskInformationView',
      short_answer: 'TaskShortAnswerView',
      polling: 'TaskPollingView',
      photo: 'TaskPhotoView',
      guess_the_difference: 'TaskSpotTheDifferenceView',
      scan_qr: 'TaskScanQRView',
      multiple_choice: 'TaskMultipleChoiceView',
      multiple_choice_image: 'TaskMultipleChoiceImageView',
      augmented_reality: 'TaskAugmentedRealityView',
      numpad: 'TaskNumpadView',
      symbol_pad: 'TaskSymbolPadView',
    }
    const taskViewComponent = components[taskType]
    return taskViewComponent
  }, [taskType])

  const SelectedTaskTypeView = useMemo(() => {
    if (taskType) {
      return loadable(
        () => import(`./TaskDetail/TaskType/${taskTypeViewComponent}`),
        {
          fallback: <h3>Loading...</h3>,
        }
      )
    }
  }, [taskTypeViewComponent, taskType])

  useEffect(() => {
    dispatch(setAction('VIEW'))
    dispatch(setHeader('QUEST'))
    if (quest) {
      dispatch(setQuest(quest))
    }
  }, [dispatch, quest])

  return (
    <div
      style={{
        height: height - 80,
      }}
      className="px-[32px] py-[38px] 2xl:p-[60px] overflow-auto"
    >
      {data && (
        <div>
          <div className="flex items-center justify-between mb-[24px]">
            <div className="text-[24px] leading-[130%] text-[#262626] font-bold">
              <p>{data.title}</p>
            </div>
            <div className="flex items-center justify-between">
              <button
                className="flex items-center justify-center text-[16px] leading-[24px] text-[#2C7D92] py-[13px] px-[30px] rounded-[50px] border-[1px] border-[#2C7D92] font-semibold"
                onClick={onEditTaskClick}
              >
                <img src={edit} alt="" className="mr-2" />
                Edit
              </button>
            </div>
          </div>
          <div className="flex w-full">
            <div className="w-8/12 mr-4">
              <CoverCarousel task={data} />
              <div className="mb-[24px]">
                <HTMLView content={data.description} />
              </div>
              <SelectedTaskTypeView taskData={data} />
              {data.task_hints.length > 0 && (
                <div className="mt-[24px]">
                  <p className="text-[18px] leading-[150%] text-[#262626] font-semibold mb-[24px]">
                    Hints 💡
                  </p>
                  {data.task_hints.map((hint, index) => (
                    <div
                      className="flex items-center w-[694px] px-[24px] py-[16px] bg-[#F7F8FA] rounded-[24px] mb-[16px]"
                      key={hint.id}
                    >
                      <div className="flex items-start text-[40px] leading-[120%] text-[#262626]/10 font-semibold">
                        {index + 1}
                      </div>
                      <div className="w-full flex items-center justify-center text-[14px] leading-[150%] text-[#262626]">
                        {hint.content}
                      </div>
                    </div>
                  ))}
                </div>
              )}
              <TaskClueView taskData={data} />
            </div>
            <div className="w-4/12">
              <ListTask />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default TaskDetail
