import React from 'react'
import { useSelector } from 'react-redux'
import { FaStar } from 'react-icons/fa'
import Moment from 'react-moment'
import { useParams, useSearchParams } from 'react-router-dom'
import { useGetReviewsQuery } from 'services/questApi'
import Pagination from './Pagination'
import ReviewChart from './ReviewChart'
import arrow_status from '../../../assets/images/arrow_status.svg'
import EmptyReviews from 'pages/quest/QuestDetail/EmptyStates/EmptyReviews'

const Review = () => {
  const { quest } = useSelector((state) => state.quest)
  const { id } = useParams()
  const [searchParams] = useSearchParams()
  const pageQuery = searchParams.get('page')

  const { data, isLoading } = useGetReviewsQuery({
    id,
    page: pageQuery,
  })

  const { reviews, total, perPage, page: currentPage } = data || {}
  const emptyReviews = reviews?.length === 0

  return (
    <>
      {emptyReviews && <EmptyReviews />}
      {!emptyReviews && quest && (
        <div className="">
          <div className="flex items-center justify-between">
            <div className="">
              <div className="text-[36px] leading-[120%] text-[#262626] flex items-center font-semibold">
                <span>
                  <FaStar className="text-[#FFCF50] mr-2" />
                </span>
                {quest.star}
              </div>
              <p className="mt-2 text-[14px] leading-[150%] text-[#7D7D7D]">
                Based on {quest.reviews_count} reviews
              </p>
            </div>
            <ReviewChart />
          </div>
          <div className="">
            <div className="flex items-center justify-between py-[30px]">
              <h4 className="text-[18px] leading-[120%] text-[#262626] font-semibold">
                ️💬 What they say
              </h4>
              <div className="flex items-center">
                Sort by&nbsp;
                <div className="border-[1px] flex items-center justify-between rounded-[24px] py-[12px] px-[24px] ml-4">
                  <select className="after:bg-white cursor-pointer text-[14px] leading-[150%] text-[#262626] mr-2">
                    <option
                      value="newest"
                      className="text-[14px] leading-[150%] text-[#262626] after:bg-white"
                    >
                      Newest First
                    </option>
                    <option value="lowest" className="after:bg-white">
                      Oldest First
                    </option>
                    <option value="highest" className="after:bg-white">
                      Highest First
                    </option>
                    <option value="lowest" className="after:bg-white">
                      Lowest First
                    </option>
                  </select>
                  &nbsp;
                  <img src={arrow_status} alt="" />
                </div>
              </div>
            </div>
            {reviews?.map((review, index) => (
              <div className="py-[24px] border-b-[1px]" key={index.id}>
                <div className="flex items-center justify-between mb-[14px]">
                  <div className="flex items-center">
                    <div className="mr-2">
                      <img
                        src={review.user?.avatar_url}
                        alt=""
                        className="w-[40px] h-[40px] rounded-full object-cover object-center "
                      />
                    </div>
                    <div className="grid items-center">
                      <p className="text-[14px] leading-[150%] text-[#262626] font-semibold">
                        {review.user?.username}
                      </p>
                      <div className="flex items-center">
                        {Array.from({
                          length: 5,
                        }).map((_, index) => (
                          <span key={`star-${review.id}-${index}`}>
                            <FaStar
                              className={
                                index + 1 > review.star
                                  ? 'text-[#EDEDED]'
                                  : 'text-[#FFCF50]'
                              }
                            />
                          </span>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="text-[12px] leading-[150%] text-[#7D7D7D]">
                    <span>
                      <Moment toNow>{review.created_at}</Moment>
                    </span>
                  </div>
                </div>
                <div className="text-[14px] leading-[150%] text-[#7D7D7D]">
                  <p>{review.message}</p>
                </div>
              </div>
            ))}
            <div className="table__result result--last">
              <Pagination
                isLoading={isLoading}
                total={total}
                currentPage={currentPage}
                perPage={perPage}
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Review
