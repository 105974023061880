import React, { useState, useCallback, useMemo } from 'react'
import Skeleton from 'react-loading-skeleton'
import { FaSearch, FaSort, FaSortUp, FaSortDown } from 'react-icons/fa'
import { Link, useSearchParams, useNavigate } from 'react-router-dom'
import Pagination from 'components/Pagination'
import { useListQuestsQuery } from 'services/questApi'
import { QuestStatus } from 'utils/questUtils'
import EmptyQuest from './EmptyQuest'

const ListQuest = () => {
  let [searchParams, setSearchParams] = useSearchParams()
  const pageQuery = searchParams.get('page') || 1
  const searchQuery = searchParams.get('query') || ''
  const [page, setPage] = useState(pageQuery)
  const [search, setSearch] = useState(searchQuery)
  const [sortConfig, setSortConfig] = useState(null)
  const queryParams = useMemo(() => {
    let query = { page, search }
    if (sortConfig) {
      return { ...query, sort: sortConfig.sort, field: sortConfig.field }
    }
    return query
  }, [page, search, sortConfig])

  const { data, isFetching } = useListQuestsQuery(queryParams)
  const { quests, total, perPage, page: currentPage } = data || {}

  const navigate = useNavigate()

  const requestSort = (field) => {
    let sort = 'asc'
    if (sortConfig && sortConfig.field === field && sortConfig.sort === 'asc') {
      sort = 'desc'
    }
    setSortConfig({ sort, field })
  }

  const onPageChange = (page) => {
    searchParams.set('page', page)
    setSearchParams(searchParams)
    setPage(page)
  }

  const onSearchChange = (query) => {
    searchParams.set('query', query)
    searchParams.delete('page')
    setSearchParams(searchParams)
    setSearch(query)
    setPage(1)
  }

  const sortIcon = useCallback(
    (field) => {
      if (sortConfig && sortConfig.field === field) {
        if (sortConfig.sort === 'asc') {
          return <FaSortUp />
        } else {
          return <FaSortDown />
        }
      }
      return <FaSort />
    },
    [sortConfig]
  )

  return (
    <>
      {isFetching && <SkeletonQuestList />}
      {quests?.length === 0 && <EmptyQuest />}
      {!isFetching && quests?.length > 0 && (
        <div>
          <div className="bg-[#FFFFFF] flex items-center px-[20px] py-[10px] border-2 border-[#EDEDED] rounded-[50px] mb-[24px] w-full">
            <a href="#0" className="mr-2">
              <FaSearch className="text-[#2C7D92]" />
            </a>
            <input
              type="text"
              placeholder="Search Quest title"
              defaultValue={search}
              onChange={(event) => onSearchChange(event.target.value)}
            />
          </div>
          <div className="mb-[24px]">
            <table className="w-full">
              <thead className="bg-[#F7F8FA] text-[14px] text-[#7D7D7D] leading-[150%] p-10">
                <tr>
                  <th
                    scope="col"
                    onClick={() => requestSort('title')}
                    className="py-[12px] pl-[20px] rounded-l-[16px]"
                  >
                    <div className="flex items-center">
                      Title&nbsp;{sortIcon('title')}
                    </div>
                  </th>
                  <th scope="col">Badge</th>
                  <th scope="col" onClick={() => requestSort('users_count')}>
                    <div className="flex justify-center items-center">
                      User&nbsp;
                      {sortIcon('users_count')}
                    </div>
                  </th>
                  <th scope="col" onClick={() => requestSort('price')}>
                    <div className="flex justify-center items-center">
                      Price&nbsp;{sortIcon('price')}
                    </div>
                  </th>
                  <th scope="col" onClick={() => requestSort('places_count')}>
                    <div className="flex justify-center items-center">
                      Places&nbsp;
                      {sortIcon('places_count')}
                    </div>
                  </th>
                  <th scope="col" onClick={() => requestSort('tasks_count')}>
                    <div className="flex justify-center items-center">
                      Tasks&nbsp;
                      {sortIcon('tasks_count')}
                    </div>
                  </th>
                  <th
                    scope="col"
                    className="text-right rounded-r-[16px]"
                    onClick={() => requestSort('status')}
                  >
                    <div className="flex justify-center items-center">
                      Status&nbsp;{sortIcon('status')}
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {quests?.length > 0 &&
                  quests.map((quest) => (
                    <tr
                      key={quest.id}
                      onClick={() => navigate(`/quests/${quest.slug}/`)}
                      className="cursor-pointer hover:bg-[#F7F8FA] hover:text-[#2C7D92] hover:rounded-[16px]"
                    >
                      <td className="pl-[20px] w-[180px] mt-[14px]">
                        <Link
                          to={`/quests/${quest.slug}`}
                          className="hover:text-[#2C7D92]"
                        >
                          {quest.title}
                        </Link>
                      </td>
                      <td>
                        <Link
                          to={`/quests/${quest.slug}`}
                          className="hover:text-[#2C7D92]"
                        >
                          <div className="flex justify-center py-4 rounded-full overflow-hidden">
                            <img
                              src={quest.badge?.image?.url}
                              alt=""
                              className="w-[40px] rounded-full object-fit object-center"
                            />
                          </div>
                        </Link>
                      </td>
                      <td>
                        <Link
                          to={`/quests/${quest.slug}`}
                          className="flex justify-center items-center hover:text-[#2C7D92]"
                        >
                          {quest.users_count}
                        </Link>
                      </td>
                      <td>
                        <Link
                          to={`/quests/${quest.slug}`}
                          className="flex justify-center items-center hover:text-[#2C7D92]"
                        >
                          {quest.price === 0.0 ? 'Free' : `S$ ${quest.price}`}
                        </Link>
                      </td>
                      <td>
                        <Link
                          to={`/quests/${quest.slug}`}
                          className="flex justify-center items-center hover:text-[#2C7D92]"
                        >
                          {quest.places_count}
                        </Link>
                      </td>
                      <td>
                        <Link
                          to={`/quests/${quest.slug}`}
                          className="flex justify-center items-center hover:text-[#2C7D92]"
                        >
                          {quest.tasks_count}
                        </Link>
                      </td>
                      <td className="text-center rounded-r-[16px]">
                        <Link
                          to={`/quests/${quest.slug}`}
                          className="flex justify-center items-center hover:text-[#2C7D92]"
                        >
                          <QuestStatus status={quest.status} />
                        </Link>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <Pagination
            isLoading={isFetching}
            total={total}
            currentPage={currentPage}
            perPage={perPage}
            onPageChange={onPageChange}
          />
        </div>
      )}
    </>
  )
}

const SkeletonQuestList = () => {
  return (
    <table className="flex items-center justify-center">
      <tbody>
        {Array.from({ length: 7 }).map((_, index) => (
          <tr key={`skleton-quest-list-${index}`}>
            <td>
              <Skeleton width={200} />
            </td>
            <td>
              <Skeleton width={200} />
            </td>
            <td>
              <Skeleton width={200} />
            </td>
            <td>
              <Skeleton width={200} />
            </td>
            <td>
              <Skeleton width={200} />
            </td>
            <td>
              <Skeleton width={200} />
            </td>
            <td className="text-center">
              <Skeleton width={200} />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default ListQuest
