import React, { useState, useEffect } from 'react'
import noRecentUserIcon from 'assets/images/icon/ClockCounterClockwise.png'
import recent_user_join from 'assets/images/recent_user_join.svg'
import defaultUserImage from 'assets/images/dash-user.png'
import { useLastUserJoinedQuery } from 'services/questApi'
import Moment from 'react-moment'
import Skeleton from 'react-loading-skeleton'
import { Link } from 'react-router-dom'

const RecentUserJoin = () => {
  const { data, isLoading } = useLastUserJoinedQuery()
  const [recentUser, setRecentUser] = useState(false)

  useEffect(() => {
    if (data) {
      if (data.length > 0) {
        setRecentUser(true)
      }
    }
  }, [data])

  return (
    <>
      {recentUser && (
        <div>
          <a
            href="#0"
            className="flex items-center py-[13px] text-[18px] leading-[27px] text-[#262626] font-semibold hover:text-[#262626]"
          >
            <img src={recent_user_join} alt="" className="pr-2 " />
            Recent User Join
          </a>

          {isLoading ? (
            <SkeletonRecentUserJoin />
          ) : data?.length > 0 ? (
            <div>
              {data.map((questUser) => (
                <div
                  className="flex items-center p-[20px] bg-[#F7F8FA] my-4 rounded-[24px]"
                  key={questUser.id}
                >
                  <div className="pr-[20px]">
                    <img
                      src={
                        questUser?.user_avatar_url
                          ? questUser?.user_avatar_url
                          : defaultUserImage
                      }
                      alt=""
                      className="w-[50px] h-[50px] rounded-full object-cover"
                    />
                  </div>
                  <div className="grid items-center">
                    <p className="text-[14px] leading-[21px] text-[#5A5A60] font-bold">
                      {questUser?.user_username}
                    </p>
                    <p className="text-[12px] leading-[16px] text-[#7D7D7D] py-1">
                      joined
                      <Link
                        to={`/quests/${questUser.quest_id}`}
                        className="text-[#2C7D92] pl-1 "
                      >
                        {questUser.quest_title}
                      </Link>
                    </p>
                    <p className="text-[12px] leading-[16px] text-[#7D7D7D]">
                      <Moment format="DD MMM . HH:mm">
                        {questUser.created_at}
                      </Moment>
                    </p>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="text-[12px] leading-[16px] text-[#7D7D7D] py-1">
              <div className="flex justify-center">
                <img src={noRecentUserIcon} alt="" />
              </div>
              <p className="text-center">No user joined in this recent time</p>
            </div>
          )}
        </div>
      )}
    </>
  )
}

const SkeletonRecentUserJoin = () => {
  return (
    <div>
      <div className="flex items-center p-[20px] bg-[#F7F8FA] my-4 rounded-[24px]">
        <div className="pr-[20px]">
          <Skeleton circle height={48} width={48} />
        </div>
        <div className="grid items-center">
          <h3>
            <Skeleton width={100} />
          </h3>
          <p>
            <Skeleton width={100} />
          </p>
          <span>
            <Skeleton width={100} />
          </span>
        </div>
      </div>
    </div>
  )
}

export default RecentUserJoin
