import React, { useState, forwardRef, useEffect, useRef } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import {
  ContentState,
  EditorState,
  convertToRaw,
  convertFromHTML,
} from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

const RichTextInput = forwardRef((props, ref) => {
  const { onChange, value, placeholder, error } = props
  const defaultPlaceholder = 'Write something!'
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  )
  const [updated, setUpdated] = useState(false)
  const editor = useRef(null)
  const toolbar = {
    options: [
      'inline',
      'emoji',
      'blockType',
      'list',
      'textAlign',
      'link',
      'history',
    ],
    list: { inDropdown: true },
    textAlign: { inDropdown: true },
    link: { inDropdown: true },
    history: { inDropdown: true },
  }

  useEffect(() => {
    if (!updated) {
      const defaultValue = value ? value : ''
      const contentState = ContentState.createFromBlockArray(
        convertFromHTML(defaultValue)
      )
      const newEditorState = EditorState.createWithContent(contentState)
      setEditorState(newEditorState)
    }
  }, [value, updated])

  const onEditorChange = (editorState) => {
    setUpdated(true)
    setEditorState(editorState)
    onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())))
  }

  const focusEditor = () => {
    editor.current?.focusEditor()
  }

  return (
    <div
      className={
        error
          ? 'rounded-[24px] border-[1px] border-[#F15A5B]'
          : 'rounded-[24px] '
      }
    >
      <div className="editor" onClick={focusEditor}>
        <Editor
          ref={(element) => {
            ref(element)
            editor.current = element
          }}
          editorState={editorState}
          placeholder={placeholder || defaultPlaceholder}
          onEditorStateChange={onEditorChange}
          toolbar={toolbar}
        />
      </div>
    </div>
  )
})

export default RichTextInput
